import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import MemeAlertsGs from '@images/png/unknown-user-3.png';

import { IconUploadAltLine } from '@common/images/svg';
import s from './RoundedAvatar.scss';

export enum RoundedAvatarSize {
    EXTRA_SMALL = 'extra-small',
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
}

interface RoundedAvatarProps {
    className?: string;
    size?: RoundedAvatarSize;
    source?: string;
    changeAllowed?: boolean;
    style?: React.CSSProperties;
    showUploadWhenEmpty?: boolean;
    onClick?: () => void;
    onChanged?: (file: File) => void;
}

export const RoundedAvatar = (props: RoundedAvatarProps) => {
    const {
        className,
        size = RoundedAvatarSize.MEDIUM,
        source,
        changeAllowed,
        style = {},
        showUploadWhenEmpty,
        onClick,
        onChanged,
    } = props;

    const [isError, setIsError] = useState(false);

    const onAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            onChanged && onChanged(files[0]);
        }
    }

    const onImageLoadError = () => {
        setIsError(true);
    }

    useEffect(() => {
        setIsError(false);
    }, [source]);

    return (
        <div
            className={cn(
                s.roundedAvatar,
                s[size],
                {
                    [s.clickable]: onClick != undefined,
                },
                className,
            )}
            style={style}
            onClick={onClick}
        >
            { showUploadWhenEmpty && (!source || isError)
                ? <div className={s.roundedAvatarUploadIcon}>
                    <IconUploadAltLine />
                </div>
                : <img
                    className={s.roundedAvatarImage}
                    src={isError || !source ? MemeAlertsGs : source}
                    onClick={onClick}
                    onError={onImageLoadError}
                />
            }
            { changeAllowed &&
                <input
                    className={s.avatarSelectInput}
                    type={'file'}
                    onChange={onAvatarChange}
                />
            }
        </div>
    )
}