import { AlertPlayer, ColoredButton, ColoredButtonColor, Column, Hint, Panel, Row } from '@components';
import { useCategories, useExternalModeration, usePlayingAlertsList } from '@hooks';
import { StickerDto, AlertSize, CategoryDto } from '@interfaces';
import config from 'config';
import React, { useState } from 'react';
import { AlertOnModeration } from './components';
import s from './ExternalModeration.scss';

export const ExternalModeration = () => {
    const {
        currentSticker,
        lastModeratedAlertId,
        moderationStats: {
            onModeration,
        } = {},
        approveAlert,
        declineAlert,
        skipCurrentAlert,
        updateAlert,
        silentUpdateAlert,
        updateLoadedAlert,
        returnLastAlertForModeration,
    } = useExternalModeration();

    const {
        addAlertToCategory,
        removeAlertFromCategory,
    } = useCategories();

    const [ soundEnabled, setSoundEnabled ] = useState(false);

    const onSoundClick = () => {
        setSoundEnabled(prev => !prev);
    }

    const onCategorySelect = async (alert: StickerDto, category: CategoryDto) => {
        if (alert.categories?.includes(category.id)) {
            const updatedAlert = await removeAlertFromCategory({ stickerId: alert.id, categoryId: category.id }).unwrap();
            updateLoadedAlert(updatedAlert);
        } else {
            const updatedAlert = await addAlertToCategory({ stickerId: alert.id, categoryId: category.id }).unwrap();
            updateLoadedAlert(updatedAlert);
        }
    }

    const {
        list,
        addToList,
        removeFromList,
    } = usePlayingAlertsList({
        stickerSize: config?.design?.stickerSize?.[AlertSize.MEDIUM],
        availableAreaMask: 1,
    });

    return (
        <Panel className={s.moderationContainer}>
            <div className={s.onModerationCount}>Стикеров осталось: { onModeration }</div>
            <Panel className={s.moderationContainerContent}>
                <Row className={s.moderationContainerLeftColumnRow}>
                    <Column fullWidth className={s.moderationContainerLeftColumn}>
                        <Row>
                            {lastModeratedAlertId && <ColoredButton caption={'Отменить последнее решение'} onClick={returnLastAlertForModeration} color={ColoredButtonColor.RED}/>}
                        </Row>
                        <Row>
                            {currentSticker &&
                                <AlertOnModeration
                                    key={currentSticker.id}
                                    alert={currentSticker}
                                    soundMuted={!soundEnabled}
                                    onApprove={approveAlert}
                                    onDecline={declineAlert}
                                    onUpdate={updateAlert}
                                    onSilentUpdate={silentUpdateAlert}
                                    onSoundClick={onSoundClick}
                                    onClick={addToList}
                                    onCategorySelect={onCategorySelect}
                                />
                            }
                        </Row>
                        <Row>
                            {currentSticker && <ColoredButton className={s.moderationContainerLeftColumnButton} caption={'Пропустить'} onClick={skipCurrentAlert} color={ColoredButtonColor.WHITE}/>}
                        </Row>
                    </Column>
                    <Column fullWidth>
                        <Row className={s.hintRow}>
                            <Hint fullWidth>
                                {'⛔️ НЕЛЬЗЯ\n\
1. Некачественный контент (Черные полосы по бокам видео,\n\
маленький размер, плохое качество видео, звука, тестовые\n\
стикеры без смысла и пр.). Мы мягче относимся к качеству\n\
стикеров со стримерами 🙏\n\
2. Дубликаты (стикер уже есть в базе).\n\
3. Реклама.\n\
4. Наркотики. Демонстрация, символика, намёки (228, Гидра).\n\
5. Политика, политики, любые политические блоггеры,\n\
государственные символы. Особенно СССР, РФ, Украина.\n\
6. Фашизм, нацизм, экстремизм. Любое упоминание\n\
экстремистских организаций (ИГИЛ, Талибан), зиги и свастики.\n\
7. Религия. Любое оскорбление любой религии.\n\
8. Демонстрация реальных боевых действий.\n\
9. Угрозы (не в шуточной форме).\n\
10. Масшутинг (колумбайн и любые упоминания аналогов).\n\
11. Порно и любая демонстрация половых органов\n\
(нарисованные тоже).\n\
12. Суицид в любом упоминании, даже намёки (синий кит, ня\n\
пока).\n\
13. Шок и треш контент. Жестокость, расчлененка, треш\n\
контент.\n\n\
😈 Twitch Unsafe\n\
- Национализм. Любое оскорбление по национальному\n\
признаку, блекфейс. Слова: негр, нигер, хач, хохол, жид и пр.\n\
- Половые оскорбления: пидр, пидорас (и любые вариации,\n\
например распидорасило), педик, гомик, гомосек, simp,\n\
девственник, инцел, virgin, куколд, cunt, петух. Любые\n\
оскорбления на почве ЛГБТ.\n\
- Религия. Демонстрация религиозных символов. Слова: алах\n\
акбар, Аллах бабах.\n\
- Обнаженка. Для женщин грудь (полностью или частично\n\
видны соски), демонстрация (даже в одежде) полового акта.\n\
- Прочее: даун, аутист, дегенерат.\n\n\
🚦 Возрастной рейтинг\n\
E - без оскорблений\n\
T - легкие ругательства (сука, жопа)\n\
M - мат без агрессии\n\
A - агрессивные ругательства и оскорбления (мат с агрессией)'}
                            </Hint>
                        </Row>
                    </Column>
                </Row>
            </Panel>
            { list.length > 0 &&
                <Panel className={s.alertsShadow}>
                    {
                        list.map(a => {
                            return (
                                <AlertPlayer
                                    key={a.uid}
                                    alert={a.alert}
                                    geometry={a.geometry}
                                    size={AlertSize.MEDIUM}
                                    onPlayEnd={() => removeFromList(a.uid)}
                                />
                            );
                        })
                    }
                </Panel>
            }
        </Panel>
    );
};
