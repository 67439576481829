import { StickerDto, StreamerModerationAreaPageData } from '@interfaces';
import { StreamerModerationAreaPageLoadParams, ModerationAreaPageLoadParams } from '@interfaces';
import { memeAlertsBaseApi } from './meme-alerts-base.api';

export const stickersModerationPagesApi = memeAlertsBaseApi.injectEndpoints({
    endpoints: (build) => ({
        loadPageModerationArea: build.query<StickerDto[], ModerationAreaPageLoadParams>({
            query: (params: ModerationAreaPageLoadParams) => ({
                url: 'sticker/moderation-area',
                method: 'POST',
                body: params,
            }),
            providesTags: ['ModerationAreaStickers'],
        }),
        loadPageStreamerModerationArea: build.query<StreamerModerationAreaPageData, StreamerModerationAreaPageLoadParams>({
            query: (params: StreamerModerationAreaPageLoadParams) => ({
                url: 'sticker/streamer-moderation-area',
                method: 'POST',
                body: params,
            }),
            providesTags: ['StreamerModerationAreaStickers'],
        }),
    }),

});
