import { IconCogLine, IconCommentDotsLine, IconHuskySolid, IconImagesLine, IconStarLine, IconThumbUpLine } from '@common/images/svg';
import { SideMenuItemProps } from '@components';
import React from 'react';

export const menu: Array<SideMenuItemProps> = [
    {
        "icon": <IconThumbUpLine />,
        "caption": "Модерация",
        "to": "moderation",
        "enabled": true,
    },
    {
        "icon": <IconHuskySolid />,
        "caption": "Зрители",
        "to": "viewer-events",
        "enabled": true,
    },
    {
        "icon": <IconStarLine />,
        "caption": "Стримеры",
        "to": "streamer-events",
        "enabled": true,
    },
    {
        "icon": <IconCommentDotsLine />,
        "caption": "Статистика",
        "to": "stats",
        "enabled": true,
    },
    {
        "icon": <IconCommentDotsLine />,
        "caption": "Отправки алертов",
        "to": "send-alerts-interval-stats",
        "enabled": true,
    },
    {
        "icon": <IconCommentDotsLine />,
        "caption": "Места отправки алертов",
        "to": "sended-alert-stats",
        "enabled": true,
    },
    {
        "icon": <IconCommentDotsLine />,
        "caption": "Статистика по поиску",
        "to": "search-stats",
        "enabled": true,
    },
    {
        "icon": <IconCommentDotsLine />,
        "caption": "Поисковые запросы",
        "to": "search-queries",
        "enabled": true,
    },
    {
        "icon": <IconCommentDotsLine />,
        "caption": "Активации стримеров",
        "to": "cohort-stats",
        "enabled": true,
    },
    {
        "icon": <IconCommentDotsLine />,
        "caption": "Ранжирование алертов",
        "to": "streamer-alerts",
        "enabled": true,
    },
    {
        "icon": <IconCommentDotsLine />,
        "caption": "Ранжирование по популярности",
        "to": "streamer-stickers",
        "enabled": true,
    },
    {
        "icon": <IconImagesLine />,
        "caption": "Тематические подборки",
        "to": "thematic-sets",
        "enabled": true,
    },
];
