import { CategoryDto } from '@interfaces';
import { memeAlertsBaseApi } from './meme-alerts-base.api';

export const memeAlertsCategoriesApi = memeAlertsBaseApi.injectEndpoints({
    endpoints: build => ({
        getCategoriesList: build.query<CategoryDto[], void>({
            query: () => ({
                url: 'category',
                method: 'GET'
            }),
            providesTags: ['Categories'],
        }),
    }),
});
