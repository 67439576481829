import React from 'react';

import cn from 'classnames';

import s from './Column.scss';

interface ColumnProps {
    className?: string;
    fullWidth?: boolean;
    noGap?: boolean;
    width?: string;
}

export const Column = (props: React.PropsWithChildren<ColumnProps>) => {
    const {
        className,
        children,
        fullWidth,
        noGap,
        width,
    } = props;

    let styles: Record<string, unknown> = {};

    if (width) {
        styles.width = width;
    }

    return (
        <div className={cn(
            s.column,
            className,
            {
                [s.columnFullWidth]: fullWidth,
                [s.noGap]: noGap,
            },
        )} style={styles}>
            {children}
        </div>
    )
};
