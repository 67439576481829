import { api } from '@store';
import { useEffect, useState } from 'react';

export enum EStreamerEventsTab {
    MONEY,
    STICKERS,
    MODERATION,
}

export const useStreamerEvents = () => {
    const {
        user: {
            useLazyGetPrivateUserByNameQuery,
        },
    } = api;

    const [streamerName, setStreamerName] = useState<string>();
    const [displayErrors, setDisplayErrors] = useState(false);

    const [
        findUserByName,
        {
            data: streamer,
            isSuccess,
            isLoading,
            isFetching,
        }
    ] = useLazyGetPrivateUserByNameQuery();

    const findStreamer = () => {
        if (!!streamerName) {
            findUserByName(streamerName);
            setDisplayErrors(false);
        } else {
            setDisplayErrors(true);
        }
    }

    useEffect(() => {
        setDisplayErrors(false);
    }, [streamerName]);

    useEffect(() => {
        if (!streamer) {
            setDisplayErrors(true);
        } else {
            setDisplayErrors(false);
        }
    }, [streamer]);

    return {
        streamer,
        streamerName,
        isSuccess,
        isLoading,
        isFetching,
        displayErrors,

        setStreamerName,
        findStreamer,
    };
};
