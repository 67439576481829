import { StickerDto, defaultGeometry, Geometry } from '@interfaces';
import { v4 } from 'uuid';
import { useList, useLogger } from '@hooks';

export interface PlayingAlert {
    uid: string;
    alert: StickerDto;
    geometry: Geometry;
    hidden?: boolean;
}

interface AlertTransformationSize {
    width: number;
    height: number;
}

interface PlayingAlertListParams {
    stickerSize?: AlertTransformationSize;
    availableAreaMask?: number;
    fullscreen?: boolean;
}

export const usePlayingAlertsList = (params: PlayingAlertListParams) => {
    let {
        stickerSize,
        availableAreaMask,
        fullscreen = false,
    } = params;

    const { trace } = useLogger({ target: 'usePlayingAlertsList' });

    const {
        list,
        addToList: add,
        removeFromList: remove,
        clearList,
    } = useList<PlayingAlert>();

    function calcAlertGeometry() {
        if (fullscreen) {
            return {
                x: 0,
                y: 0,
                rotation: 0,
            }
        }

        if (stickerSize && availableAreaMask) {
            trace(`[Alert size info]: ${JSON.stringify(stickerSize)}%`);

            const zoneIndexes = [];

            for (let i = 0; i <= 8; i++) {
                if ((Math.pow(2, i) & availableAreaMask) > 0) {
                    zoneIndexes.push(i);
                }
            }

            trace(`[Available zone indexes]: ${zoneIndexes}`);

            const randomlySelectedZoneIdx = zoneIndexes[Math.floor(Math.random() * zoneIndexes.length)];

            trace(`[Randomly selected zone]: ${randomlySelectedZoneIdx}`);

            const zoneX = randomlySelectedZoneIdx % 3;
            const zoneY = Math.ceil((1 + randomlySelectedZoneIdx) / 3) - 1;

            trace(`[Zone matrix coords]: Xm: ${zoneX}, Ym: ${zoneY}`);

            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;

            trace(`[Available viewport size]: Width: ${windowWidth}. Height: ${windowHeight}`);

            const viewportDiagonal = Math.sqrt(Math.pow(windowWidth, 2) + Math.pow(windowHeight, 2));

            const windowXPad = [0, 2, 3, 5, 6, 8].includes(randomlySelectedZoneIdx)
                ? [0, 3, 6].includes(randomlySelectedZoneIdx)
                    ? Math.round(viewportDiagonal * stickerSize.width / 100 / 2)
                    : -Math.round(viewportDiagonal * stickerSize.width / 100 / 2)
                : 0;
            const windowYPad = [0, 1, 2, 6, 7, 8].includes(randomlySelectedZoneIdx)
                ? [0, 1, 2].includes(randomlySelectedZoneIdx)
                    ? Math.round(viewportDiagonal * stickerSize.height / 100 / 2)
                    : -Math.round(viewportDiagonal * stickerSize.height / 100 / 2)
                : 0;

            trace(`[Calculated side paddings]: Xpad: ${windowXPad}, Ypad: ${windowYPad}`);

            const windowXPadTail = [0, 3, 6].includes(randomlySelectedZoneIdx) ? windowXPad : 0;
            const windowYPadTail = [0, 1, 2].includes(randomlySelectedZoneIdx) ? windowYPad : 0;

            const x = ((windowWidth / 3 - Math.abs(windowXPad)) * Math.random()) + windowXPadTail + (windowWidth / 3 * zoneX);
            const y = ((windowHeight / 3 - Math.abs(windowYPad)) * Math.random()) + windowYPadTail + (windowHeight / 3 * zoneY);

            const rotation = Math.random() * 60 - 30;

            trace(`[Calculated alert coords]: X: ${x}, Y: ${x}, Rotation (deg): ${rotation}`);

            return { x, y, rotation };
        } else {
            return defaultGeometry;
        }
    }

    const addToList = (playingAlert: StickerDto, hidden: boolean = false) => {
        const uid = v4();
        const alert: StickerDto = {...playingAlert}; // TODO: May bo not needed, check!
        trace(JSON.stringify(alert));
        const geometry: Geometry = calcAlertGeometry();
        trace(JSON.stringify(geometry));
        add({ alert, geometry, uid, hidden });
    }

    const removeFromList = (uid: string) => {
        remove(uid);
    }

    return {
        list,
        addToList,
        removeFromList,
        clearList,
    };
};
