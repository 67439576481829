import { noop } from '@common';
import { useApi, useLogger } from '@hooks';
import { ITag } from '@typings';
import React, { useEffect, useState } from 'react';

interface TagSelectParams {
    debounceTime?: number;
    defaultValue?: ITag[];
    invalidSymbols?: RegExp;
    maxLength?: number;
    readonly?: boolean;
    onChanged?: (value: string) => void;
    onTagsChanged?: (tags?: ITag[]) => void;
}

let timer: NodeJS.Timeout;

export const useTagSelect = (params: TagSelectParams) => {
    const logger = useLogger({ target: useTagSelect.name });

    const defaultOnChange = (newValue: string) => {
        if (newValue.length > 0) {
            search(newValue);
        } else {
            setSearchResults([]);
        }
    }

    const {
        debounceTime = 0,
        defaultValue,
        invalidSymbols = /[^А-яA-z0-9ё]*[_]*/gi,
        maxLength = 0,
        readonly,
        onChanged = defaultOnChange,
        onTagsChanged = noop,
    } = params;

    const [defaultInitialized, setDefaultInitialized] = useState(false);
    const [value, setValue] = useState<string>('');
    const [searchResults, setSearchResults] = useState<ITag[]>();
    const [currentTags, setCurrentTags] = useState<ITag[]>([]);
    const [abortRequest, setAbortRequest] = useState(false);

    const {
        alert: {
            useLazyFindTagQuery,
        },
    } = useApi();

    const [
        findTagRequest,
        {
            data: tags,
            isFetching,
            isLoading,
        }
    ] = useLazyFindTagQuery();

    const search = (tag: string) => {
        findTagRequest(tag);
    }

    const reset = () => {
        onChanged('');
        setValue('');
    }

    const set = (val: string) => {
        onChanged(val);
        setValue(val);
    }

    const onPlusClick = () => {
        setTimeout(() => {
            onSelectTag({
                name: value,
            });
        }, 0);
    }

    const onSelectTag = (tag: ITag) => {
        if (tag.name.length > 0) {
            if(!currentTags.some(x => x.name === tag.name)) {
                onTagsChanged([...currentTags].concat(tag));
            }
            setCurrentTags((prev) => {
                if(!prev.some(x => x.name === tag.name)) {
                    return [...prev].concat(tag);
                } else {
                    return prev;
                }
            });
        }
    }

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            event.stopPropagation();

            clearTimeout(timer);

            if (isFetching) {
                setAbortRequest(true);
            }
            onSelectTag({
                name: value,
            });
            reset();
        }
    }

    const onDeleteTagClick = (name: string) => {
        const result = [...currentTags.filter(x => x.name !== name)];
        onTagsChanged(result);

        setCurrentTags(prev => [...prev.filter(x => x.name !== name)]);        
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (readonly) return;

        let inputElementValue = event.target.value;

        if (maxLength && inputElementValue && inputElementValue.length > maxLength) return;

        let newValue: string;

        if (invalidSymbols) {
            newValue = inputElementValue.replace(invalidSymbols, '');
        } else {
            newValue = inputElementValue;
        }

        setValue(newValue.toLowerCase());

        if (debounceTime > 0) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                onChanged(newValue.toLowerCase());
            }, debounceTime);
        } else {
            onChanged(newValue.toLowerCase());
        }
    }

    const isLoadInProgress = isLoading || isFetching;

    useEffect(() => {
        if (!isFetching && (!tags || tags.length === 0)) {
            setSearchResults([]);
        } else if (!isFetching && tags) {
            if (!abortRequest) {
                setSearchResults(tags);
            } else {
                setAbortRequest(false);
            }
        }
    }, [tags, isFetching]);

    useEffect(() => {
        if (defaultValue && !defaultInitialized) {
            setCurrentTags(defaultValue);
            setDefaultInitialized(true);
        } else if (!defaultInitialized) {
            setCurrentTags([]);
        }
    }, [defaultValue]);

    return {
        currentTags,
        searchResults,
        isLoadInProgress,
        value,
        set,
        reset,
        onChange,
        onKeyDown,
        onPlusClick,
        onSelectTag,
        onDeleteTagClick,
    }
}