export interface StreamerActivity {
    days: number;
    spentTotal: number;
}

export interface StreamerLastActive {
    date: Date;
}

export interface TopStreamer {
    id: string;
    name: string;
    username: string;
    email: string;
    createdAt: string
    streamerLink: string;
    donationAlertsUsername: string;
    channelAlertsCount: number;
    onlyChannelStickers: boolean;
    twitchFriendly: boolean;
    familyFriendly: boolean;
    memePartyActive: boolean;
    registeredAt: Date;
    lastVisit: number;
    currencyName: string,
    alertPrice: number;
    activity: StreamerActivity;
    lastActive: StreamerLastActive;
    payers: number;
}

export interface TotalStreamers {
    streamersTotal: number;
    streamersActivated: number;
}

export interface TopStreamers {
    total: TotalStreamers;
    topStreamers: TopStreamer[];
}
