// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EventRecord__bonusRow--e8vwH {\n  background-color: rgba(0, 255, 224, 0.5);\n}\n\n.EventRecord__buyRow--TlXrv {\n  background-color: rgba(36, 255, 0, 0.5);\n}\n\n.EventRecord__expenseRow--Mj5QY {\n  background-color: rgba(255, 180, 229, 0.5);\n}", "",{"version":3,"sources":["webpack://./src/views/pages/Admin/components/ViewerEvents/components/EventRecord/EventRecord.scss"],"names":[],"mappings":"AAGA;EACI,wCAAA;AAFJ;;AAKA;EACI,uCAAA;AAFJ;;AAKA;EACI,0CAAA;AAFJ","sourcesContent":["@import '/src/common/styles/colors.scss';\n@import '/src/common/styles/mixins.scss';\n\n.bonusRow {\n    background-color: rgba(#00FFE0, 0.5);\n}\n\n.buyRow {\n    background-color: rgba(#24FF00, 0.5);\n}\n\n.expenseRow {\n    background-color: rgba(#FFB4E5, 0.5);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bonusRow": "EventRecord__bonusRow--e8vwH",
	"buyRow": "EventRecord__buyRow--TlXrv",
	"expenseRow": "EventRecord__expenseRow--Mj5QY"
};
export default ___CSS_LOADER_EXPORT___;
