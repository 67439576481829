import React, { useEffect, useState } from 'react'

export type InputValueType = string | ReadonlyArray<string> | number | undefined;

interface InputParams {
    defaultValue?: InputValueType;
    readonly?: boolean;
    maxLength?: number;
    maxNumberValue?: number;
    debounceTime?: number;
    resetTrigger?: unknown;
    invalidSymbols?: RegExp;
    onChange?: (value: InputValueType) => void;
}

let timer: NodeJS.Timeout;

export const useInput = (params: InputParams = {}) => {
    const {
        defaultValue = '',
        readonly,
        maxLength,
        maxNumberValue,
        debounceTime = 0,
        resetTrigger,
        invalidSymbols,
        onChange,
    } = params;

    const [value, setValue] = useState<InputValueType>(defaultValue);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;

        if (maxLength && value.length > maxLength) {
            return;
        }
        if (maxNumberValue) {
            if (Number(value) > maxNumberValue) {
                value = maxNumberValue.toString();
            }
        }
        if (invalidSymbols) {
            setValue(value.replace(invalidSymbols, ''));
        } else {
            setValue(value);
        }
        if (debounceTime > 0) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                onChange && onChange(value);
            }, debounceTime);
        } else {
            onChange && onChange(value);
        }
    }

    useEffect(() => {
        if (/* !value && */ defaultValue || defaultValue === '') {
            setValue(defaultValue);
            onChange && onChange(defaultValue);
        }
    }, [defaultValue]);

    useEffect(() => {
        setValue(defaultValue ?? '');
        onChange && onChange(defaultValue);
    }, [resetTrigger])

    return {
        value,
        onChange: readonly ? () => {} : handleChange,
    }
}