export interface Position {
    x: number;
    y: number;
}

export interface Geometry extends Position {
    rotation?: number;
    scale?: number;
}

export const defaultGeometry: Geometry = {
    x: 0,
    y: 0,
    scale: 1,
    rotation: 0,
}

export interface Dimensions {
    width: number;
    height: number;
}

export const defaultDimensions: Dimensions = {
    width: 0,
    height: 0,
}
