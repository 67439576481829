import { DOM_MODAL_ROOT_CONTAINER_ID } from '@const';
import { useDeviceDetection, useModals } from '@hooks';
import React from 'react'
import { createPortal } from 'react-dom';
import { ModalWindow } from '../ModalWindow';
import cn from 'classnames';

import s from './ModalShadow.scss';

const container = document.getElementById(DOM_MODAL_ROOT_CONTAINER_ID);
if (!container) throw new Error(`Modal root container with id='${DOM_MODAL_ROOT_CONTAINER_ID}' is missing in the index.html file`);

export const ModalShadow = () => {
    const { modals, closeModal } = useModals();
    const { isMobileLayoutRecommended } = useDeviceDetection();

    const onShadowClick = () => {
        if (!modals.at(-1)?.options?.preventCloseOnShadow) {
            closeModal();
        }
    }

    return modals?.length
        ? createPortal((
            <div className={s.shadow} onMouseDown={onShadowClick}>
                <div
                    className={cn(
                        s.modalContainer,
                        {
                            [s.modalContainerMobileLayout]: isMobileLayoutRecommended,
                        },
                        modals.at(-1)?.options?.containerClassName,
                    )}
                    onMouseDown={(e) => e.stopPropagation()}
                >
                    <ModalWindow {...modals.at(-1)?.options}>
                        {modals.at(-1)?.content}
                    </ModalWindow>
                </div>
            </div>
        ), container)
        : null;
}