import { IconFlameLine, IconImageDeclineLine, IconImagesLine, IconMagnifierLine, IconPetardLine, IconShitSolid } from '@common/images/svg';
import { AlertPlayer, ColoredButton, ColoredButtonColor, Column, Input, InputColor, Loader, Menu, MenuItem, Panel, Row } from '@components';
import { ModeratorHookReturnType, useCategories, useElementOnScreen, useModals, useModeration, usePlayingAlertsList } from '@hooks';
import { StickerDto, AlertSize, CategoryDto } from '@interfaces';
import { Paginator } from '@views/fragments';
import config from 'config';
import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { AlertOnModeration } from './components';
import s from './Moderation.scss';
import { ModerationMode } from '@typings';
import { MassTagPopup, SimilarStickersPopup } from '@views/modals';

export const Moderation = () => {
    const {
        currentUser,
    } = useOutletContext<ModeratorHookReturnType>();

    const {
        alerts,
        currentPage,
        totalPagesCount,
        totalStickersCount,
        activeTab,
        lastModeratedAlertId,
        filter,
        isStickersFetching,
        moderationStats: {
            total,
            top,
            onModeration,
            declined,
        } = {},
        approveAlert,
        declineAlert,
        returnLastAlertForModeration,
        updateAlert,
        silentUpdateAlert,
        changeFilter,
        showTab,
        changePage,
        updateLoadedAlert,
        massUpdateStickers,
    } = useModeration();

    const {
        addAlertToCategory,
        removeAlertFromCategory,
    } = useCategories();

    const { elementRef: topPaginatorRef, isOnScreen: isPaginatorOnScreen } = useElementOnScreen<HTMLDivElement>({
        threshold: 0,
        throttleTimeMs: 100,
    });

    const { showModal } = useModals();

    const [ soundEnabled, setSoundEnabled ] = useState(false);

    const onSoundClick = () => {
        setSoundEnabled(prev => !prev);
    }

    const onSimilarClick = (a: StickerDto) => {
        showModal({
            content: (
                <SimilarStickersPopup
                    alert={a}
                />
            ),
            options: {}
        });
    }

    const onMassEditClick = () => {
        if (activeTab === ModerationMode.ALL) 
        {
            showModal({
                content: (
                    <MassTagPopup
                        filter={filter}
                        massUpdateStickers={massUpdateStickers}
                        stickersCount={totalStickersCount}
                    />
                ),
                options: {
                    bodyClassName: s.noPaddingModal,
                }
            });
        }
    }

    const onCategorySelect = async (alert: StickerDto, category: CategoryDto) => {
        if (alert.categories?.includes(category.id)) {
            const updatedAlert = await removeAlertFromCategory({ stickerId: alert.id, categoryId: category.id }).unwrap();
            updateLoadedAlert(updatedAlert);
        } else {
            const updatedAlert = await addAlertToCategory({ stickerId: alert.id, categoryId: category.id }).unwrap();
            updateLoadedAlert(updatedAlert);
        }
    }

    const onChangePage = (page: number) => {
        changePage(page);
        if (topPaginatorRef.current && !isPaginatorOnScreen) {
            topPaginatorRef.current.scrollIntoView();
        }
    }

    const {
        list,
        addToList,
        removeFromList,
    } = usePlayingAlertsList({
        stickerSize: config?.design?.stickerSize?.[AlertSize.MEDIUM],
        availableAreaMask: 1,
    });

    const alertsElements = alerts.map((a, idx) => (
        <AlertOnModeration
            key={a.id}
            alert={a}
            soundMuted={!soundEnabled}
            onSetFilter={changeFilter}
            onApprove={approveAlert}
            onDecline={declineAlert}
            onUpdate={updateAlert}
            onSilentUpdate={silentUpdateAlert}
            onSoundClick={onSoundClick}
            onSimilarClick={() => onSimilarClick(a)}
            onClick={addToList}
            onCategorySelect={onCategorySelect}
        />
    ));

    return (
        <Panel className={s.moderationContainer}>
            {(currentUser?.flags?.isAdmin || currentUser?.flags?.isMasterModer) &&
                <Menu>
                    <MenuItem
                        icon={<IconImagesLine />}
                        caption={total ? `Каталог (${total})` : `Каталог`}
                        isActive={activeTab === ModerationMode.ALL}
                        onClick={() => showTab(ModerationMode.ALL)}
                    />
                    <MenuItem
                        icon={<IconShitSolid />}
                        caption={total ? `Блеклист` : `Блеклист`}
                        isActive={activeTab === ModerationMode.DISABLED}
                        onClick={() => showTab(ModerationMode.DISABLED)}
                    />
                    <MenuItem
                        icon={<IconShitSolid />}
                        caption={total ? `По дате` : `Блеклист`}
                        isActive={activeTab === ModerationMode.DISABLED_BY_DATE}
                        onClick={() => showTab(ModerationMode.DISABLED_BY_DATE)}
                    />
                    <MenuItem
                        icon={<IconPetardLine />}
                        caption={total ? `Топ (${top})` : `Топ`}
                        isActive={activeTab === ModerationMode.TOP}
                        onClick={() => showTab(ModerationMode.TOP)}
                    />
                    <MenuItem
                        icon={<IconFlameLine />}
                        caption={onModeration ? `На модерации (${onModeration})` : `На модерации`}
                        isActive={activeTab === ModerationMode.UNMODERATED}
                        onClick={() => showTab(ModerationMode.UNMODERATED)}
                    />
                    <MenuItem
                        icon={<IconImageDeclineLine />}
                        caption={declined ? `Отклоненные (${declined})` : `Отклоненные`}
                        isActive={activeTab === ModerationMode.DECLINED}
                        onClick={() => showTab(ModerationMode.DECLINED)}
                    />
                </Menu>
            }
            {(currentUser?.flags?.isAdmin || currentUser?.flags?.isMasterModer) &&
                <Row className={s.searchRow}>
                    <Input
                        tagIcon={<IconMagnifierLine />}
                        placeholder='Найти алерты'
                        debounceTime={500}
                        shadowActive
                        color={InputColor.ORANGE}
                        fullWidth
                        defaultValue={filter}
                        onChange={(value) => changeFilter(value?.toString() ?? '')}
                    />
                </Row>
            }
            <Panel>
                <Column fullWidth className={s.fullWidth}>
                            {lastModeratedAlertId && <ColoredButton caption={'Отменить последнее решение'} onClick={returnLastAlertForModeration} color={ColoredButtonColor.RED}/>}
                            {alertsElements.length > 0 && activeTab !== ModerationMode.DISABLED && activeTab !== ModerationMode.DISABLED_BY_DATE &&
                                <div className={s.topBar} ref={topPaginatorRef}>
                                    <Paginator
                                        caption='Страницы'
                                        maxPages={totalPagesCount}
                                        activePage={currentPage}
                                        onPageSelect={onChangePage}
                                    />
                                    {activeTab === ModerationMode.ALL &&
                                        <div>
                                            <ColoredButton
                                                caption={'Mass edit'}
                                                className={s.button}
                                                disabled={!filter && alerts.length > 0}
                                                color={ColoredButtonColor.WHITE}
                                                onClick={onMassEditClick}
                                            />
                                        </div>
                                    }
                                </div>
                            }
                            {isStickersFetching && <div className={s.loader}><Loader/></div>}
                            {alertsElements}
                            {!isStickersFetching && alertsElements.length > 0 &&
                                <Paginator
                                    caption='Страницы'
                                    maxPages={totalPagesCount}
                                    activePage={currentPage}
                                    onPageSelect={onChangePage}
                                />
                            }
                </Column>
            </Panel>
            { list.length > 0 &&
                <Panel className={s.alertsShadow}>
                    {
                        list.map(a => {
                            return (
                                <AlertPlayer
                                    key={a.uid}
                                    alert={a.alert}
                                    geometry={a.geometry}
                                    size={AlertSize.LARGE}
                                    onPlayEnd={() => removeFromList(a.uid)}
                                />
                            );
                        })
                    }
                </Panel>
            }
        </Panel>
    );
};
