// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ThemePreview__themePreviewContent--t6nEq {\n  justify-content: flex-start;\n}\n.ThemePreview__themePreviewRow--AgTm_ {\n  margin-bottom: 30px;\n}\n\n.ThemePreview__list--uPxJl {\n  margin: 20px;\n  display: flex;\n  column-gap: 20px;\n  flex: 1;\n}\n\n.ThemePreview__test--ji5mq {\n  min-width: 140px;\n  justify-content: space-between;\n}", "",{"version":3,"sources":["webpack://./src/views/pages/Admin/components/ThemePreview/ThemePreview.scss"],"names":[],"mappings":"AACI;EACI,2BAAA;AAAR;AAGI;EACI,mBAAA;AADR;;AAKA;EACI,YAAA;EACA,aAAA;EACA,gBAAA;EACA,OAAA;AAFJ;;AAKA;EACI,gBAAA;EACA,8BAAA;AAFJ","sourcesContent":[".themePreview {\n    &Content {\n        justify-content: flex-start;\n    }\n\n    &Row {\n        margin-bottom: 30px;\n    }\n}\n\n.list {\n    margin: 20px;\n    display: flex;\n    column-gap: 20px;\n    flex: 1;\n}\n\n.test {\n    min-width: 140px;\n    justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"themePreviewContent": "ThemePreview__themePreviewContent--t6nEq",
	"themePreviewRow": "ThemePreview__themePreviewRow--AgTm_",
	"list": "ThemePreview__list--uPxJl",
	"test": "ThemePreview__test--ji5mq"
};
export default ___CSS_LOADER_EXPORT___;
