import { ColoredButton, ColoredButtonColor, Loader, Logo, Panel, Row, SideMenu } from '@components';
import { useModerator } from '@hooks';
import { HeadedPage } from '@layouts';
import React from 'react'

import { menu } from './MenuItems';

import s from './Moderator.scss';
import cn from 'classnames';
import { Outlet, useNavigate } from 'react-router-dom';
import { ExternalModeration } from '../Admin';

export const Moderator = () => {
    const moderator = useModerator();

    const {
        currentUser,
        currentModerationShift,
        isCurrentUserFetching,
        isCurrentModerationShiftFetching,
        toggleShift,
    } = moderator;

    const navigate = useNavigate();

    const onShiftButtonClick = () => {
        void toggleShift()
    }

    const header = (
        <div className={s.header}>
            <div className={s.headerLogoContainer} onClick={() => navigate('/')}>
                <Logo />
            </div>
            <div className={s.headerTitle}>
                {'Панель модерации'}
            </div>
            <div className={s.headerExpander}/>
            <div className={s.headerDeviceInfo}>
                {navigator.userAgent}
            </div>
        </div>
    );

    return (
        <HeadedPage
            className={s.pageOverrides}
            header={header}
        >
            { currentUser?.flags.isMasterModer ?
                <Row fullHeight noGap className={s.contentRow}>
                    <SideMenu elements={menu}/>
                    <Outlet context={moderator} />
                </Row>
                :
                <Row fullHeight noGap className={s.contentRow}>
                    <Panel className={cn({[s.emptyPanel]: !currentModerationShift?.isOpen})}>
                        {isCurrentModerationShiftFetching || isCurrentUserFetching ?
                            <Loader className={s.loader}/>
                        :
                            <ColoredButton
                                className={s.shiftButton}
                                caption={currentModerationShift?.isOpen ? 'Закрыть смену' : 'Начать смену'}
                                color={currentModerationShift?.isOpen ? ColoredButtonColor.RED : ColoredButtonColor.ORANGE}
                                onClick={onShiftButtonClick}/>
                        }
                        {currentModerationShift?.isOpen && <ExternalModeration/>}
                    </Panel>
                </Row>
            }
        </HeadedPage>
    );
};
