import { useApi } from '@hooks';

export const useCategories = () => {
    const {
        alert: {
            useAddAlertToCategoryMutation,
            useRemoveAlertFromCategoryMutation,
        },
        categories: {
            useGetCategoriesListQuery,
        },
    } = useApi();

    const {
        data: categories,
    } = useGetCategoriesListQuery();

    const [
        addAlertToCategory,
    ] = useAddAlertToCategoryMutation();

    const [
        removeAlertFromCategory,
    ] = useRemoveAlertFromCategoryMutation();

    return {
        categories,
        addAlertToCategory,
        removeAlertFromCategory,
    }
}
