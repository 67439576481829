import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { useDeviceDetection, useLogger } from '@hooks';
import { MemeAlertsLogo } from '@images/svg';
import s from './Video.scss';
import { noop } from '@common';

interface VideoProps {
    source?: string;
    containerClassName?: string,
    videoClassName?: string;
    overlay?: JSX.Element;
    forceShowOverlay?: boolean;
    forceMute?: boolean;
    unmuteOnHover?: boolean;
    loop?: boolean;
    disabled?: boolean;
    volume?: number;
    videoStyles?: any;
    filterShadow?: boolean;
    onClick?: () => void;
    onHover?: () => void;
    onOut?: () => void;
    onReadyStateChange?: (ready: boolean) => void;
}

export const Video = (props: React.PropsWithChildren<VideoProps>) => {
    const {
        children,
        containerClassName,
        overlay,
        forceShowOverlay,
        forceMute = false,
        unmuteOnHover = false,
        source,
        videoClassName,
        loop,
        disabled,
        volume = 100,
        videoStyles,
        filterShadow = false,
        onClick = noop,
        onHover: onAlertHover = noop,
        onOut: onAlertOut = noop,
        onReadyStateChange = noop,
    } = props;

    const logger = useLogger({ target: 'Video' });
    const { isMobile } = useDeviceDetection();

    const clickable = onClick !== noop;

    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [muted, setMuted] = useState(unmuteOnHover);

    const onStartLoad = () => {
        setError(false);
        setLoaded(false);
        onReadyStateChange(false);
    }

    const onLoaded = () => {
        setLoaded(true);
        onReadyStateChange(true);
    }
    const onError = () => {
        setError(true);
        onReadyStateChange(false);
    }

    const video = useRef<HTMLVideoElement>(null);

    const onHover = () => {
        onAlertHover();
        if (unmuteOnHover) {
            setMuted(false);

            if (video.current) {
                video.current.currentTime = 0;
                void video.current.play();
            }
        }
    }

    const onOut = () => {
        onAlertOut();
        if (unmuteOnHover) {
            setMuted(true);
            if (video.current) {
                video.current.muted = true;
                void video.current.play();
            }
        }
    }

    const onVideoClick = () => {
        if (loaded && !error) {
            onClick();
        }
    }

    useEffect(() => {
        if (video.current) {
            video.current.volume = volume / 100;
        }
    }, [volume]);

    return (
        <div
            className={cn(
                s.videoContainer,
                containerClassName,
                {
                    [s.clickable]: clickable && loaded && !error,
                    [s.videoContainerBoxShadow]: !filterShadow,
                    [s.videoContainerFilter]: filterShadow,
                }
            )}
            style={...videoStyles}
            onClick={onVideoClick}
        >
            {/* Show error if the image could not be loaded */}
            { !loaded && error &&
                <div className={s.error}>
                    <div className={s.errorImage}>
                        <MemeAlertsLogo/>
                    </div>
                    <div className={s.errorText}>
                        {'Не удалось загрузить\nизображение'}
                    </div>
                </div>
            }

            {/* This is tha main animation that is shown on collection */}
            { !error &&
                <video
                    className={cn(
                        s.video,
                        videoClassName,
                        {
                            [s.hidden]: !loaded || error,
                            [s.videoDisabled]: disabled,
                        },
                    )}
                    playsInline={true}
                    autoPlay={true}
                    muted={muted || forceMute}
                    loop={loop ?? muted}
                    controls={false}
                    src={source}
                    ref={video}
                    onLoadStart={() => onStartLoad()}
                    onLoadedData={() => onLoaded()}
                    onError={() => onError()}
                />
            }

            {/* Image children, some content that is placed over the image but under the overlay */}
            { loaded && !error &&
                <div className={s.children}>
                    { children }
                </div>
            }

            {/* Image overlay - the container for extra components are placed over the image */}
            {  ((loaded && !error) || forceShowOverlay) &&
                (
                    !isMobile
                        ?
                            <div
                                className={s.overlay}
                                onMouseEnter={() => onHover()}
                                onMouseLeave={() => onOut()}
                            >
                                { overlay }
                            </div>
                        :
                            <div
                                className={s.overlay}
                            >
                                { overlay }
                            </div>
                        )
            }
        </div>
    )
}
