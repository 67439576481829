import { noop } from '@common';
import React, { useEffect, useState } from 'react';
import s from './Select.scss';
import { useLogger } from '@hooks';
import cn from 'classnames';

export type SelectOptionValueType = string | ReadonlyArray<string> | number;

export interface SelectOption {
    id: number;
    value?: SelectOptionValueType;
    caption: string;
    selected?: boolean;
}

export type SelectOptions = Array<SelectOption>;

interface SelectProps {
    className?: string;
    options?: SelectOptions;
    onSelectionChanged?: (selection?: SelectOption) => void;
    onValueChanged?: (value?: SelectOptionValueType) => void;
}

export const Select = (props: SelectProps) => {
    const {
        className,
        options = [],
        onSelectionChanged = noop,
        onValueChanged = noop,
    } = props;

    const logger = useLogger({ target: Select.name });

    const [selection, setSelection] = useState<SelectOption | undefined>(options.find(o => o.selected));

    const optionsElements = options.sort((a, b) => a.id > b.id ? 1 : a.id == b.id ? 0 : -1).map((o, idx) => (
        <option key={idx} value={o.id}>{o.caption}</option>
    ));

    const onSelectionChange = (value: string) => {
        const optionId = Number(value);
        const newValue = options.find(o => o.id === optionId)
        setSelection(newValue);
        onSelectionChanged(newValue);
        onValueChanged(newValue?.value);
    }

    useEffect(() => {
        setSelection(options.find(o => o.selected));
    }, [options]);

    return (
        <div className={cn(s.select, className)}>
            <select className={s.selectControl} value={selection?.id} onChange={(s) => onSelectionChange(s.target.value)}>
                { optionsElements }
            </select>
        </div>
    )
}