import cn from 'classnames';
import React from 'react';

import s from './TextArea.scss';
import { TextAreaValueType, useTextArea } from './hooks/TextArea';
import { ComponentColor } from '@components/common';

interface TextAreaProps {
    className?: string;
    readonly?: boolean;
    placeholder?: string;
    maxRows?: number;
    maxLength?: number;
    defaultValue?: string;
    debounceTime?: number;
    color?: ComponentColor;
    onChange?: (value: TextAreaValueType) => void;
}

export const TextArea = (props: TextAreaProps) => {
    const {
        className,
        placeholder,
        maxRows = 5,
        maxLength,
        color = ComponentColor.DEFAULT,
    } = props;

    const textArea = useTextArea({...props});

    const textAreaClassNames = cn(
        s.text,
        className,
        s['color' + color],
    );

    return (
        <div
            className={textAreaClassNames}
        >
            <textarea
                rows={maxRows}
                placeholder={placeholder}
                maxLength={maxLength}

                {...textArea}
            >
            </textarea>
        </div>
    )
}