import React, { useRef, useState } from 'react';

import cn from 'classnames';

import { MemeAlertsLogo } from '@images/svg';
import s from './Picture.scss';

interface PictureProps {
    source?: string;
    containerClassName?: string,
    imgClassName?: string;
    overlay?: JSX.Element;
    forceShowOverlay?: boolean;
    isMemeView?: boolean;
    disabled?: boolean;
    onClick?: () => void;
}

export const Picture = (props: React.PropsWithChildren<PictureProps>) => {
    const {
        children,
        source,
        containerClassName,
        imgClassName,
        overlay,
        forceShowOverlay,
        isMemeView,
        disabled,
        onClick,
    } = props;

    const clickable = !!onClick;

    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);

    const container = useRef<HTMLDivElement>(null);

    const [borderRadius, setBorderRadius] = useState({
        borderRadius: '10%',
    });

    const onLoaded = () => {
        setLoaded(true);
        if (container.current) {
            const borderRadius = ((container.current.offsetWidth + container.current.offsetHeight) / 2) * 0.1;
            setBorderRadius({
                borderRadius: borderRadius + 'px',
            })
        }
    };

    const onError = () => setError(true);

    const onImageClick = () => {
        if (loaded && !error) {
            onClick && onClick();
        }
    }

    return (
        <div style={isMemeView ? borderRadius : {}} className={cn(
            s.imageContainer,
            containerClassName,
            {
                [s.clickable]: clickable && loaded && !error,
                [s.imageContainerLoading]: !loaded && !error,
            }
        )} onClick={onImageClick} ref={container}>
            {/* Show error if the image could not be loaded */}
            { !loaded && error &&
                <div className={s.error}>
                    <div className={s.errorImage}>
                        <MemeAlertsLogo/>
                    </div>
                    <div className={s.errorText}>
                        {'Не удалось загрузить\nизображение'}
                    </div>
                </div>
            }

            {/* Show image if it is loaded without errors */}
            { !error &&
                <img
                    style={isMemeView ? borderRadius : {}}
                    className={cn(
                        s.image,
                        imgClassName,
                        {
                            [s.hidden]: !loaded || error,
                            [s.imageDisabled]: disabled,
                        },
                    )}
                    src={source}
                    onLoadedMetadata={() => onLoaded()}
                    onLoad={() => onLoaded()}
                    onError={() => onError()}
                />
            }

            { loaded && !error &&
                <div className={s.children}>
                    { children }
                </div>
            }

            {/* Image overlay - the container for extra components are placed over the image */}
            {  ((loaded && !error) || forceShowOverlay) &&
                <div className={s.overlay}>
                        {overlay}
                </div>
            }
        </div>
    )
}