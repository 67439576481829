import { Button, Loader, Panel, Row } from '@components';
import { StatsMode, useStats } from '@hooks';
import * as Highcharts from 'highcharts';
import HighchartsDefault, { HighchartsReact } from 'highcharts-react-official';
import cn from 'classnames';
import React, { useRef } from 'react';
import s from './Stats.scss';
import { StatisticRecord } from './components';

export const Stats = () => {
    const {
        mode,
        currentDayStats,
        currentMonthStats,
        dailyStatistic,
        monthlyStatistic,
        isDailyStatisticLoading,
        isDailyEndReached,
        isMonthlyStatisticLoading,
        isMonthlyEndReached,
        setMode,
        loadNextDailyPage,
        loadNextMonthlyPage,
    } = useStats();

    const isLoading = (mode === StatsMode.DAILY && isDailyStatisticLoading) || (mode === StatsMode.MONTHLY && isMonthlyStatisticLoading);

    const chartComponentRef = useRef<HighchartsDefault.RefObject>(null);

    const rows = mode === StatsMode.DAILY
        ? dailyStatistic?.map((r, idx) => (
            <StatisticRecord data={r} key={idx}/>
        ))
        : monthlyStatistic?.map((r, idx) => (
            <StatisticRecord data={r} key={idx} showAsMonth/>
        ))

    const activeStatistic = mode === StatsMode.DAILY ? dailyStatistic.sort((a, b) => b.day - a.day) : monthlyStatistic.sort((a, b) => b.day - a.day);

    const series = [];
    series[0] = { type: 'line', name: 'New Users', data: activeStatistic?.map((item) => ([item.day, item.newUsers ?? 0])) };
    series[1] = { type: 'line', name: 'Total Users', visible: false, data: activeStatistic?.map((item) => ([item.day, item.totalUsers ?? 0])) };
    series[2] = { type: 'line', name: 'New Streamers', visible: false, data: activeStatistic?.map((item) => ([item.day, item.newStreamers ?? 0])) };
    series[3] = { type: 'line', name: 'Total Streamers', visible: false, data: activeStatistic?.map((item) => ([item.day, item.totalStreamers ?? 0])) };
    series[4] = { type: 'line', name: 'Active Streamers', visible: false, data: activeStatistic?.map((item) => ([item.day, item.activeStreamers ?? 0])) };
    series[5] = { type: 'line', name: 'AS%', visible: false, data: activeStatistic?.map((item) => ([item.day, parseFloat(item.activeStreamers === 0 || item.totalStreamers === 0 ? '0' : ((item.activeStreamers / item.totalStreamers) * 100).toFixed(2))])) };
    series[6] = { type: 'line', name: 'Streamers Hits', visible: false, data: activeStatistic?.map((item) => ([item.day, item.streamerPageHits ?? 0])) };
    series[7] = { type: 'line', name: 'Hits / AS', visible: false, data: activeStatistic?.map((item) => ([item.day, parseFloat(item.activeStreamers === 0 || item.streamerPageHits === 0 ? '0' : (item.streamerPageHits / item.activeStreamers).toFixed(2))])) };
    series[8] = { type: 'line', name: 'Sticker Open', visible: false, data: activeStatistic?.map((item) => ([item.day, item.sendStickerPopups ?? 0])) };
    series[9] = { type: 'line', name: 'Sticker Sent', visible: false, data: activeStatistic?.map((item) => ([item.day, item.sales ?? 0])) };
    series[10] = { type: 'line', name: 'FS Sent', visible: false, data: activeStatistic?.map((item) => ([item.day, item.fullscreen ?? 0])) };
    series[11] = { type: 'line', name: 'MP', visible: false, data: activeStatistic?.map((item) => ([item.day, item.memePartyCount ?? 0])) };
    series[12] = { type: 'line', name: 'Sent / Open', visible: false, data: activeStatistic?.map((item) => ([item.day, parseFloat(item.sales === 0 || item.sendStickerPopups === 0 ? '0' : (item.sales / item.sendStickerPopups).toFixed(2))])) };
    series[13] = { type: 'line', name: 'New Payers', visible: false, data: activeStatistic?.map((item) => ([item.day, item.newPayersCount ?? 0])) };
    series[14] = { type: 'line', name: 'Total Payers', visible: false, data: activeStatistic?.map((item) => ([item.day, item.totalPayersCount ?? 0])) };
    series[15] = { type: 'line', name: 'Payers', visible: false, data: activeStatistic?.map((item) => ([item.day, item.uniquePayersCount ?? 0])) };
    series[16] = { type: 'line', name: 'Senders', visible: false, data: activeStatistic?.map((item) => ([item.day, item.uniqueSendersCount ?? 0])) };
    series[17] = { type: 'line', name: 'Sent / Senders', visible: false, data: activeStatistic?.map((item) => ([item.day, parseFloat(item.sales === 0 || item.uniqueSendersCount === 0 ? '0' : (item.sales / item.uniqueSendersCount).toFixed(2))])) };
    series[18] = { type: 'line', name: 'Purchase', visible: false, data: activeStatistic?.map((item) => ([item.day, item.purchasedCurrencyValue ?? 0])) };
    series[19] = { type: 'line', name: 'Spent', visible: false, data: activeStatistic?.map((item) => ([item.day, item.spentCurrencyValue ?? 0])) };
    series[20] = { type: 'line', name: 'Balance', visible: false, data: activeStatistic?.map((item) => ([item.day, item.totalCurrencyInWallets ?? 0])) };
    series[21] = { type: 'line', name: 'ARPAS', visible: false, data: activeStatistic?.map((item) => ([item.day, parseFloat(item.revenue === 0 || item.activeStreamers === 0 ? '0' : (item.revenue / item.activeStreamers).toFixed(2))])) };
    series[22] = { type: 'line', name: 'ARPPU', visible: false, data: activeStatistic?.map((item) => ([item.day, parseFloat(item.revenue === 0 || item.uniquePayersCount === 0 ? '0' : (item.revenue / item.uniquePayersCount).toFixed(2))])) };
    series[23] = { type: 'line', name: 'Revenue', visible: false, data: activeStatistic?.map((item) => ([item.day, item.revenue ?? 0])) };
    series[24] = { type: 'line', name: 'Profit', visible: false, data: activeStatistic?.map((item) => ([item.day, parseFloat(item.profit.toFixed(2))])) };

    const options: Highcharts.Options = {
        title: {
            text: mode === StatsMode.DAILY ? 'Дневная статистика' : 'Месячная статистика'
        },
        tooltip: {
            formatter: function() {
                if (this.x) {
                    return new Date(parseInt(this.x.toString())).toLocaleDateString() + ' - ' + this.y;
                } else {
                    return this.y?.toString();
                }
            },
        },
        chart: {
            style: {
                'float': 'left'
            },
        },
        yAxis: {
            title: {
                text: 'Values'
            }
        },
        xAxis: {
            title: {
                text: 'Time'
            },
            labels: {
                formatter: function() {
                    if (mode === StatsMode.DAILY) {
                        return new Date(this.value).toLocaleDateString()
                    } else {
                        return new Date(this.value).toLocaleDateString('ru-RU', {
                            month: 'long',
                        })
                    }
                }
            },
        },
        series: series as Highcharts.SeriesOptionsType[],
    };

    return (
        <Panel className={s.statsContainer}>
            <Row noGap>
                <div className={cn(
                    s.statsTab,
                    { [s.statsTabActive]: mode === StatsMode.DAILY },
                )} onClick={() => setMode(StatsMode.DAILY)}>
                    {`Дневная статистика`}
                </div>
                <div className={cn(
                    s.statsTab,
                    { [s.statsTabActive]: mode === StatsMode.MONTHLY },
                )} onClick={() => setMode(StatsMode.MONTHLY)}>

                    {`Месячная статистика`}
                </div>
            </Row>
            {
                isLoading
                    ? <Loader />
                    : <>
                        <div className={s.chartContainer}>
                            <div className={s.chart}>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={options}
                                    ref={chartComponentRef}
                                />
                            </div>
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <td>
                                        { mode === StatsMode.DAILY ? "Date" : "Month" }
                                    </td>
                                    <td>
                                        { 'New Users' }
                                    </td>
                                    <td>
                                        { 'Total Users' }
                                    </td>
                                    <td>
                                        { 'New Streamers' }
                                    </td>
                                    <td>
                                        { 'Total Streamers' }
                                    </td>
                                    <td>
                                        { 'Active Streamers' }
                                    </td>
                                    <td>
                                        { 'AS%' }
                                    </td>
                                    <td>
                                        { 'Streamers Hits' }
                                    </td>
                                    <td>
                                        { 'Hits / AS' }
                                    </td>
                                    <td>
                                        { 'Sticker Open' }
                                    </td>
                                    <td>
                                        { 'Sticker Sent' }
                                    </td>
                                    <td>
                                        { 'FS Sent' }
                                    </td>
                                    <td>
                                        { 'MP' }
                                    </td>
                                    <td>
                                        { 'Sent / Open' }
                                    </td>
                                    <td>
                                        { 'New Payers' }
                                    </td>
                                    <td>
                                        { 'Total Payers' }
                                    </td>
                                    <td>
                                        { 'Payers' }
                                    </td>
                                    <td>
                                        { 'Senders' }
                                    </td>
                                    <td>
                                        { 'Sent / Senders' }
                                    </td>
                                    <td>
                                        { 'Purchase' }
                                    </td>
                                    <td>
                                        { 'Spent' }
                                    </td>
                                    <td>
                                        { 'Balance' }
                                    </td>
                                    <td>
                                        { 'ARPAS' }
                                    </td>
                                    <td>
                                        { 'ARPPU' }
                                    </td>
                                    <td>
                                        { 'Revenue' }
                                    </td>
                                    <td>
                                        { 'Profit' }
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {mode === StatsMode.DAILY && currentDayStats &&
                                    <StatisticRecord data={currentDayStats} key={-1}/>
                                }

                                {mode === StatsMode.MONTHLY && currentMonthStats &&
                                    <StatisticRecord data={currentMonthStats} key={-1} showAsMonth/>
                                }

                                {rows}
                            </tbody>
                        </table>


                        {mode === StatsMode.DAILY && !isDailyEndReached &&
                            <Button
                                caption={'Загрузить еще'}
                                className={s.loadMoreButton}
                                onClick={loadNextDailyPage}
                            />
                        }

                        {mode === StatsMode.MONTHLY && !isMonthlyEndReached &&
                            <Button
                                caption={'Загрузить еще'}
                                className={s.loadMoreButton}
                                onClick={loadNextMonthlyPage}
                            />
                        }
                    </>
            }
        </Panel>
    );
};