import React, { ReactNode, useState } from 'react';

import cn from 'classnames';

import s from './Button.scss';

export interface ButtonProps {
    className?: string;
    caption: string | ReactNode;
    disabled?: boolean;
    onClick: () => void;
}

export const Button = (props: ButtonProps) => {
    const { caption, className, disabled, onClick } = props;

    const [pressed, setPressed] = useState(false);

    return (
        <button
            type='button'
            disabled={disabled}
            className={cn(
                s.button,
                className,
                {
                    [s.buttonPressed]: pressed,
                    [s.disabled]: disabled,
                },
            )}
            onClick={onClick}
            onMouseDown={() => setPressed(true)}
            onMouseUp={() => setPressed(false)}
        >
            {caption}
        </button>
    )
}