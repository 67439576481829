import { SearchStatisticData } from '@interfaces';
import React from 'react';


interface StatisticRecordProps {
    data: SearchStatisticData;
    showAsMonth?: boolean;
}

export const StatisticRecord = (props: StatisticRecordProps) => {
    const {
        data,
        showAsMonth,
    } = props;

    return (
        <tr>
            <td>
                { showAsMonth
                    ? new Date(data.day).toLocaleDateString('ru-RU', {
                        month: 'long',
                    })
                    : new Date(data.day).toLocaleDateString()
                }
            </td>
            <td>
                { data.search ?? 0 }
            </td>
            <td>
                { data.searchNull ?? 0 }
            </td>
            <td>
                { data.search ? ((data.searchNull / data.search) * 100).toFixed(2) + ' %' : '-' }
            </td>
            <td>
                { data.searchSent ?? 0 }
            </td>
            <td>
                { data.search ? ((data.searchSent / data.search) * 100).toFixed(2) + ' %' : '-' }
            </td>
        </tr>
    );
};
