import { Button, Loader, Panel, Row } from '@components';
import { useSearchStats } from '@hooks';
import cn from 'classnames';
import React from 'react';
import s from './SearchStats.scss';
import { StatisticRecord } from './components';

export const SearchStats = () => {
    const {
        currentDayStats,
        dailyStatistic,
        isDailyStatisticLoading,
        isDailyEndReached,
        loadNextDailyPage,
    } = useSearchStats();

    const isLoading = isDailyStatisticLoading;

    const rows = dailyStatistic?.map((r, idx) => (
        <StatisticRecord data={r} key={idx}/>
    ));

    return (
        <Panel className={s.statsContainer}>
            <Row noGap>
                <div className={cn(
                    s.statsTab,
                    s.statsTabActive,
                )}>
                    {`Дневная статистика`}
                </div>
            </Row>
            {
                isLoading
                    ? <Loader />
                    : <>
                        <table>
                            <thead>
                                <tr>
                                    <td>
                                        { "Date" }
                                    </td>
                                    <td>
                                        { 'Search' }
                                    </td>
                                    <td>
                                        { 'SearchNull' }
                                    </td>
                                    <td>
                                        { 'SearchNullP' }
                                    </td>
                                    <td>
                                        { 'SearchSent' }
                                    </td>
                                    <td>
                                        { 'SearchSentP' }
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                { currentDayStats &&
                                    <StatisticRecord data={currentDayStats} key={-1}/>
                                }

                                {rows}
                            </tbody>
                        </table>


                        {!isDailyEndReached &&
                            <Button
                                caption={'Загрузить еще'}
                                className={s.loadMoreButton}
                                onClick={loadNextDailyPage}
                            />
                        }
                    </>
            }
        </Panel>
    );
};