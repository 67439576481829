import { StatisticData } from '@interfaces';
import React from 'react';


interface StatisticRecordProps {
    data: StatisticData;
    showAsMonth?: boolean;
}

export const StatisticRecord = (props: StatisticRecordProps) => {
    const {
        data,
        showAsMonth,
    } = props;

    const asp = data.activeStreamers === 0 || data.totalStreamers === 0 ? 0 : ((data.activeStreamers / data.totalStreamers) * 100).toFixed(2);
    const shas = data.activeStreamers === 0 || data.streamerPageHits === 0 ? 0 : (data.streamerPageHits / data.activeStreamers).toFixed(2);
    const so = data.sales === 0 || data.sendStickerPopups === 0 ? 0 : (data.sales / data.sendStickerPopups).toFixed(2);
    const ss = data.sales === 0 || data.uniqueSendersCount === 0 ? 0 : (data.sales / data.uniqueSendersCount).toFixed(2);
    const arpas = data.revenue === 0 || data.activeStreamers === 0 ? 0 : (data.revenue / data.activeStreamers).toFixed(2);
    const arpu = data.revenue === 0 || data.uniquePayersCount === 0 ? 0 : (data.revenue / data.uniquePayersCount).toFixed(2);

    return (
        <tr>
            <td>
                { showAsMonth
                    ? new Date(data.day).toLocaleDateString('ru-RU', {
                        month: 'long',
                    })
                    : new Date(data.day).toLocaleDateString()
                }
            </td>
            <td>
                { data.newUsers ?? 0 }
            </td>
            <td>
                { data.totalUsers ?? 0 }
            </td>
            <td>
                { data.newStreamers }
            </td>
            <td>
                { data.totalStreamers }
            </td>
            <td>
                { data.activeStreamers ?? 0 }
            </td>
            <td>
                { asp }
            </td>
            <td>
                { data.streamerPageHits }
            </td>
            <td>
                { shas }
            </td>
            <td>
                { data.sendStickerPopups }
            </td>
            <td>
                { data.sales }
            </td>
            <td>
                { data.fullscreen ?? 0 }
            </td>
            <td>
                { data.memePartyCount }
            </td>
            <td>
                { so }
            </td>
            <td>
                { data.newPayersCount }
            </td>
            <td>
                { data.totalPayersCount }
            </td>
            <td>
                { data.uniquePayersCount ?? 0 }
            </td>
            <td>
                { data.uniqueSendersCount ?? 0 }
            </td>
            <td>
                { ss }
            </td>
            <td>
                { data.purchasedCurrencyValue?.toFixed(1) ?? 0 }
            </td>
            <td>
                { data.spentCurrencyValue ?? 0 }
            </td>
            <td>
                { data.totalCurrencyInWallets?.toFixed(0) ?? 0}
            </td>
            <td>
                { arpas }
            </td>
            <td>
                { arpu }
            </td>
            <td>
                { data.revenue }
            </td>
            <td>
                { data.profit.toFixed(2) }
            </td>
        </tr>
        // <Row className={s.record} noGap>
        //     <Column className={s.recordColumn}>
        //         { showAsMonth
        //             ? new Date(data.day).toLocaleDateString('ru-RU', {
        //                 month: 'long',
        //             })
        //             : new Date(data.day).toLocaleDateString()
        //         }
        //     </Column>
        //     <Column className={s.recordColumn}>
        //         { data.newUsers ?? 0 }
        //     </Column>
        //     <Column className={s.recordColumn}>
        //         { data.totalUsers ?? 0 }
        //     </Column>
        //     <Column className={s.recordColumn}>
        //         { data.newStreamers }
        //     </Column>
        //     <Column className={s.recordColumn}>
        //         { data.totalStreamers }
        //     </Column>
        //     <Column className={s.recordColumn}>
        //         { data.activeStreamers ?? 0 }
        //     </Column>
        //     <Column className={s.recordColumn}>
        //         { data.streamerPageHits }
        //     </Column>
        //     <Column className={s.recordColumn}>
        //         { data.sendStickerPopups }
        //     </Column>
        //     <Column className={s.recordColumn}>
        //         { data.sales }
        //     </Column>
        //     <Column className={s.recordColumn}>
        //         { data.purchasedCurrencyValue ?? 0 }
        //     </Column>
        //     <Column className={s.recordColumn}>
        //         { data.spentCurrencyValue ?? 0 }
        //     </Column>
        //     <Column className={s.recordColumn}>
        //         { data.totalCurrencyInWallets ?? 0}
        //     </Column>
        //     <Column className={s.recordColumn}>
        //         { data.revenue }
        //     </Column>
        //     <Column className={s.recordColumn}>
        //         { data.profit.toFixed(2) }
        //     </Column>
        // </Row>
    );
};
