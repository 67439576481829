import React from 'react';

import cn from 'classnames';

import s from './Label.scss';

export enum LabelIconPosition {
    LEFT,
    RIGHT,
}

export enum LabelSize {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
    EXTRA = 'extra',
}

interface LabelProps {
    caption: string;
    className?: string;
    iconClassName?: string;
    icon?: JSX.Element;
    iconPosition?: LabelIconPosition;
    size?: LabelSize;
    onClick?: () => void;
}

export const Label = (props: LabelProps) => {
    const {
        className,
        iconClassName,
        caption,
        size = LabelSize.MEDIUM,
        icon,
        iconPosition = LabelIconPosition.LEFT,
        onClick,
    } = props;

    const clickable = !!onClick;

    return (
        <div className={cn(
            className,
            s.label,
            s[size],
            {
                [s.labelReverseOrder]: iconPosition === LabelIconPosition.RIGHT,
                [s.clickable]: clickable,
            },
        )} onClick={onClick}>
            {
                !!icon &&
                <div className={cn(s.labelIcon, iconClassName)}>
                    {icon}
                </div>
            }
            <div className={s.labelCaption}>{caption}</div>
        </div>
    )
}