import React from 'react';
import cn from 'classnames';
import { NotificationData, NotificationType } from '@contexts';

import s from './Notification.scss';
import { IconCloseLine } from '@images/svg';

interface NotificationProps extends NotificationData {
    onClose?: () => void;
}

export const Notification = (props: NotificationProps) => {
    const {
        uid,
        text,
        type = NotificationType.INFO,
        onClose,
    } = props;

    return (
        <div className={cn(s.notification, s[type])}>
            <div className={s.notificationCloseIcon} onClick={onClose}>
                <IconCloseLine />
            </div>
            <div className={s.notificationText}>
                {text}
            </div>
        </div>
    )
}