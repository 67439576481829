import { noop } from '@common';
import { useLogger } from '@hooks';
import React, { useEffect, useState } from 'react'

interface TextFieldParams {
    debounceTime?: number;
    defaultValue?: string;
    invalidSymbols?: RegExp;
    maxLength?: number;
    readonly?: boolean;
    onChanged?: (value: string) => void;
}

let timer: NodeJS.Timeout;

export const useTextField = (params: TextFieldParams) => {
    const logger = useLogger({ target: useTextField.name });

    const {
        debounceTime = 0,
        defaultValue = '',
        invalidSymbols,
        maxLength = 0,
        readonly,
        onChanged = noop,
    } = params;

    const [value, setValue] = useState(defaultValue);

    const reset = () => {
        onChanged('');
        setValue('');
    }

    const set = (val: string) => {
        // if (val !== value) {
            logger.debug(`New value ${val}, Old value ${value}`);
            onChanged(val);
            setValue(val);
        // }
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (readonly) return;

        let inputElementValue = event.target.value;

        if (maxLength && inputElementValue && inputElementValue.length > maxLength) {
            inputElementValue = inputElementValue.substring(0, maxLength);
        }

        let newValue: string;

        if (invalidSymbols) {
            newValue = inputElementValue.replace(invalidSymbols, '');
        } else {
            newValue = inputElementValue;
        }

        setValue(newValue);

        if (debounceTime > 0) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                onChanged(newValue);
            }, debounceTime);
        } else {
            onChanged(newValue);
        }
    }

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    return {
        value,
        onChange,
        reset,
        set,
    }
}