import { Button, Loader, Panel, Row } from '@components';
import { useSendAlertsIntervalStats } from '@hooks';
import React from 'react';
import cn from 'classnames';
import s from './SendAlertsIntervalStats.scss';
import { StatisticRecord } from './components';

export const SendAlertsIntervalStats = () => {
    const {
        currentStats,
        days,
        isLoadingPage,
        isEndReached,
        loadNextPage,
        setDays,
    } = useSendAlertsIntervalStats();

    const rows = currentStats?.map((r, idx) => (
        <StatisticRecord data={r} key={idx}/>
    ));

    return (
        <Panel className={s.statsContainer}>
            <Row noGap>
                <div className={cn(
                    s.statsTab,
                    { [s.statsTabActive]: days === 30 },
                )} onClick={() => setDays(30)}>
                    {`30 дней`}
                </div>
                <div className={cn(
                    s.statsTab,
                    { [s.statsTabActive]: days === 7 },
                )} onClick={() => setDays(7)}>
                    {`7 дней`}
                </div>
                <div className={cn(
                    s.statsTab,
                    { [s.statsTabActive]: days === 1 },
                )} onClick={() => setDays(1)}>
                    {`Вчера`}
                </div>
                <div className={cn(
                    s.statsTab,
                    { [s.statsTabActive]: days === 0 },
                )} onClick={() => setDays(0)}>
                    {`Сегодня`}
                </div>
            </Row>
            {
                isLoadingPage
                    ? <Loader />
                    : <>
                        <table>
                            <thead>
                                <tr>
                                    <td>
                                        { 'Name' }
                                    </td>
                                    <td>
                                        { 'Sent' }
                                    </td>
                                    <td>
                                        { 'Senders' }
                                    </td>
                                    <td>
                                        { 'Streamers' }
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                { rows }
                            </tbody>
                        </table>

                        {!isEndReached &&
                            <Button
                                caption={'Загрузить еще'}
                                className={s.loadMoreButton}
                                onClick={loadNextPage}
                            />
                        }
                    </>
            }
        </Panel>
    );
};