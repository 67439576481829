import React from 'react';
import { StickerDto, AlertUpdateDto, CategoryDto, EEtmaRating, ITag, IUser, IGame } from '@interfaces';
import { Alert, AlertSizeCalculationBase, ColoredButton, ColoredButtonColor, Column, GameSelect, Input, InputValueType, Label, LabelSize, Row, StreamerSelect, Switch, SwitchColor, TagSelect, TextArea } from '@components';
import cn from 'classnames';
import s from './AlertOnModeration.scss';
import { IconEtmaA, IconEtmaE, IconEtmaM, IconEtmaT, IconThumbDownLine, IconThumbUpLine } from '@common/images/svg';
import { noop } from '@common';
import { useModals } from '@hooks';
import {DeleteOrRestoreUserPopup, RejectStickerPopup} from '@views/modals';

interface AlertOnModerationProps {
    alert: StickerDto;
    soundMuted?: boolean;
    onApprove?: (stickerId: string) => void;
    onDecline?: (stickerId: string, rejectReason?: string) => void;
    onUpdate?: (alertData: AlertUpdateDto) => void;
    onSilentUpdate?: (alertData: AlertUpdateDto) => void;
    onSoundClick?: () => void;
    onClick?: (alert: StickerDto) => void;
    onCategorySelect?: (alert: StickerDto, category: CategoryDto) => void;
}

export const AlertOnModeration = (props: AlertOnModerationProps) => {
    const {
        alert,
        soundMuted,
        onApprove,
        onDecline,
        onUpdate,
        onSilentUpdate = noop,
        onSoundClick,
        onClick,
        onCategorySelect,
    } = props;

    const { showModal } = useModals();

    const formatCreationTimestamp = (timestamp?: number) => {
        if (!timestamp) {
            return '';
        }
        const date = new Date(timestamp);
        return date.toLocaleDateString('ru-RU', {
            day: '2-digit',
            month: 'short',
            hour: '2-digit',
            minute: '2-digit',
        }).replace(/\./g, '');
    }

    const formatAuthor = (alert: StickerDto) => {
        const total = (alert.creatorTotalApproved ?? 0) + (alert.creatorTotalDeclined ?? 0);
        const approvedPercent = total === 0 || !alert.creatorTotalApproved ? 0 : ((alert.creatorTotalApproved ?? 0) / total * 100);
        const approvedPercentFixed = approvedPercent.toFixed(0);

        return (
            <div>Автор: <span className={s.authorName}>
                {alert.creatorName ?? 'Неизвестен'}</span>
                <span className={s.green}> {alert.creatorTotalApproved}</span> 
                <span className={s.red}> {alert.creatorTotalDeclined}</span>
                <div className={cn(s.moderationHistoryStats, {
                        [s.green]: approvedPercent >= 70,
                        [s.yellow]: approvedPercent >= 50 && approvedPercent < 70,
                        [s.red]: approvedPercent > 30 && approvedPercent < 50,
                        [s.darkRed]: approvedPercent <= 30
                    })}>
                    <span className={s.info}> {approvedPercentFixed}%</span>
                </div>
            </div>
        )
    }

    const onCategoryClick = (category: CategoryDto) => {
        onCategorySelect && onCategorySelect(alert, category);
    }

    const onNameUpdate = (value: InputValueType) => {
        const newName = value?.toString() ?? "";
        if (newName !== alert.name) {
            onSilentUpdate({ id: alert.id, name: value?.toString() ?? '' });
        }
    }

    const onDescriptionUpdate = (value: InputValueType) => {
        const newDesc = value?.toString() ?? "";
        if (newDesc !== alert.description) {
            onSilentUpdate({ id: alert.id, description: value?.toString() ?? '' });
        }
    }

    const onSpeech2TextUpdate = (value: InputValueType) => {
        const newText = value?.toString() ?? "";
        const oldText = alert.speech2text ?? "";
        if (newText !== oldText) {
            onSilentUpdate({ id: alert.id, speech2text: value?.toString() ?? '' });
        }
    }

    const onOcrTextUpdate = (value: InputValueType) => {
        const newText = value?.toString() ?? "";
        const oldText = alert.ocrText ?? "";
        if (newText !== oldText) {
            onSilentUpdate({ id: alert.id, ocrText: value?.toString() ?? '' });
        }
    }

    const onMusicUpdate = (value: InputValueType) => {
        const newText = value?.toString() ?? "";
        const oldText = alert.music ?? "";
        if (newText !== oldText) {
            onSilentUpdate({ id: alert.id, music: value?.toString() ?? '' });
        }
    }

    const onStreamerUpdate = (streamer?: IUser) => {
        const newStreamerId = streamer?._id || null;
        if (newStreamerId !== alert.streamerId) {
            onSilentUpdate({ id: alert.id, streamerId: newStreamerId });
        }
    }

    const onGameUpdate = (game?: IGame) => {
        const newGameId = game?._id || null;
        if (newGameId !== alert.gameId) {
            onSilentUpdate({ id: alert.id, gameId: newGameId });
        }
    }

    const onTagsUpdate = (tags?: ITag[]) => {
        const newTags = tags || [];
        onSilentUpdate({ id: alert.id, tags: newTags });
    }

    // const onNsfwUpdate = (value: boolean) => {
    //     if (alert.isAdult !== value) {
    //         onUpdate && onUpdate({ id: alert.id, isAdult: value });
    //     }
    // }

    const onisNotTSUpdate = (value: boolean) => {
        if (alert.isNotTwitchSafe !== value) {
            onUpdate && onUpdate({ id: alert.id, isNotTwitchSafe: value });
        }
    }

    const onHasCopyrightUpdate = (value: boolean) => {
        if (alert.hasCopyright !== value) {
            onUpdate && onUpdate({ id: alert.id, hasCopyright: value });
        }
    }

    const onisOnTopUpdate = (value: boolean) => {
        if (alert.isOnTop !== value) {
            onUpdate && onUpdate({ id: alert.id, isOnTop: value });
        }
    }

    const onEtmaRatingUpdate = (value: EEtmaRating) => {
        if (value >= 0 && alert.etmaRating !== value) {
            onUpdate && onUpdate({ id: alert.id, etmaRating: value });
        }
    }

    const onApproveAlert = () => {
        if (alert.etmaRating !== undefined && alert.etmaRating !== null) {
            onApprove && onApprove(alert.id);
        }
    }

    const onDeclineAlert = () => {
        showModal({
            content: (
                <RejectStickerPopup
                    onDeclineClick={onDeclineAlertCallback}
                />
            ),
            options: {}
        });
    }

    const onDeclineAlertCallback = (reason: string) => {
        onDecline && onDecline(alert.id, reason);
    }

    const defaultStreamerValue = alert.streamerId ? {id: alert.streamerId, avatarUrl: alert.streamerAvatar, name: alert.streamerName} : undefined;

    const defaultGameValue = alert.gameId ? {id: alert.gameId, coverThumbId: alert.gameCoverThumbId, name: alert.gameName} : undefined;

    return (
        <Row className={s.alertOnModeration}>
            <Column className={s.alertContainer}>
                <div className={s.alertContainer}>
                    <Alert
                        alert={alert}
                        hideName
                        muteSound={soundMuted}
                        playable={!!alert.stickerUrl}
                        unmuteOnHover={true}
                        sizeCalculationBase={AlertSizeCalculationBase.WIDTH}
                        hideModerationStatus={true}
                        onSoundClick={onSoundClick}
                        onClick={onClick}
                    />
                </div>
                <div className={s.adultProb}>Adult prob: {alert.isAdultProb ? (alert.isAdultProb * 100).toFixed(1) + '%': '0.0%'}</div>
            </Column>
            <Column className={s.alertForm}>
                <Row className={s.authorAndDate}>
                    {formatAuthor(alert)}
                    <Label className={s.date} caption={formatCreationTimestamp(alert.createdAt)} size={LabelSize.SMALL} />
                </Row>
                <Row>
                    <Input debounceTime={500} defaultValue={alert.name} fullWidth onChange={(value) => onNameUpdate(value)} />
                </Row>
                <Row>
                    <TextArea debounceTime={500} maxRows={3} placeholder={'Описание, ключевые слова'} defaultValue={alert.description} onChange={(value) => onDescriptionUpdate(value)} />
                </Row>
                <Row>
                    <TextArea debounceTime={500} maxRows={3} placeholder={'Распознанный голос'} defaultValue={alert.speech2text} onChange={(value) => onSpeech2TextUpdate(value)} />
                </Row>
                <Row>
                    <TextArea debounceTime={500} maxRows={3} placeholder={'Распознанный текст'} defaultValue={alert.ocrText} onChange={(value) => onOcrTextUpdate(value)} />
                </Row>
                <Row>
                    <TextArea debounceTime={500} maxRows={1} placeholder={'Распознанная музыка'} defaultValue={alert.music} onChange={(value) => onMusicUpdate(value)} />
                </Row>
                <Row>
                    <StreamerSelect 
                        debounceTime={500}
                        fullWidth
                        placeholder={'Никнейм стримера (если стикер о нём)'}
                        defaultValue={defaultStreamerValue}
                        onSelect={(streamer) => onStreamerUpdate(streamer)} />
                </Row>
                <Row>
                    <GameSelect
                        fullWidth
                        placeholder={'Игра (если стикер о ней)'}
                        debounceTime={500}
                        maxLength={100}
                        defaultValue={defaultGameValue}
                        onSelect={(game) => onGameUpdate(game)}
                    />
                </Row>
                <Row>
                    <TagSelect
                        fullWidth
                        placeholder={'Добавить тег'}
                        debounceTime={500}
                        maxLength={20}
                        defaultValue={alert.tags}
                        onTagsChanged={onTagsUpdate}
                    />
                </Row>
                {/* <Row>
                    <CategoriesPanel activeCategories={alert.categories} onCategoryClick={onCategoryClick} />
                </Row> */}
                <Row>
                    <div className={s.etma}>
                        <div className={cn(s.etmaButton, {[s.selectedE]: alert.etmaRating === EEtmaRating.E})} onClick={() => onEtmaRatingUpdate(EEtmaRating.E)}>
                            <IconEtmaE/>
                        </div>
                        <div className={cn(s.etmaButton, {[s.selectedT]: alert.etmaRating === EEtmaRating.T})} onClick={() => onEtmaRatingUpdate(EEtmaRating.T)}>
                            <IconEtmaT/>
                        </div>
                        <div className={cn(s.etmaButton, {[s.selectedM]: alert.etmaRating === EEtmaRating.M})} onClick={() => onEtmaRatingUpdate(EEtmaRating.M)}>
                            <IconEtmaM/>
                        </div>
                        <div className={cn(s.etmaButton, {[s.selectedA]: alert.etmaRating === EEtmaRating.A})} onClick={() => onEtmaRatingUpdate(EEtmaRating.A)}>
                            <IconEtmaA/>
                        </div>
                    </div>
                </Row>
                <Row className={s.buttons}>
                    <ColoredButton
                        caption={<IconThumbDownLine/>}
                        className={s.button}
                        color={ColoredButtonColor.RED}
                        onClick={() => onDeclineAlert()}
                    />
                    <div className={s.expander}/>
                    <Switch
                        className={s.switch}
                        checked={alert.isOnTop}
                        color={SwitchColor.GREEN}
                        caption={'Top'}
                        onChange={(value) => onisOnTopUpdate(value)}
                    />
                    {/* <Switch
                        className={s.switch}
                        checked={alert.isAdult}
                        color={SwitchColor.RED}
                        caption={'NSFW'}
                        onChange={(value) => onNsfwUpdate(value)}
                    /> */}
                    <Switch
                        className={s.switch}
                        checked={alert.isNotTwitchSafe}
                        color={SwitchColor.PURPLE}
                        caption={'Twitch Unsafe'}
                        onChange={(value) => onisNotTSUpdate(value)}
                    />
                    <Switch
                        className={s.switch}
                        checked={alert.hasCopyright}
                        color={SwitchColor.BLACK}
                        caption={'Copyright'}
                        onChange={(value) => onHasCopyrightUpdate(value)}
                    />
                    <div className={s.expander}/>
                    {/* <ColoredButton
                        caption={<IconSaveLine/>}
                        className={s.button}
                        color={ColoredButtonColor.ORANGE}
                        disabled={!updateEnabled}
                        onClick={() => onUpdate && onUpdate({
                            id: alert.id,
                            name: stickerName,
                            description: alertDescription,
                            isAdult: alertNsfw,
                            isNotTwitchSafe: alertIsNotTwitchSafe,
                            isOnTop: alertIsOnTop,
                        })}
                    /> */}
                    <ColoredButton
                        caption={<IconThumbUpLine/>}
                        className={s.button}
                        color={ColoredButtonColor.GREEN}
                        disabled={(alert.etmaRating === undefined || alert.etmaRating === null)}
                        onClick={() => onApproveAlert()}
                    />
                </Row>
            </Column>
        </Row>
    );
};
