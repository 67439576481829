import React from 'react';
import cn from 'classnames';
import s from './Hint.scss';

interface HintProps {
    fullWidth?: boolean;
    className?: string;
}

export const Hint = (props: React.PropsWithChildren<HintProps>) => {
    const {
        children,
        fullWidth,
        className,
    } = props;

    return (
        <div className={cn(
            s.hint,
            { [s.hintFullWidth]: fullWidth },
            className
        )}>
            {children}
        </div>
    )
};
