// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NotificationArea__notificationArea--AJY2f {\n  position: absolute;\n  top: 0;\n  right: 4px;\n  display: flex;\n  flex-direction: column;\n  row-gap: 5px;\n  padding: 75px 25px 25px 25px;\n  overflow: hidden;\n  pointer-events: none;\n}", "",{"version":3,"sources":["webpack://./src/contexts/notification/NotificationArea.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,MAAA;EACA,UAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;EACA,4BAAA;EACA,gBAAA;EACA,oBAAA;AACJ","sourcesContent":[".notificationArea {\n    position: absolute;\n    top: 0;\n    right: 4px;\n    display: flex;\n    flex-direction: column;\n    row-gap: 5px;\n    padding: 75px 25px 25px 25px;\n    overflow: hidden;\n    pointer-events: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notificationArea": "NotificationArea__notificationArea--AJY2f"
};
export default ___CSS_LOADER_EXPORT___;
