import { useApi } from '@hooks/webapi'

export const useTopStreamers = () => {
    const {
        user: {
            useGetTopStreamersQuery,
        }
    } = useApi();

    const {
        data: stats,
        isFetching: isTopStreamersLoading,
    } = useGetTopStreamersQuery();

    return {
        stats,
        isTopStreamersLoading,
    }
}