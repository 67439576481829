import { Button, Input, Loader, Panel, RoundedAvatar, RoundedAvatarSize } from '@components';
import { useStreamerAlertsStats } from '@hooks';
import React from 'react';
import s from './StreamerAlertsStats.scss';
import { StatisticRecord } from './components';

export const StreamerAlertsStats = () => {
    const {
        streamerName,
        lastData,
        currentStats,
        isLoadingPage,
        isFetchingPage,
        isEndReached,
        loadNextPage,
        setStreamerName,
    } = useStreamerAlertsStats();

    const rows = currentStats?.map((r, idx) => (
        <StatisticRecord data={lastData} item={r} key={idx}/>
    ));

    return (
        <Panel className={s.statsContainer}>
            <div className={s.streamerName}>
                <Input placeholder='Streamer name' className={s.streamerNameInput} debounceTime={1000} onChange={(value) => setStreamerName(value?.toString())}/>
                {lastData?.streamer && <RoundedAvatar source={lastData?.streamer?.channel?.avatarUrl} size={RoundedAvatarSize.MEDIUM}/>}
                {(!isLoadingPage && !isFetchingPage && streamerName && !lastData?.streamer) && <div className={s.notFound}>Not Found</div>}
            </div>
            {currentStats.length > 0 && 
                <table>
                    <thead>
                        <tr>
                            <td>
                                { 'Name' }
                            </td>
                            <td>
                                { 'StreamerK' }
                            </td>
                            <td>
                                { 'GlobalK' }
                            </td>
                            <td>
                                { 'TimeK' }
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        { rows }
                    </tbody>
                </table>
            }

            {!isEndReached && currentStats.length > 0 && !isLoadingPage && !isFetchingPage &&
                <Button
                    caption={'Загрузить еще'}
                    className={s.loadMoreButton}
                    onClick={loadNextPage}
                />
            }
            {(isLoadingPage || isFetchingPage) && 
                <div className={s.loader}><Loader/></div>
            }
        </Panel>
    );
};