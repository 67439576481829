import { MemesIndexSearchParams, MemesIndexSearchResult } from '@interfaces';
import { memeAlertsBaseApi } from './meme-alerts-base.api';

export const stickersMemesIndexApi = memeAlertsBaseApi.injectEndpoints({
    endpoints: (build) => ({
        searchSimilarStickers: build.query<MemesIndexSearchResult, MemesIndexSearchParams>({
            query: (params: MemesIndexSearchParams) => ({
                url: 'sticker/index/search',
                method: 'POST',
                body: params,
            }),
        }),
    }),

});
