import { CircleButton, CircleButtonColor, Icon, IconSize, Picture, Video } from '@components';
import { useElementOnScreen } from '@hooks';
import { IconCogLine, IconHeartAltSolid, IconMagnifierLine, IconPlusAltLine, IconSoundActiveLine, IconSoundEnableLine, IconSoundMuteLine, IconTrashSolid } from '@images/svg';
import { StickerDto, defaultDimensions, Dimensions } from '@interfaces';
import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import s from './Alert.scss';

export enum AlertSizeCalculationBase {
    NONE,
    WIDTH,
    HEIGHT,
}

export enum AlertButton {
    ADD_TO_FAVORITES,
    ADD_TO_CHANNEL,
    DELETE,
    CUSTOM_SETTINGS,
}

interface AlertProps {
    alert: StickerDto;
    buttons?: Array<AlertButton>;
    playable?: boolean;
    forceShowButtons?: boolean;
    muteSound?: boolean;
    unmuteOnHover?: boolean;
    hideName?: boolean;
    hideSoundIcon?: boolean;
    hideSimilarIcon?: boolean;
    useLoadedAnimation?: boolean;
    useStroke?: boolean;
    loop?: boolean;
    volume?: number;
    useFallbackVideo?: boolean;
    hideModerationStatus?: boolean;
    isMobileLayoutRecommended?: boolean;
    useCompactOverlay?: boolean;
    sizeCalculationBase?: AlertSizeCalculationBase;
    onClick?: (alert: StickerDto) => void;
    onDeleteClick?: (alert: StickerDto) => void;
    onLikeClick?: (alert: StickerDto) => void;
    onPlusClick?: (alert: StickerDto) => void;
    onSoundClick?: () => void;
    onSimilarClick?: () => void;
    onCustomSettingsClick?: (alert: StickerDto) => void;
}

export const Alert = (props: AlertProps) => {
    const {
        alert,
        buttons = [],
        forceShowButtons,
        muteSound,
        unmuteOnHover,
        hideName,
        hideSoundIcon,
        hideSimilarIcon = true,
        useLoadedAnimation = true,
        useStroke = true,
        playable,
        loop,
        volume,
        useFallbackVideo,
        hideModerationStatus,
        isMobileLayoutRecommended,
        useCompactOverlay = false,
        sizeCalculationBase = AlertSizeCalculationBase.NONE,
        onClick,
        onDeleteClick,
        onLikeClick,
        onPlusClick,
        onSoundClick,
        onSimilarClick,
        onCustomSettingsClick,
    } = props;

    const customSettings = alert.customSettings;

    const { elementRef: alertRef, isOnScreen: isAlertOnScreen } = useElementOnScreen<HTMLDivElement>({ threshold: 0, throttleTimeMs: 100 });

    const [isHovered, setIsHovered] = useState(false);

    const [ready, setReady] = useState(false);
    const [videoDims, setVideoDims] = useState<Dimensions>(defaultDimensions);

    const onReadyStateChanged = (ready: boolean) => {
        setReady(ready);
    }

    const overlay = !isMobileLayoutRecommended ? (
        <div className={cn(s.alertOverlay, { [s.alertOverlayMobileLayout]: isMobileLayoutRecommended })}>
            <div className={s.alertOverlayTopBlock}/>
            <div className={s.alertOverlayBottomBlock}>
                { !hideName &&
                    <div className={s.alertOverlayBottomBlockName}>
                        {alert.name}
                    </div>
                }
                <div className={s.alertOverlayBottomBlockButtons}>
                    { buttons.includes(AlertButton.CUSTOM_SETTINGS) &&
                        <CircleButton
                            icon={<IconCogLine/>}
                            isActive={false}
                            color={CircleButtonColor.WHITE}
                            onClick={() => onCustomSettingsClick && onCustomSettingsClick(alert)}
                        />
                    }
                    <div className={s.expander}></div>
                    { buttons.includes(AlertButton.DELETE) &&
                        <CircleButton
                            icon={<IconTrashSolid/>}
                            isActive={false}
                            color={CircleButtonColor.WHITE}
                            onClick={() => onDeleteClick && onDeleteClick(alert)}
                        />
                    }
                    { buttons.includes(AlertButton.ADD_TO_FAVORITES) &&
                        <CircleButton
                            icon={<IconHeartAltSolid/>}
                            isActive={alert.isFavorite}
                            color={CircleButtonColor.RED}
                            onClick={() => onLikeClick && onLikeClick(alert)}
                        />
                    }
                    { buttons.includes(AlertButton.ADD_TO_CHANNEL) &&
                        <CircleButton
                            icon={<IconPlusAltLine/>}
                            isActive={alert.isAddedToChannel}
                            color={CircleButtonColor.GREEN}
                            onClick={() => onPlusClick && onPlusClick(alert)}
                        />
                    }
                </div>
            </div>
        </div>
    ) : undefined;

    const soundIcon = (
        <Icon
            className={s.alertSoundIcon}
            icon={muteSound
                ? <IconSoundMuteLine/>
                : isHovered
                    ? <IconSoundActiveLine/>
                    : <IconSoundEnableLine/>
            }
            iconSize={IconSize.SMALL}
            onClick={onSoundClick}
        />
    );

    const similarIcon = (
        <Icon
            className={s.alertSimilarIcon}
            icon={<IconMagnifierLine/>}
            iconSize={IconSize.SMALL}
            onClick={onSimilarClick}
        />
    );

    const alertView = !playable
        ? <Picture
            source={alert.stickerPreview}
            containerClassName={s.alertContainerOverrides}
            imgClassName={useStroke ? cn (s.alertImageOverrides, s.alertImageStroke) : s.alertImageOverrides}
            overlay={overlay}
            forceShowOverlay={forceShowButtons}
            disabled={customSettings?.disableSticker}
            onClick={onClick ? () => onClick(alert) : undefined}
        >
            { !hideSoundIcon && soundIcon }
        </Picture>
        : <Video
            source={
                isAlertOnScreen
                    ? useFallbackVideo
                        ? alert.fallbackUrl
                        : alert.stickerUrl
                    : undefined
            }
            containerClassName={cn(s.alertContainerOverrides, {[s.alertNotApproved]: alert.moderationState.toLowerCase() !== 'approved'})}
            videoClassName={useStroke ? cn (s.alertImageOverrides, s.alertImageStroke) : s.alertImageOverrides}
            forceShowOverlay={forceShowButtons}
            forceMute={muteSound}
            unmuteOnHover={unmuteOnHover}
            loop={loop}
            disabled={customSettings?.disableSticker}
            volume={customSettings?.customVolumeEnabled && customSettings?.customVolume !== undefined
                ? customSettings?.customVolume
                : volume
            }
            overlay={overlay}
            onClick={() => onClick && onClick(alert)}
            onHover={() => setIsHovered(true)}
            onOut={() => setIsHovered(false)}
            onReadyStateChange={onReadyStateChanged}
        >
            { !hideSoundIcon && soundIcon }
            { !hideSimilarIcon && similarIcon }
        </Video>;

    useEffect(() => {
        switch (sizeCalculationBase) {
            case AlertSizeCalculationBase.WIDTH:
                if (alertRef.current?.clientWidth && alert.videoData) {
                    setVideoDims({ width: alertRef.current.clientWidth, height: alertRef.current.clientWidth / alert.videoData.aspectRatio });
                }
                break;
            case AlertSizeCalculationBase.HEIGHT:
                if (alertRef.current?.clientHeight && alert.videoData) {
                    setVideoDims({ width: alertRef.current.clientHeight * alert.videoData.aspectRatio, height: alertRef.current.clientHeight });
                }
                break;
        }
    }, []);

    return (
        <div
            ref={alertRef}
            className={cn(
                s.alert,
                {
                    [s.alertLoading]: !ready,
                    [s.alertLoaded]: ready && useLoadedAnimation,
                    [s.alertCompactOverlay]: useCompactOverlay,
                },
            )}
            style={videoDims.width ? videoDims : { minHeight: '140px' }}
        >
            { isAlertOnScreen &&
                <>
                    { alertView }
                    { alert.moderationState.toLowerCase() === 'moderation' && !isHovered && !hideModerationStatus &&
                        <div className={s.alertModeration}>
                            {'Модерируется'}
                        </div>
                    }
                    { alert.moderationState.toLowerCase() === 'declined' && !isHovered && !hideModerationStatus &&
                        <div className={s.alertDeclined}>
                            {'Отклонен'}
                        </div>
                    }
                </>
            }
        </div>
    )
}