import { StreamerAlertsStatsData, StreamerAlertsStatsDataItem } from '@interfaces';
import React from 'react';

interface StatisticRecordProps {
    data?: StreamerAlertsStatsData;
    item: StreamerAlertsStatsDataItem;
}

export const StatisticRecord = (props: StatisticRecordProps) => {
    const {
        data,
        item,
    } = props;

    const sk = data?.minMax.maxStreamerUsages === 0 || item.streamerSends === 0 || !data ? '0.00' : (item.streamerSends / data.minMax.maxStreamerUsages).toFixed(2);
    const gk = data?.minMax.maxUsages === 0 || item.totalSends === 0 || !data ? '0.00' : (item.totalSends / data.minMax.maxUsages).toFixed(2);
    const tk = data?.minMax.minCreationDate === 0 || item.daysAfterCreation === 0 || !data ? '0.00' : (item.daysAfterCreation / data.minMax.minCreationDate).toFixed(2);

    return (
        <tr>
            <td>
                { item.name }
            </td>
            <td>
                { sk }
            </td>
            <td>
                { gk }
            </td>
            <td>
                { tk }
            </td>
        </tr>
    );
};
