// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Row__row--zgKS1 {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-start;\n  column-gap: 20px;\n  width: 100%;\n}\n.Row__rowFullHeight--ptOeX {\n  flex: 1;\n}\n\n.Row__noGap--MHwqr {\n  column-gap: 0;\n}", "",{"version":3,"sources":["webpack://./src/components/containers/Row/Row.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,2BAAA;EACA,gBAAA;EACA,WAAA;AACJ;AACI;EACI,OAAA;AACR;;AAGA;EACI,aAAA;AAAJ","sourcesContent":[".row {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: flex-start;\n    column-gap: 20px;\n    width: 100%;\n\n    &FullHeight {\n        flex: 1;\n    }\n}\n\n.noGap {\n    column-gap: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "Row__row--zgKS1",
	"rowFullHeight": "Row__rowFullHeight--ptOeX",
	"noGap": "Row__noGap--MHwqr"
};
export default ___CSS_LOADER_EXPORT___;
