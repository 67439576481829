import React, { useState } from 'react'
import cn from 'classnames';
import s from './SideMenu.scss';
import { IconAngleDoubleLeftLine, IconAngleDoubleRightLine } from '@common/images/svg';
import { SideMenuItem } from '../SideMenuItem';

interface SideMenuItem {
    caption: string;
    to?: string;
    enabled?: boolean;
    onClick?: () => void;
}

interface SideMenuProps {
    elements: SideMenuItem[];
}

export const SideMenu = (props: React.PropsWithChildren<SideMenuProps>) => {
    const {
        children,
        elements = [],
    } = props;

    const [selected, setSelected] = useState<number>(0);
    const [isCollapsed, setIsCollapsed] = useState(false);

    const onClickItem = (item: SideMenuItem, key: number) => {
        setSelected(key);
        item.onClick && item.onClick();
    }

    return (
        <div className={cn(
            s.sideMenu,
            {
                [s.sideMenuCollapsed]: isCollapsed,
            }
        )}>
            {
                elements.filter(e => e.enabled).map((e, i) =>
                    <SideMenuItem
                        {...e}
                        key={i}
                        selected={i === selected}
                        collapsed={isCollapsed}
                        onClick={() => onClickItem(e, i)}
                    />
                )
            }
            { children }
            <div className={s.sideMenuCollapseButton} onClick={() => setIsCollapsed(prev => !prev)}>
                <div className={s.sideMenuCollapseButtonIcon}>
                    { isCollapsed
                        ? <IconAngleDoubleRightLine />
                        : <IconAngleDoubleLeftLine />
                    }
                </div>
            </div>
        </div>
    )
}