import { StreamerStickersStatsData, StreamerStickersStatsDataItem } from '@interfaces';
import React from 'react';

interface StatisticRecordProps {
    data?: StreamerStickersStatsData;
    item: StreamerStickersStatsDataItem;
}

export const StatisticRecord = (props: StatisticRecordProps) => {
    const {
        data,
        item,
    } = props;

    return (
        <tr>
            <td>
                { item.name }
            </td>
            <td>
                { item.totalSendings }
            </td>
            <td>
                { item.totalAverage.toFixed(3) }
            </td>
            <td>
                { item.totalRms.toFixed(3) }
            </td>
            <td>
                { item.totalZScore.toFixed(3) }
            </td>
            <td>
                { item.streamerSendings }
            </td>
            <td>
                { item.streamerAverage.toFixed(3) }
            </td>
            <td>
                { item.streamerRms.toFixed(3) }
            </td>
            <td>
                { item.streamerZScore.toFixed(3) }
            </td>
            <td>
                { item.rank.toFixed(3) }
            </td>
        </tr>
    );
};
