import { StatisticData } from '@interfaces';
import { api } from '@store';
import { useEffect, useState } from 'react';

export enum StatsMode {
    DAILY,
    MONTHLY,
}

export const useStats = () => {
    const {
        stats: {
            useLazyLoadDailyStatsPageQuery,
            useGetCurrentDayStatsQuery,
            useLazyLoadMonthlyStatsPageQuery,
            useGetCurrentMonthStatsQuery,
        }
    } = api;

    const [mode, setMode] = useState(StatsMode.DAILY);
    const [dailyPage, setDailyPage] = useState(1);
    const [monthlyPage, setMonthlyPage] = useState(1);
    const [isDailyEndReached, setIsDailyEndReached] = useState(false);
    const [isMonthlyEndReached, setIsMonthlyEndReached] = useState(false);
    const [dailyStatistic, setDailyStatistic] = useState<StatisticData[]>([]);
    const [monthlyStatistic, setMonthlyStatistic] = useState<StatisticData[]>([]);

    const {
        data: currentDayStats,
    } = useGetCurrentDayStatsQuery();

    const {
        data: currentMonthStats,
    } = useGetCurrentMonthStatsQuery();

    const [
        loadDailyPage,
        {
            data: loadedDailyPage,
            isLoading: isDailyStatisticLoading,
        },
    ] = useLazyLoadDailyStatsPageQuery();

    const [
        loadMonthlyPage,
        {
            data: loadedMonthlyPage,
            isLoading: isMonthlyStatisticLoading,
        },
    ] = useLazyLoadMonthlyStatsPageQuery();

    const loadNextDailyPage = () => {
        setDailyPage(dailyPage + 1);
    }

    const loadNextMonthlyPage = () => {
        setMonthlyPage(monthlyPage + 1);
    }

    useEffect(() => {
        loadDailyPage(dailyPage);
        loadMonthlyPage(monthlyPage);
    }, []);

    useEffect(() => {
        loadDailyPage(dailyPage);
    }, [dailyPage]);

    useEffect(() => {
        loadMonthlyPage(monthlyPage);
    }, [monthlyPage]);

    useEffect(() => {
        if (loadedDailyPage && !loadedDailyPage.length) {
            setIsDailyEndReached(true);
        }
        setDailyStatistic((prev) => [...prev].concat(loadedDailyPage ?? []));
    }, [loadedDailyPage]);

    useEffect(() => {
        if (loadedMonthlyPage && !loadedMonthlyPage.length) {
            setIsMonthlyEndReached(true);
        }
        setMonthlyStatistic((prev) => [...prev].concat(loadedMonthlyPage ?? []));
    }, [loadedMonthlyPage]);

    return {
        mode,
        currentDayStats,
        currentMonthStats,
        dailyStatistic,
        monthlyStatistic,
        isDailyStatisticLoading,
        isDailyEndReached,
        isMonthlyStatisticLoading,
        isMonthlyEndReached,
        setMode,
        loadNextDailyPage,
        loadNextMonthlyPage,
    };
};
