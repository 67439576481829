import { MemeAlertsEvent } from '@hooks';
import { StatisticData, RegisterSendAlertPopupOpenEventDto, RegisterStreamerPageOpenEventDto, EventsLoadDto, SendedAlertStatisticData, SearchStatisticData, SearchQueryStatisticData, SendAlertsStatisticData, AlertSendIntervalLoadDto, CohortStatisticData, StreamerAlertsStatsData, StreamerStickersStatsData, StreamerStickersStatsLoadParams, ViewerEventsData, ViewerEventsLoadParams, StreamerEventsMoneyLoadParams, StreamerEventsMoneyData, StreamerEventsStickersData, StreamerEventsStickersLoadParams } from '@interfaces';
import { memeAlertsBaseApi } from './meme-alerts-base.api';

export const memeAlertsStatsApi = memeAlertsBaseApi.injectEndpoints({
    endpoints: (build) => ({
        registerSendAlertPopupOpenEvent: build.mutation<void, RegisterSendAlertPopupOpenEventDto>({
            query: (eventData: RegisterSendAlertPopupOpenEventDto) => ({
                url: 'event/send-sticker-popup-open',
                method: 'POST',
                body: eventData,
            }),
        }),
        registerStreamerPageOpenEvent: build.mutation<void, RegisterStreamerPageOpenEventDto>({
            query: (eventData: RegisterStreamerPageOpenEventDto) => ({
                url: 'event/send-streamer-page-open',
                method: 'POST',
                body: eventData,
            }),
        }),
        loadEvents: build.query<MemeAlertsEvent, EventsLoadDto>({
            query: (params: EventsLoadDto) => ({
                url: 'event',
                method: 'POST',
                body: params,
            }),
        }),
        hasUnreadEvents: build.query<boolean, void>({
            query: () => ({
                url: 'event/read/state',
                method: 'POST',
            }),
        }),
        markAsRead: build.query<boolean, void>({
            query: () => ({
                url: 'event/read',
                method: 'POST',
            }),
        }),
        getViewerEventsPage: build.query<ViewerEventsData, ViewerEventsLoadParams>({
            query: (data: ViewerEventsLoadParams) => ({
                url: `event/viewer/${data.page}`,
                method: 'POST',
                body: data,
            }),
        }),
        getStreamerEventsMoneyPage: build.query<StreamerEventsMoneyData, StreamerEventsMoneyLoadParams>({
            query: (data: StreamerEventsMoneyLoadParams) => ({
                url: `event/streamer/money/${data.page}`,
                method: 'POST',
                body: data,
            }),
        }),
        getStreamerEventsStickersPage: build.query<StreamerEventsStickersData, StreamerEventsStickersLoadParams>({
            query: (data: StreamerEventsStickersLoadParams) => ({
                url: `event/streamer/stickers/${data.page}`,
                method: 'POST',
                body: data,
            }),
        }),
        loadDailyStatsPage: build.query<StatisticData[], number>({
            query: (page: number) => ({
                url: `stats/daily/${page}`,
                method: 'GET'
            }),
            providesTags: ['DailyStatsPages'],
        }),
        getCurrentDayStats: build.query<StatisticData, void>({
            query: () => ({
                url: `stats/daily/current`,
                method: 'GET'
            }),
            providesTags: ['CurrentDailyStats'],
        }),
        loadMonthlyStatsPage: build.query<StatisticData[], number>({
            query: (page: number) => ({
                url: `stats/monthly/${page}`,
                method: 'GET'
            }),
            providesTags: ['MonthlyStatsPages'],
        }),
        getCurrentMonthStats: build.query<StatisticData, void>({
            query: () => ({
                url: `stats/monthly/current`,
                method: 'GET'
            }),
            providesTags: ['CurrentMonthlyStats'],
        }),
        loadDailySentAlertsStatsPage: build.query<SendedAlertStatisticData[], number>({
            query: (page: number) => ({
                url: `stats/sended-sticker-daily/${page}`,
                method: 'GET'
            }),
            providesTags: ['SentAlertsStatsPages'],
        }),
        getCurrentDaySentAlertsStats: build.query<SendedAlertStatisticData, void>({
            query: () => ({
                url: `stats/sended-sticker-daily/current`,
                method: 'GET'
            }),
            providesTags: ['CurrentSentAlertsStats'],
        }),
        loadDailySearchStatsPage: build.query<SearchStatisticData[], number>({
            query: (page: number) => ({
                url: `stats/search-daily/${page}`,
                method: 'GET'
            }),
            providesTags: ['SearchStatsPages'],
        }),
        getCurrentDaySearchStats: build.query<SearchStatisticData, void>({
            query: () => ({
                url: `stats/search-daily/current`,
                method: 'GET'
            }),
            providesTags: ['CurrentSearchStats'],
        }),
        getCurrentSearchQueriesStats: build.query<SearchQueryStatisticData[], number>({
            query: (page: number) => ({
                url: `stats/search-queries/${page}`,
                method: 'GET'
            }),
            providesTags: ['CurrentSearchQueriesStats'],
        }),
        getCurrentSendAlertsStats: build.query<SendAlertsStatisticData[], AlertSendIntervalLoadDto>({
            query: (data: AlertSendIntervalLoadDto) => ({
                url: `stats/send-stickers/${data.page}/${data.days}`,
                method: 'GET'
            }),
            providesTags: ['CurrentSendAlertsStats'],
        }),
        getCohortStatsPage: build.query<CohortStatisticData[], number>({
            query: (page: number) => ({
                url: `stats/cohort/${page}`,
                method: 'GET'
            }),
            providesTags: ['CohortStats'],
        }),
        getStreamerAlertsStatsPage: build.query<StreamerAlertsStatsData, {page: number; streamerName: string;}>({
            query: (data: {page: number; streamerName: string;}) => ({
                url: `stats/streamer-stickers/${data.streamerName}/${data.page}`,
                method: 'GET'
            }),
            providesTags: ['StreamerAlertsStats'],
        }),
        getStreamerStickersStatsPage: build.query<StreamerStickersStatsData, StreamerStickersStatsLoadParams>({
            query: (data: StreamerStickersStatsLoadParams) => ({
                url: `stats/streamer-stickers/${data.streamerName}/${data.page}`,
                method: 'POST',
                body: data,
            }),
            providesTags: ['StreamerStickersStats'],
        }),
    }),
});
