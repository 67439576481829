import {
    AlertCreationDto,
    StickerDto,
    AlertInCategoryDto,
    AlertsModerationStats,
    AlertUpdateDto,
    ITag,
    MemePartySessionDto,
    MemePartySessionFetchParams,
    Position,
    SendStickerDto,
    SendMemeCannonStickerDto,
    ThematicSet,
    UpdateAlertCustomSettingsDto,
    IGame,
    MassStickersUpdateDto
} from '@interfaces';
import { memeAlertsBaseApi } from './meme-alerts-base.api';
import { MemeAlertsEvent } from '@hooks';

export const memeAlertsAlertApi = memeAlertsBaseApi.injectEndpoints({
    endpoints: (build) => ({
        createSticker: build.mutation<StickerDto, AlertCreationDto>({
            query: (alertData: AlertCreationDto) => ({
                url: 'sticker',
                method: 'POST',
                body: alertData,
            }),
        }),
        updateSticker: build.mutation<StickerDto, AlertUpdateDto>({
            query: (alertData: AlertUpdateDto) => ({
                url: 'sticker',
                method: 'PUT',
                body: alertData,
            }),
            invalidatesTags: ['ModerationStats'],
        }),
        silentUpdateSticker: build.query<StickerDto, AlertUpdateDto>({
            query: (alertData: AlertUpdateDto) => ({
                url: 'sticker',
                method: 'PUT',
                body: alertData,
            }),
        }),
        massUpdateStickers: build.mutation<number, MassStickersUpdateDto>({
            query: (updateData: MassStickersUpdateDto) => ({
                url: 'sticker/mass',
                method: 'POST',
                body: updateData,
            }),
            invalidatesTags: ['ModerationStats'],
        }),
        addAlertToFavorites: build.mutation<StickerDto, string>({
            query: (stickerId: string) => ({
                url: `sticker/${stickerId}/favorite`,
                method: 'POST',
            }),
        }),
        removeAlertFromFavorites: build.mutation<StickerDto, string>({
            query: (stickerId: string) => ({
                url: `sticker/${stickerId}/favorite`,
                method: 'DELETE',
            }),
        }),
        addAlertToChannel: build.mutation<StickerDto, string>({
            query: (stickerId: string) => ({
                url: `sticker/${stickerId}/channel`,
                method: 'POST',
            }),
        }),
        removeAlertFromChannel: build.mutation<StickerDto, string>({
            query: (stickerId: string) => ({
                url: `sticker/${stickerId}/channel`,
                method: 'DELETE',
            }),
        }),
        deleteSticker: build.mutation<StickerDto, string>({
            query: (stickerId: string) => ({
                url: `sticker/${stickerId}`,
                method: 'DELETE',
            }),
        }),
        sendSticker: build.mutation<boolean, SendStickerDto>({
            query: (sendStickerDto: SendStickerDto) => ({
                url: `sticker/send`,
                method: 'POST',
                body: sendStickerDto,
            }),
        }),
        sendMemeCannonSticker: build.mutation<boolean, SendMemeCannonStickerDto>({
            query: (sendMemeCannonStickerDto: SendMemeCannonStickerDto) => ({
                url: `sticker/send-meme-cannon`,
                method: 'POST',
                body: sendMemeCannonStickerDto,
            }),
        }),
        sendRandomAlert: build.mutation<void, boolean>({
            query: (fullscreen?: boolean) => ({
                url: `sticker/send-random`,
                method: 'POST',
                body: { fullscreen }
            }),
        }),
        getModerationStats: build.query<AlertsModerationStats, string | undefined>({
            query: (filter) => ({
                url: `sticker/moderation-stats` + (filter ? `?q=${filter}` : ''),
                method: 'GET',
            }),
            providesTags: ['ModerationStats'],
        }),
        //TODO Remove?
        addAlertToCategory: build.mutation<StickerDto, AlertInCategoryDto>({
            query: (alertInCategory: AlertInCategoryDto) => ({
                url: `sticker/${alertInCategory.stickerId}/category/${alertInCategory.categoryId}`,
                method: 'POST',
            }),
        }),
        removeAlertFromCategory: build.mutation<StickerDto, AlertInCategoryDto>({
            query: (alertInCategory: AlertInCategoryDto) => ({
                url: `sticker/${alertInCategory.stickerId}/category/${alertInCategory.categoryId}`,
                method: 'DELETE',
            }),
        }),
        updateAlertCustomSettings: build.mutation<StickerDto, UpdateAlertCustomSettingsDto>({
            query: (alertCustomSettingsData: UpdateAlertCustomSettingsDto) => ({
                url: `sticker/${alertCustomSettingsData.stickerId}/custom-settings`,
                method: 'POST',
                body: alertCustomSettingsData.alertCustomSettings,
            }),
        }),
        getMemePartyState: build.query<MemePartySessionDto, MemePartySessionFetchParams>({
            query: (params: MemePartySessionFetchParams) => ({
                url: `meme-party`,
                method: 'POST',
                body: params,
            }),
        }),
        resetMemePartySession: build.mutation<MemePartySessionDto, void>({
            query: () => ({
                url: `meme-party/reset`,
                method: 'GET',
            }),
        }),
        updateMemePartyPosition: build.mutation<void, Position>({
            query: (position: Position) => ({
                url: `meme-party/update-position`,
                method: 'PUT',
                body: position,
            }),
        }),
        replayAlert: build.query<boolean, string>({
            query: (sendAlertEventId: string) => ({
                url: `sticker/replay`,
                method: 'POST',
                body: {
                    sendAlertEventId,
                },
            }),
        }),
        toggleAlertDisableState: build.query<MemeAlertsEvent, string>({
            query: (eventId: string) => ({
                url: `sticker/toggle-disable-state`,
                method: 'POST',
                body: {
                    eventId,
                },
            }),
        }),
        findTag: build.query<ITag[], string>({
            query: (tag: string) => ({
                url: `tag/find/${tag}`,
                method: 'POST',
            }),
        }),
        findGame: build.query<IGame[], string>({
            query: (query: string) => ({
                url: `game/find/${query}`,
                method: 'POST',
            }),
        }),
        loadThematicSets: build.query<ThematicSet[], void>({
            query: () => ({
                url: `thematic-set`,
                method: 'GET',
            }),
            providesTags: ['ThematicSets'],
        }),
        loadAvailableThematicSets: build.query<ThematicSet[], void>({
            query: () => ({
                url: `thematic-set/available`,
                method: 'GET',
            }),
            providesTags: ['ThematicSets'],
        }),
        createThematicSet: build.mutation<ThematicSet, FormData>({
            query: (data: FormData) => ({
                url: `thematic-set`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['ThematicSets'],
        }),
        updateThematicSet: build.mutation<ThematicSet, { data: FormData, id: string }>({
            query: (data: { data: FormData, id: string }) => ({
                url: `thematic-set/${data.id}`,
                method: 'PUT',
                body: data.data,
            }),
            invalidatesTags: ['ThematicSets'],
        }),
        removeThematicSet: build.mutation<void, string>({
            query: (id: string) => ({
                url: `thematic-set/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['ThematicSets'],
        }),
    }),
});
