import { Logger, LoggerParams } from '@common/classes';
import { useEffect, useRef } from 'react';

export const useLogger = (params: LoggerParams = {}) => {
    const logger = useRef<Logger>();

    const trace = (message: any, customColor?: string) => {
        logger.current?.trace(message, customColor);
    }

    const debug = (message: any, customColor?: string) => {
        logger.current?.debug(message, customColor);
    }

    const info = (message: any, customColor?: string) => {
        logger.current?.info(message, customColor);
    }

    const warning = (message: any, customColor?: string) => {
        logger.current?.warning(message, customColor);
    }

    const error = (message: any, customColor?: string) => {
        logger.current?.error(message, customColor);
    }

    useEffect(() => {
        logger.current = new Logger(params);
    }, []);

    return {
        trace,
        debug,
        info,
        warning,
        error,
    }
}