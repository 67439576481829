import { StickerDto, IUser } from "@interfaces";

export interface StreamerModerationAreaPageLoadParams {
    streamerId?: string;
    pageSize: number;
    skip?: number;
}

export interface StreamerModerationAreaPageData {
    streamer: IUser,
    data: StickerDto[];
}