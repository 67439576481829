import { useElementOnScreen, useLogger } from '@hooks';
import { StickerDto, Dimensions, defaultDimensions } from '@interfaces';
import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { noop } from '@common';
import s from './Sticker.scss';
import { Image, Video } from './components';

export enum StickerSizeCalculationBase {
    NONE,
    WIDTH,
    HEIGHT,
}

interface StickerProps {
    className?: string;
    loop?: boolean;
    withSound?: boolean;
    soundOnHover?: boolean;
    overlay?: JSX.Element;
    sticker: StickerDto;
    useFadeInOnLoaded?: boolean;
    useSkeletonOnLoading?: boolean;
    useAnimatedPreview?: boolean;
    volume?: number;
    videoRef?: React.MutableRefObject<HTMLVideoElement | null | undefined>;
    useFallbackVideo?: boolean;
    sizeCalculationBase?: StickerSizeCalculationBase;
    onClick?: (sticker: StickerDto) => void;
}

export const Sticker = (props: StickerProps) => {
    const {
        className,
        loop,
        withSound,
        soundOnHover = false,
        overlay,
        sizeCalculationBase = StickerSizeCalculationBase.NONE,
        sticker,
        useFallbackVideo = false,
        useFadeInOnLoaded = false,
        useSkeletonOnLoading = false,
        useAnimatedPreview = false,
        volume,
        videoRef,
        onClick = noop,
    } = props;

    const stickerSourceUrl = useFallbackVideo ? sticker.fallbackUrl : sticker.stickerUrl;
    const stickerDisabled = sticker.customSettings?.disableSticker || sticker.moderationState.toLowerCase() !== 'approved'

    const logger = useLogger({ target: Sticker.name });

    const stickerVideoRef = useRef<HTMLVideoElement>(null);
    const stickerImageRef = useRef<HTMLImageElement>(null);

    const { elementRef: stickerRef, isOnScreen: isStickerOnScreen } = useElementOnScreen<HTMLDivElement>({
        threshold: 0,
        throttleTimeMs: 100,
        callback: (videoIsOnScreen) => {
            if (stickerVideoRef.current) {
                if (videoIsOnScreen && stickerSourceUrl) {
                    stickerVideoRef.current.src = stickerSourceUrl;
                    stickerVideoRef.current.load();
                } else {
                    stickerVideoRef.current.src = '';
                    stickerVideoRef.current.load();
                }
            }
            if (stickerImageRef.current) {
                if (videoIsOnScreen && stickerSourceUrl) {
                    stickerImageRef.current.src = stickerSourceUrl;
                } else {
                    stickerImageRef.current.src = '';
                }
            }
        }
    });

    const [ready, setReady] = useState(false);
    const [videoDims, setVideoDims] = useState<Dimensions>(defaultDimensions);
    const [isHovered, setIsHovered] = useState(false);

    const onReadyStateChanged = (ready: boolean) => {
        setReady(ready);
    }

    const stickerView = useAnimatedPreview
        ? <Image
            source={sticker.stickerAnimatedPreview}
            stickerImageRef={stickerImageRef}
            onReadyStateChange={onReadyStateChanged}
        />
        : <Video
            source={isStickerOnScreen ? stickerSourceUrl : ''}
            loop={loop}
            isHovered={isHovered}
            restartOnHover={soundOnHover}
            disabled={stickerDisabled}
            volume={sticker.customSettings?.customVolume || volume}
            withSound={soundOnHover ? withSound && isHovered : withSound}
            videoRef={videoRef}
            stickerVideoRef={stickerVideoRef}
            onReadyStateChange={onReadyStateChanged}
        />;

    useEffect(() => {
        switch (sizeCalculationBase) {
            case StickerSizeCalculationBase.WIDTH:
                if (stickerRef.current?.clientWidth && sticker.videoData) {
                    setVideoDims({ width: stickerRef.current.clientWidth, height: stickerRef.current.clientWidth / sticker.videoData.aspectRatio });
                }
                break;
            case StickerSizeCalculationBase.HEIGHT:
                if (stickerRef.current?.clientHeight && sticker.videoData) {
                    setVideoDims({ width: stickerRef.current.clientHeight * sticker.videoData.aspectRatio, height: stickerRef.current.clientHeight });
                }
                break;
        }
    }, []);

    return (
        <div
            ref={stickerRef}
            className={cn(
                s.sticker,
                className,
                {
                    [s.stickerLoading]: !ready && useSkeletonOnLoading,
                    [s.stickerLoaded]: ready && useFadeInOnLoaded,
                },
            )}
            style={videoDims.width ? videoDims : { minHeight: '140px' }}
            onClick={() => onClick(sticker)}
            onMouseEnter={(e) => setIsHovered(true)}
            onMouseOut={(e) => setIsHovered(false)}
            onMouseMove={(e) => setIsHovered(true)}
        >
            { isStickerOnScreen && stickerView }
            { overlay }
            { ready && !isHovered && sticker.moderationState.toLowerCase() === 'moderation' &&
                <div className={s.moderationPlate}>{'Модерируется'}</div>
            }
            { ready && !isHovered && sticker.moderationState.toLowerCase() === 'declined' &&
                <div className={s.declinedPlate}>{'Отклонен'}</div>
            }
            { ready && !isHovered && sticker.customSettings?.disableSticker &&
                <div className={s.blacklistPlate}>{'ЧС'}</div>
            }
        </div>
    )
}