import { SearchQueryStatisticData } from '@interfaces';
import React from 'react';

import s from './StatisticRecord.scss';
import cn from 'classnames';

interface StatisticRecordProps {
    data: SearchQueryStatisticData;
}

export const StatisticRecord = (props: StatisticRecordProps) => {
    const {
        data,
    } = props;

    return (
        <tr>
            <td className={cn({[s.red]: data.results === 0})}>
                { data._id ?? '' }
            </td>
            <td>
                { data.count ?? 0 }
            </td>
            <td>
                { data.results ?? 0 }
            </td>
        </tr>
    );
};
