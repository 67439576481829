import { ComponentColor, IconButton, Input, InputColor, Loader, Panel, RoundedAvatar, RoundedAvatarSize, Row } from '@components';
import { EStreamerEventsTab, useStreamerEvents } from '@hooks';
import React, { useState } from 'react';
import s from './StreamerEvents.scss';
import cn from 'classnames';
import { MoneyTable } from './components/MoneyTable/MoneyTable';
import { StickersTable } from './components/StickersTable/StickersTable';
import { StreamerModeration } from './components';
import { EEtmaRating } from '@typings';

export const StreamerEvents = () => {
    const {
        streamer,
        streamerName,
        isSuccess,
        isLoading,
        isFetching,
        displayErrors,
        findStreamer,
        setStreamerName,
    } = useStreamerEvents();

    const anyStreamer = streamer as any;

    const [tabType, setTabType] = useState<EStreamerEventsTab>(EStreamerEventsTab.MONEY);

    const onKeyDown = (event: React.KeyboardEvent) => {
        if (event.key == 'Enter') {
            findStreamer();
        }
    };

    return (
        <Panel className={s.statsContainer}>
            <div className={s.streamerName} onKeyDown={onKeyDown}>
                <Input
                    placeholder='Имя стримера'
                    className={s.streamerNameInput}
                    color={InputColor.ORANGE}
                    errorMessage={!streamerName ? 'Введите имя стримера' : 'Стример с таким именем не найден'}
                    hasError={
                        (!isSuccess && displayErrors && !streamer) ||
                        (displayErrors && isSuccess)}
                    onChange={(value) => setStreamerName(value?.toString())}
                />
                {<RoundedAvatar source={streamer?.channel?.avatarUrl} size={RoundedAvatarSize.MEDIUM}/>}
                <IconButton
                    caption={'Найти'}
                    color={ComponentColor.ORANGE}
                    onClick={findStreamer}
                />
            </div>
            {streamer && 
                <>
                    <div className={s.viewerName}>DonationAlerts: {streamer.channel.donationAlertsUsername || 'НЕ УКАЗАНО!'}</div>
                    <div className={s.viewerName}>Отправка стикеров: {streamer.channel.disableStickers ? 'OFF' : 'ON'}</div>
                    <div className={s.viewerName}>ETMA: {(streamer.channel.etmaRating && EEtmaRating[streamer.channel.etmaRating].toString()) || 'НЕ УКАЗАНО!'}</div>
                    <div className={s.viewerName}>Только стикеры канала: {streamer.channel.onlyChannelStickers ? 'ON' : 'OFF'}</div>
                    <div className={s.viewerName}>Twitch safe: {streamer.channel.onlyTwitchFriendly ? 'ON' : 'OFF'}</div>
                    <div className={s.viewerName}>Copyright safe: {streamer.channel.isCopyrightFreeOnly ? 'ON' : 'OFF'}</div>
                    <div className={s.viewerName}>Тематические подборки: {streamer.channel.isThematicSetsEnabled ? 'ON' : 'OFF'}</div>
                    <div className={s.viewerName}>Сообщения к стикерам: {streamer.channel.isStickersMessagesEnabled ? 'ON' : 'OFF'}</div>
                    <div className={s.viewerName}>Анти-мат: {streamer.channel.isAntiSwearEnabled ? 'ON' : 'OFF'}</div>
                    <div className={s.viewerName}>Стоп слова: {streamer.channel.antiSwearBadWords || 'НЕ УКАЗАНО'}</div>
                    <div className={s.viewerName}>MemeParty: {streamer.channel.memePartyActive ? 'ON' : 'OFF'}</div>
                    <div className={s.viewerName}>Способ авторизации: {anyStreamer.linkedAccounts.google ? `Google (${anyStreamer.linkedAccounts.google.email})` : anyStreamer.linkedAccounts.vk ? `VK (${anyStreamer.linkedAccounts.vk.username})` : `Twitch (${anyStreamer.linkedAccounts.twitch.username})`}</div>
                    <Row noGap>
                        <div className={cn(
                            s.statsTab,
                            { [s.statsTabActive]: tabType === EStreamerEventsTab.MONEY },
                        )} onClick={() => setTabType(EStreamerEventsTab.MONEY)}>
                            {`Деньги`}
                        </div>
                        <div className={cn(
                            s.statsTab,
                            { [s.statsTabActive]: tabType === EStreamerEventsTab.STICKERS },
                        )} onClick={() => setTabType(EStreamerEventsTab.STICKERS)}>
                            {`Стикеры`}
                        </div>
                        <div className={cn(
                            s.statsTab,
                            { [s.statsTabActive]: tabType === EStreamerEventsTab.MODERATION },
                        )} onClick={() => setTabType(EStreamerEventsTab.MODERATION)}>
                            {`Модерация`}
                        </div>
                    </Row>
                </>
            }

            {tabType === EStreamerEventsTab.MONEY && <MoneyTable streamer={streamer}/>}
            {tabType === EStreamerEventsTab.STICKERS && <StickersTable streamer={streamer}/>}
            {tabType === EStreamerEventsTab.MODERATION && <StreamerModeration streamer={streamer}/>}

            {(isLoading || isFetching) &&
                <div className={s.loader}>
                    <Loader/>
                </div>
            }
        </Panel>
    );
};