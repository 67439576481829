import { useApi, useAuth, useLogger } from '@hooks';
import { useEffect, useState } from 'react';

export const useCurrentUser = () => {
    const { trace, debug } = useLogger({ target: 'useCurrentUser' });

    const {
        user: {
            useCurrentUserQuery,
        },
        auth: {
            useLogoutMutation,
            useLazyReissueObsTokenQuery,
        }
    } = useApi();

    const {
        token,
        removeToken,
    } = useAuth();

    const {
        data: currentUser,
        isLoading,
        isFetching,
        isError,
        isSuccess,
        error,
        refetch: refetchCurrentUser,
    } = useCurrentUserQuery(void(0), { skip: !token});

    const [ logout ] = useLogoutMutation();

    const [ reissueObsTokenRequest ] = useLazyReissueObsTokenQuery();

    const [ noCurrentUser, setNoCurrentUser ] = useState(false);

    const reissueObsToken = async () => {
        const result = await reissueObsTokenRequest().unwrap();
        if (result && result.token) {
            localStorage.setItem('obsToken', result.token);
        }
    }

    useEffect(() => {
        setNoCurrentUser((!isLoading && !isFetching && isSuccess && !currentUser) || isError);
        if (isError && error && (error as any).status === 403) {
            debug(`Current token is invalid and will be removed`);
            removeToken();
        }
    }, [currentUser, isLoading, isFetching, isError, isSuccess]);

    useEffect(() => {
        trace('Mount');
        if (!token) {
            debug(`Current user load request is skipped because there is no token provided`);
            setNoCurrentUser(true);
        }
        return () => trace('Dismount');
    }, []);

    return {
        currentUser,
        noCurrentUser,
        state: {
            isLoading: isLoading || isFetching,
            isError,
            isSuccess,
        },
        refetchCurrentUser,
        reissueObsToken,
        logout,
    }
}