import React from 'react'
import { MemeAlertsLogo, MemeAlertsGrayScaleLogo } from '@images/svg';
import cn from 'classnames';

import s from './Logo.scss';

interface LogoProps {
    className?: string;
    grayscale?: boolean;
    onClick?: () => void;
}

export const Logo = (props: LogoProps) => {
    const {
        className,
        grayscale = false,
        onClick,
    } = props;

    return (
        <div
            className={cn(
                s.logo,
                className,
            )}
            onClick={onClick}
        >
            {grayscale
                ? <MemeAlertsGrayScaleLogo/>
                : <MemeAlertsLogo />
            }
        </div>
    )
}