import { StreamerAlertsStatsData, StreamerAlertsStatsDataItem } from '@interfaces';
import { api } from '@store';
import { useEffect, useState } from 'react';

export const useStreamerAlertsStats = () => {
    const {
        stats: {
            useLazyGetStreamerAlertsStatsPageQuery,
        }
    } = api;

    const [page, setPage] = useState(1);
    const [streamerName, setStreamerName] = useState<string>();
    const [currentStats, setCurrentStats] = useState<StreamerAlertsStatsDataItem[]>([]);
    const [lastData, setLastData] = useState<StreamerAlertsStatsData>();
    const [isEndReached, setIsEndReached] = useState(false);

    const [
        loadPage,
        {
            data: loadedPage,
            isLoading: isLoadingPage,
            isFetching: isFetchingPage,
        }
    ] = useLazyGetStreamerAlertsStatsPageQuery();

    const loadNextPage = () => {
        setPage(page + 1);
    }

    useEffect(() => {
        if (streamerName) {
            loadPage({page, streamerName});
        }
    }, []);

    useEffect(() => {
        if (streamerName) {
            loadPage({page, streamerName});
        } else {
            setIsEndReached(false);
            setLastData(undefined);
            setCurrentStats([]);
        }
    }, [page, streamerName]);

    useEffect(() => {
        if (loadedPage && !loadedPage.data.length) {
            if (loadedPage.streamer) {
                setIsEndReached(true);
            } else {
                setIsEndReached(false);
                setLastData(undefined);
                setCurrentStats([]);
            }
        } else {
            setCurrentStats((prev) => [...prev].concat(loadedPage?.data ?? []));
            setLastData(loadedPage);
        }
    }, [loadedPage]);

    return {
        streamerName,
        lastData,
        currentStats,
        isLoadingPage,
        isFetchingPage,
        isEndReached,

        loadNextPage,
        setStreamerName,
    };
};
