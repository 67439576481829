import React from 'react';
import { Link, To } from 'react-router-dom';

import cn from 'classnames';

import s from './MenuItem.scss';

interface MenuItemProps {
    className?: string;
    iconClassName?: string;
    caption: string;
    icon?: JSX.Element;
    isActive?: boolean;
    to?: To;
    onClick?: () => void;
}

export const MenuItem = (props: MenuItemProps) => {
    const { className, iconClassName, caption, icon, isActive, to, onClick } = props;

    const item =
        <div
            className={cn(
                s.menuItem,
                {
                    [s.menuItemActive]: isActive,
                    [s.clickable]: to || onClick,
                },
                className,
            )}
            onClick={onClick}
        >
            <div className={cn(s.menuItemIcon, iconClassName)}>
                {icon}
            </div>
            <div className={s.menuItemCaption}>
                {caption}
            </div>
        </div>;


    return to ? <Link className={s.link} to={to}>{item}</Link> : item;
}