import { useEffect, useState } from 'react'

interface SwitchParams {
    initialChecked?: boolean;
    onChange?: (value: boolean) => void;
}

export const useSwitch = (params: SwitchParams = {}) => {
    const {
        initialChecked = false,
        onChange,
    } = params;

    const [checked, setChecked] = useState<boolean>(initialChecked);

    const handleChange = () => {
        onChange && onChange(!checked);
        setChecked(checked => !checked);
    }

    useEffect(() => {
        setChecked(initialChecked);
    }, [initialChecked]);

    return {
        checked,
        onChange: handleChange,
    }
}