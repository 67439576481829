import { ITag } from './ITag';

export enum EAlertType {
    IMAGE = 'IMAGE',
    IMAGE_AND_AUDIO = 'IMAGE_AND_AUDIO',
    GIF = 'GIF',
    GIF_AND_AUDIO = 'GIF_AND_AUDIO',
}

export enum EModerationState {
    MODERATION = 'MODERATION',
    APPROVED = 'APPROVED',
    DECLINED = 'DECLINED',
    DELETED = 'DELETED',
}

export enum EAlertSentTopic {
    DEFAULT = 'Default',
    SEARCH = 'Search',
    TOP = 'Top',
    POPULAR = 'Popular',
    LAST = 'Last',
    CHANNEL = 'Channel',
    FAVORITES = 'Favorites',
    MY_CREATED = 'MyCreated',
    THEMATIC_SET = 'Theme',
}

export enum EEtmaRating {
    E,
    T,
    M,
    A,
}

export interface AlertCreationDto {
    name: string;
    description: string;
    streamerId?: string;
    gameId?: string;
    tags?: ITag[];
    token: string;
}

export interface AlertUpdateDto {
    id: string;
    name?: string;
    description?: string;
    moderationState?: EModerationState;
    isAdult?: boolean;
    isNotTwitchSafe?: boolean;
    hasCopyright?: boolean;
    isOnTop?: boolean;
    etmaRating?: EEtmaRating;
    streamerId?: string | null;
    gameId?: string | null;
    tags?: ITag[];
    rejectReason?: string;
    speech2text?: string;
    ocrText?: string;
    music?: string;
}

export interface MassStickersUpdateDto {
    query: string;
    streamerId?: string | null;
    gameId?: string | null;
    addedTags?: ITag[];
    removedTags?: ITag[];
    isNotTwitchSafe?: boolean;
}

export interface VideoData {
    width: number;
    height: number;
    aspectRatio: number;
    medianColor?: string;
}

export interface StickerDto {
    id: string;
    name: string;
    description: string;
    stickerUrl?: string;
    fallbackUrl?: string;
    stickerPreview? : string;
    stickerAnimatedPreview? : string;
    type: EAlertType; // ? Is it field needed?
    creatorId: string;
    streamerId?: string;
    gameId?: string;
    usageCount?: number;
    moderationState: string;
    isAdult?: boolean;
    isNotTwitchSafe?: boolean;
    hasCopyright?: boolean;
    isOnTop?: boolean;
    isFavorite?: boolean;
    likedCount?: number;
    isAddedToChannel?: boolean;
    categories?: string[];
    createdAt?: number;
    creatorName?: string;
    creatorAvatar?: string;
    moderatorName?: string;
    moderatorAvatar?: string;
    streamerName?: string;
    streamerAvatar?: string;
    gameName?: string;
    gameCoverThumbId?: string;
    customSettings?: AlertCustomSettingsDto;
    videoData?: VideoData;
    etmaRating?: EEtmaRating;
    disableCount?: number;
    disabledAt?: number;
    creatorTotalApproved?: number;
    creatorTotalDeclined?: number;
    tags?: ITag[];
    speech2text?: string;
    ocrText?: string;
    music?: string;
    isAdultProb?: number;
}

export interface SendStickerDto {
    toChannel: string;
    fullscreen?: boolean;
    isSoundOnly?: boolean;
    stickerId: string;
    topic: EAlertSentTopic,
    name?: string;
    isMemePartyActive?: boolean;
    message?: string;
}

export interface SendMemeCannonStickerDto {
    toChannel: string;
    stickerId: string;
    topic: EAlertSentTopic,
    count: number;
    name?: string;
    isMemePartyActive?: boolean;
    message?: string;
    isSoundOnly?: boolean;
}

export interface AlertInCategoryDto {
    stickerId: string;
    categoryId: string;
}

export interface AlertsModerationStats {
    total: number;
    top: number;
    onModeration: number;
    declined: number;
}

export enum AlertSize {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
}

export interface RegisterSendAlertPopupOpenEventDto {
    userId: string;
    streamerName: string;
    streamerId: string;
    stickerId: string;
}

export interface RegisterStreamerPageOpenEventDto {
    userId: string;
    streamerName: string;
    streamerId: string;
}

export interface AlertCustomSettingsDto {
    disableSticker?: boolean;
    customPriceEnabled?: boolean;
    customPrice?: number;
    customVolumeEnabled?: boolean;
    customVolume?: number;
}

export interface UpdateAlertCustomSettingsDto {
    stickerId: string;
    alertCustomSettings: AlertCustomSettingsDto;
}
