import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { columnizeStickers, noop } from '@common';
import { Column, Sticker, StickerSizeCalculationBase } from '@components';
import { StickerDto } from '@interfaces';
import s from './StickersGrid.scss';
import { StickerOverlay } from './components';
import { DEFAULT_SOUND_VOLUME } from '@const';

interface StickersGridProps {
    className?: string,
    isAppleMobileDevice?: boolean,
    isMobileLayoutRecommended?: boolean,
    stickers: Array<StickerDto>;
    stickersSoundEnabled?: boolean;
    columnsCount?: number;
    defaultSoundVolume?: number;
    onStickerClick?: (a: StickerDto) => void;
    onSwitchSound?: () => void;
}

export const StickersGrid = (props: StickersGridProps) => {
    const {
        className,
        isAppleMobileDevice,
        isMobileLayoutRecommended,
        stickers,
        stickersSoundEnabled = false,
        columnsCount = 4,
        defaultSoundVolume = DEFAULT_SOUND_VOLUME,
        onStickerClick = noop,
        onSwitchSound,
    } = props;

    const [ stickersColumnsCount, setStickersColumnsCount] = useState(0);

    const columns = columnizeStickers(stickers, stickersColumnsCount);

    const onStickerClickHandler = async (s: StickerDto) => {
        onStickerClick(s);
    }

    const items = columns.map((column, idx) => (
        <Column key={idx} className={s.stickersGridColumn}>
            { column.map((sticker, idx) => (
                <Sticker
                    className={cn(
                        s.sticker,
                        {
                            [s.stickerForAppleDevice]: isAppleMobileDevice,
                        }
                    )}
                    key={idx}
                    sticker={sticker}
                    withSound={stickersSoundEnabled}
                    soundOnHover
                    loop
                    useFadeInOnLoaded
                    useSkeletonOnLoading
                    useFallbackVideo={isAppleMobileDevice}
                    useAnimatedPreview={isMobileLayoutRecommended}
                    overlay={ !isMobileLayoutRecommended
                        ? <StickerOverlay
                            sticker={sticker}
                            soundEnabled={stickersSoundEnabled}
                            onSoundButtonClick={onSwitchSound}
                        />
                        : undefined
                    }
                    volume={sticker.customSettings?.customVolume || defaultSoundVolume}
                    sizeCalculationBase={StickerSizeCalculationBase.WIDTH}
                    onClick={onStickerClickHandler}
                />
            ))}
        </Column>
    ))

    useEffect(() => {
        setStickersColumnsCount(isMobileLayoutRecommended ? 2 : columnsCount);
    }, [columnsCount, isMobileLayoutRecommended]);

    // useEffect(() => {
    //     setStickersColumnsCount(columnsCount);
    // }, [columnsCount]);

    return (
        <div className={cn(s.stickersGrid, className)}>
            {items}
        </div>
    )
}