import { IModerationShift, IUser, TopStreamers, WalletState } from '@interfaces';
import { memeAlertsBaseApi } from './meme-alerts-base.api';

export const memeAlertsUserApi = memeAlertsBaseApi.injectEndpoints({
    endpoints: (build) => ({
        /** Loads current user data */
        currentUser: build.query<IUser, void>({
            query: () => ({
                url: 'user/current',
                method: 'GET',
            }),
            providesTags: ['CurrentUser']
        }),
        /** Loads current user data */
        currentUserReferals: build.query<IUser[], void>({
            query: () => ({
                url: 'user/referals',
                method: 'GET',
            }),
        }),
        /** Loads current user data */
        getTopStreamers: build.query<TopStreamers, void>({
            query: () => ({
                url: 'user/top-streamers',
                method: 'GET',
            }),
            providesTags: ['TopStreamers']
        }),
        /** Gets a user by name */
        getUserByName: build.query<IUser, string>({
            query: (username?: string) => ({
                url: 'user/find',
                method: 'POST',
                body: { username }
            }),
        }),

        getPrivateUserByName: build.query<IUser, string>({
            query: (username?: string) => ({
                url: 'user/findPrivate',
                method: 'POST',
                body: { username }
            }),
        }),

        deleteUserById: build.query<boolean, string>({
            query: (userId?: string) => ({
                url: 'user/delete',
                method: 'POST',
                body: { userId }
            }),
        }),

        restoreUserById: build.query<boolean, string>({
            query: (userId?: string) => ({
                url: 'user/delete/restore',
                method: 'POST',
                body: { userId }
            }),
        }),

        /** Gets a balance */
        getBalanceByStreamerName: build.query<WalletState, string>({
            query: (username?: string) => ({
                url: 'user/balance',
                method: 'POST',
                body: { username }
            }),
        }),

        /** Gets a balance */
        earnWelcomeBonus: build.mutation<WalletState, string>({
            query: (streamerId?: string) => ({
                url: 'user/earn-welcome-bonus',
                method: 'POST',
                body: { streamerId }
            }),
        }),

        giveBonus: build.query<void, {userId: string; streamerId: string; value: number;}>({
            query: (data) => ({
                url: 'user/give-bonus',
                method: 'POST',
                body: data
            }),
        }),

        updateUserName: build.mutation<IUser, string>({
            query: (value: string) => ({
                url: 'user/name',
                method: 'POST',
                body: { value },
            }),
            invalidatesTags: ['CurrentUser'],
        }),

        getCurrentModerationShift: build.query<IModerationShift, void>({
            query: () => ({
                url: 'user/moderation/shift',
                method: 'POST',
            }),
            providesTags: ['ModerationShift'],
        }),

        openModerationShift: build.mutation<IModerationShift, void>({
            query: () => ({
                url: 'user/moderation/open-shift',
                method: 'POST',
            }),
            invalidatesTags: ['ModerationShift'],
        }),

        closeModerationShift: build.mutation<IModerationShift, void>({
            query: () => ({
                url: 'user/moderation/close-shift',
                method: 'POST',
            }),
            invalidatesTags: ['ModerationShift'],
        }),

        findStreamer: build.query<IUser[], string>({
            query: (value: string) => ({
                url: 'user/find/streamer',
                method: 'POST',
                body: { value },
            }),
        }),
    }),
});
