import { EventType } from '@hooks';
import { ViewerEventsData, ViewerEventsDataItem } from '@interfaces';
import cn from 'classnames';
import React from 'react';
import s from './EventRecord.scss';

interface EventRecordProps {
    data?: ViewerEventsData;
    item: ViewerEventsDataItem;
}

export const EventRecord = (props: EventRecordProps) => {
    const {
        data,
        item,
    } = props;

    const formatTimestamp = (timestamp?: number) => {
        if (!timestamp) {
            return '';
        }
        const date = new Date(timestamp);
        return date.toLocaleDateString('ru-RU', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        });
    }

    const kindToType = (item: ViewerEventsDataItem) => {
        const kind = item.kind;
        return kind === EventType.BUY_CURRENCY || kind === EventType.WELCOME_BONUS_EARNED || kind === EventType.BONUS_EARNED ? 'Пополнение' : 'Трата';
    }

    const resolveRomanEventType = (item: ViewerEventsDataItem) => {
        const kind = item.kind;
        const fullscreen = item.fullscreen;
        if (kind === EventType.ALERT_SENT) {
            return fullscreen ? 'Фуллскрин' : 'Стрикер';
        }
        if (kind === EventType.MEME_CANNON_STICKER_SENT) {
            return 'Мемпушка';
        }
        if (kind === EventType.WELCOME_BONUS_EARNED) {
            return 'Бонус';
        }
        if (kind === EventType.BONUS_EARNED) {
            return 'Бонус';
        }
        if (kind === EventType.BUY_CURRENCY) {
            return 'Покупка';
        }
    }

    return (
        <tr className={cn({
                [s.bonusRow]: item.kind === EventType.WELCOME_BONUS_EARNED || item.kind === EventType.BONUS_EARNED,
                [s.buyRow]: item.kind === EventType.BUY_CURRENCY,
                [s.expenseRow]: item.kind === EventType.ALERT_SENT || item.kind === EventType.MEME_CANNON_STICKER_SENT,
            })}>
            <td>
                { formatTimestamp(item.timestamp) }
            </td>
            <td>
                { item.streamerName }
            </td>
            <td>
                { kindToType(item) }
            </td>
            <td>
                { resolveRomanEventType(item) }
            </td>
            <td>
                { item.stickerName }
            </td>
            <td>
                { item.paidPrice || item.packValue || item.bonusValue || 0 }
            </td>
            <td>
                { item.packPrice }
            </td>
            <td>
                { item.balance || 0 }
            </td>
        </tr>
    );
};
