import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import config from "config";

export const memeAlertsBaseApi = createApi({
    reducerPath: "meme-alerts/api",
    tagTypes: [
        'Categories',
        'CohortStats',
        'CurrentDailyStats',
        'CurrentMonthlyStats',
        'CurrentSearchQueriesStats',
        'CurrentSearchStats',
        'CurrentSendAlertsStats',
        'CurrentSentAlertsStats',
        'CurrentUser',
        'DailyStatsPages',
        'ModerationAreaStickers',
        'ModerationShift',
        'ModerationStats',
        'MonthlyStatsPages',
        'SearchStatsPages',
        'SentAlertsStatsPages',
        'StreamerAlertsStats',
        'StreamerModerationAreaStickers',
        'StreamerStickersStats',
        'ThematicSets',
        'TopStreamers',
    ],
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiHost,
        prepareHeaders: (headers) => {
            let token = localStorage.getItem("accessToken") ?? "";
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: () => ({}),
});
