import { EEtmaRating } from './StickerDto';
import { Position } from './Geometry';
import { IMorpherDeclensions } from "./IMorpherDeclensions";
import { ITag } from './ITag';

export interface ChannelDto {
    name?: string;
    link?: string;
    onlyChannelStickers?: boolean;
    catalogueStickerPrice?: number;
    avatarUrl?: string;
    isAdultDisabled?: boolean;
    onlyTwitchFriendly?: boolean;
    isCopyrightFreeOnly?: boolean;
    stickerSize?: string;
    stickerStickAreasMask?: number;
    donationAlertsUsername?: string;
    donationAlertsUserId?: string;
    backgroundUrl?: string;
    backgroundMode?: number;
    soundVolume?: number;
    currencyImageUrl?: string;
    currencyName?: string;
    currencyNameDeclensions?: IMorpherDeclensions;
    memePartyActive?: boolean;
    memePartySoundEnabled?: boolean;
    memePartyOwnSoundEnabled?: boolean;
    memePartyOwnSoundUrl?: string;
    memePartyConfetti?: boolean;
    memePartyProgressPosition?: Position;
    memePartyGoal?: number;
    memePartyDuration?: number;
    memePartySendDelay?: number;
    isPopupNickNameEnabled?: boolean;
    isPopupNewSaleEnabled?: boolean;
    popupNickNameAreaMask?: number;
    etmaRating?: EEtmaRating;
    fullscreenStickersEnabled?: boolean;
    fullscreenStickersPrice?: number;
    refCode?: string;
    welcomeBonusEnabled?: boolean;
    welcomeBonus?: number;
    newbieActionEnabled?: boolean;
    newbieAction?: number;
    memeCannonEnabled?: boolean;
    memeCannonPrice?: number;
    twitchLink?: string;
    youtubeLink?: string;
    telegramLink?: string;
    paywallText?: string;
    sendDelay?: number;
    masteredObsSettings?: boolean;
    disableStickers?: boolean;
    isAntiSwearEnabled?: boolean;
    antiSwearBadWords?: string;
    antiSwearRemoveLinks?: boolean;
    isThematicSetsEnabled?: boolean;
    isStickersMessagesEnabled?: boolean;
    forbiddenTags?: ITag[];
    isNewSupporterAlertEnable?: boolean;
    supporterName?: string;
    supporterNameDeclensions?: IMorpherDeclensions;
    isSoundOnlyStickersEnabled?: boolean;
}
