import { api } from '@store';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const useAdmin = () => {
    const {
        user: {
            useCurrentUserQuery,
        },
    } = api;

    const navigate = useNavigate();
    const {
        data: currentUser,
    } = useCurrentUserQuery();

    useEffect(() => {
        //console.log(currentUser);
        if (currentUser && !currentUser?.flags?.isAdmin) {
            //navigate('/');
        }
    }, [currentUser]);

    return {
        currentUser,
    };
};
