import React from 'react';

import cn from 'classnames';

import s from './Icon.scss';

export enum IconSize {
    TINY = 'tiny',
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
    HUGE = 'huge',
}

interface IconProps {
    className?: string;
    icon?: JSX.Element;
    iconSize?: IconSize;
    onClick?: () => void;
}

export const Icon = (props: IconProps) => {
    const {
        className,
        icon,
        iconSize = IconSize.MEDIUM,
        onClick,
    } = props;

    return (
        <div
            className={cn(
                className,
                s.icon,
                s[iconSize],
            )}
            onClick={(e) => {
                e.stopPropagation();
                onClick?.();
            }}
        >
            {icon}
        </div>
    );
};
