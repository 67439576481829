import { Button, ComponentColor, IconButton, Input, InputColor, Loader, Panel, RoundedAvatar, RoundedAvatarSize, Row } from '@components';
import {EViewerEventsTableType, useModals, useViewerEvents} from '@hooks';
import React, { useState } from 'react';
import s from './ViewerEvents.scss';
import { EventRecord } from './components';
import cn from 'classnames';
import {DeleteOrRestoreUserPopup} from "@views/modals";

export const ViewerEvents = () => {
    const {
        currentUser,
        userId,
        lastData,
        currentStats,
        isLoadingPage,
        isFetchingPage,
        isEndReached,
        isSuccess,
        displayErrors,
        loadNextPage,
        setUserId,
        find,
        giveBonus,
        deleteUser,
        restoreUser,
    } = useViewerEvents();

    const { showModal } = useModals();

    const [tableType, setTableType] = useState<EViewerEventsTableType>(EViewerEventsTableType.EVENTS);
    const [streamerId, setStreamerId] = useState<string>();
    const [bonus, setBonus] = useState<number>();

    const viewer = lastData?.viewer as any;

    const eventRows = currentStats?.map((r, idx) => (
        <EventRecord data={lastData} item={r} key={idx}/>
    ));

    const walletRows = lastData?.wallets?.map((r, idx) => (
        <tr key={idx}>
            <td className={cn(
                            s.walletStreamer,
                            { [s.walletStreamerActive]: streamerId === r.streamerId },
                        )} onClick={() => {setStreamerId(r.streamerId)}}>
                { r.streamerName }
            </td>
            <td className={cn(
                            { [s.walletStreamerActive]: streamerId === r.streamerId },
                        )} onClick={() => {setStreamerId(r.streamerId)}}>
                { r.balance }
            </td>
        </tr>
    ));

    const formatTimestamp = (timestamp?: number) => {
        if (!timestamp) {
            return '';
        }
        const date = new Date(timestamp);
        return date.toLocaleDateString('ru-RU', {
            day: '2-digit',
            month: 'short',
            hour: '2-digit',
            minute: '2-digit',
        }).replace(/\./g, '');
    }

    const onDeleteOrRestoreUser = (userId: string, callback: (userId: string) => void, caption: string) => {
        showModal({
            content: (
                <DeleteOrRestoreUserPopup
                    onAcceptClick={callback}
                    buttonCaption={caption}
                    userId={userId}
                />
            ),
            options: {}
        });
    }

    const onDeleteUserCallback = async (userId: string) => {
        await deleteUser(userId);
    }

    const onRestoreCallback = async (userId: string) => {
        await restoreUser(userId);
    }

    return (
        <Panel className={s.statsContainer}>
            <div className={s.streamerName}>
                <Input
                    placeholder='ID зрителя'
                    className={s.streamerNameInput}
                    color={InputColor.ORANGE}
                    errorMessage={!userId ? 'Введите ID зрителя' : 'Зритель с таким ID не найден'}
                    hasError={
                        (!isSuccess && displayErrors && !userId) ||
                        (displayErrors && isSuccess && !isLoadingPage && !isFetchingPage && !!userId && !lastData?.viewer)}
                    onChange={(value) => setUserId(value?.toString())}
                />
                {<RoundedAvatar source={lastData?.viewer?.channel?.avatarUrl} size={RoundedAvatarSize.MEDIUM}/>}
                <IconButton
                    caption={'Найти'}
                    color={ComponentColor.ORANGE}
                    onClick={find}
                />
            </div>
            {lastData?.viewer &&
                <>
                {lastData?.viewer.deletedAt &&
                    <>
                        <div className={s.viewerDeleted}>ПОЛЬЗОВАТЕЛЬ УДАЛЕН!</div>
                        <div className={s.viewerName}>Дата удаления: {`${formatTimestamp(viewer.deletedAt)}`}</div>
                        {currentUser?.flags.isAdmin &&
                            <div className={s.deleteOrRestoreButton}>
                                <IconButton
                                    caption={'Восстановить пользователя'}
                                    color={ComponentColor.ORANGE}
                                    onClick={() => onDeleteOrRestoreUser(lastData.viewer.id, onRestoreCallback, 'Восстановить')}
                                />
                            </div>
                        }
                    </>
                }
                {!lastData?.viewer.deletedAt &&
                    <>
                        <div className={s.viewerName}>Имя пользователя: {lastData.viewer.name}</div>
                        <div className={s.viewerName}>Способ авторизации: {viewer.linkedAccounts.google ? `Google (${viewer.linkedAccounts.google.email})` : viewer.linkedAccounts.vk ? `VK (${viewer.linkedAccounts.vk.username})` : `Twitch (${viewer.linkedAccounts.twitch.username})`}</div>
                        {currentUser?.flags.isAdmin &&
                            <div className={s.deleteOrRestoreButton}>
                                <IconButton
                                    className={s.deleteOrRestoreButton}
                                    caption={'Удалить пользователя'}
                                    color={ComponentColor.RED}
                                    onClick={() => onDeleteOrRestoreUser(lastData.viewer.id, onDeleteUserCallback, 'Удалить')}
                                />
                            </div>
                        }
                    </>
                }
            <Row noGap>
            <div className={cn(
                            s.statsTab,
                            { [s.statsTabActive]: tableType === EViewerEventsTableType.EVENTS },
                        )} onClick={() => setTableType(EViewerEventsTableType.EVENTS)}>
                            {`События`}
                        </div>
                        <div className={cn(
                            s.statsTab,
                            { [s.statsTabActive]: tableType === EViewerEventsTableType.WALLETS },
                        )} onClick={() => setTableType(EViewerEventsTableType.WALLETS)}>
                            {`Кошельки`}
                        </div>
                    </Row>
                </>
            }
            {(currentStats.length > 0 && tableType === EViewerEventsTableType.EVENTS) &&
                <table>
                    <thead>
                        <tr>
                            <td>
                                { 'Date' }
                            </td>
                            <td>
                                { 'Streamer' }
                            </td>
                            <td>
                                { 'Type' }
                            </td>
                            <td>
                                { 'Event' }
                            </td>
                            <td>
                                { 'Sticker' }
                            </td>
                            <td>
                                { 'Coins' }
                            </td>
                            <td>
                                { 'Money' }
                            </td>
                            <td>
                                { 'Balance' }
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        { eventRows }
                    </tbody>
                </table>
            }
            {(lastData?.viewer && lastData?.wallets && tableType === EViewerEventsTableType.WALLETS) &&
                <>
                <table>
                    <thead>
                        <tr>
                            <td>
                                { 'Streamer' }
                            </td>
                            <td>
                                { 'Balance' }
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        { walletRows }
                    </tbody>
                </table>
                {streamerId &&
                    <div className={s.bonusControls}>
                        <Input
                            type='number'
                            placeholder='Сумма'
                            defaultValue={bonus}
                            className={s.bonusInput}
                            color={InputColor.ORANGE}
                            onChange={(value) => setBonus(value ? parseInt(value.toString()) : 0)}
                        />
                        <IconButton
                            caption={'Дать бонус'}
                            disabled={!bonus || parseInt(bonus.toString()) == 0}
                            color={ComponentColor.ORANGE}
                            onClick={() => giveBonus(streamerId, bonus || 0)}
                        />
                    </div>
                }
                </>
            }

            {!isEndReached && currentStats.length > 0 && !isLoadingPage && !isFetchingPage && tableType === EViewerEventsTableType.EVENTS &&
                <Button
                    caption={'Загрузить еще'}
                    className={s.loadMoreButton}
                    onClick={loadNextPage}
                />
            }
            {(isLoadingPage || isFetchingPage) &&
                <div className={s.loader}><Loader/></div>
            }
        </Panel>
    );
};