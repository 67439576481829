import { Button, Loader, Panel } from '@components';
import { useSearchQueriesStats } from '@hooks';
import React from 'react';
import s from './SearchQueriesStats.scss';
import { StatisticRecord } from './components';

export const SearchQueriesStats = () => {
    const {
        currentStats,
        isLoadingPage,
        isEndReached,
        loadNextPage,
    } = useSearchQueriesStats();

    const rows = currentStats?.map((r, idx) => (
        <StatisticRecord data={r} key={idx}/>
    ));

    return (
        <Panel className={s.statsContainer}>
            {
                isLoadingPage
                    ? <Loader />
                    : <>
                        <table>
                            <thead>
                                <tr>
                                    <td>
                                        { "Query" }
                                    </td>
                                    <td>
                                        { 'Count' }
                                    </td>
                                    <td>
                                        { 'Results' }
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                { rows }
                            </tbody>
                        </table>

                        {!isEndReached &&
                            <Button
                                caption={'Загрузить еще'}
                                className={s.loadMoreButton}
                                onClick={loadNextPage}
                            />
                        }
                    </>
            }
        </Panel>
    );
};