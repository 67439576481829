import { noop } from '@common';
import { ComponentColor, Loader, RoundedAvatar, RoundedAvatarSize } from '@components';
import cn from 'classnames';
import React, { useRef } from 'react';
import s from './StreamerSelect.scss';
import { useStreamerSelect } from './hooks/StreamerSelect';
import { IconCloseLine } from '@common/images/svg';
import { IUser } from '@interfaces';

export interface StreamerSelectMethods {
    reset: () => void;
    focus: () => void;
    set: (value: string) => void;
}

export interface StreamerSelectDefaultValue {
    id: string;
    avatarUrl?: string;
    name?: string;
}

interface StreamerSelectProps {
    className?: string;
    color?: ComponentColor;
    debounceTime?: number;
    defaultValue?: StreamerSelectDefaultValue;
    methodsRef?: React.MutableRefObject<StreamerSelectMethods | null>;
    invalidSymbols?: RegExp;
    maxLength?: number;
    maxWidth?: number;
    minWidth?: number;
    fullWidth?: boolean;
    placeholder?: string;
    readonly?: boolean;
    useInactiveShadow?: boolean;
    onSelect?: (streamer?: IUser) => void;
}

export const StreamerSelect = (props: StreamerSelectProps) => {
    const {
        className,
        color = ComponentColor.DEFAULT,
        methodsRef,
        maxWidth,
        minWidth,
        fullWidth,
        placeholder,
        useInactiveShadow,
        onSelect = noop,
    } = props;

    const inputRef = useRef<HTMLInputElement>(null);
    const {
        isLoadInProgress,
        searchResults,
        selectedStreamer,
        errorUrls,
        value,
        onChange,
        setSelectedStreamer,
        set,
        reset,
    } = useStreamerSelect({...props});

    const styles = {
        minWidth,
        maxWidth,
    }

    const focus = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }

    const onStreamerClick = (streamer: IUser) => {
        setTimeout(() => {
            setSelectedStreamer(streamer);
            onSelect(streamer);
        }, 0);
    }

    const textFieldClassNames = cn(
        s.textField,
        className,
        s['color' + color],
        {
            [s.textFieldInactiveShadow]: useInactiveShadow,
            [s.textFieldFullWidth]: fullWidth,
        }
    );

    if (methodsRef) {
        methodsRef.current = {
            reset,
            focus,
            set,
        }
    }

    const getStreamerItem = (user: IUser, onSelect?: (user: IUser) => void) => {
        return (
            <div 
                key={user._id}
                onMouseDown={() => onSelect ? onSelect(user) : noop}
                className={cn(s.streamerItem, {[s.streamerItemSelectable]: !!onSelect})}>
                <div className={s.streamerItemIcon}>
                    <RoundedAvatar
                        className={s.streamerItemIconAvatar}
                        source={errorUrls?.includes(user.channel.avatarUrl || '') ? '' : user.channel.avatarUrl}
                        size={RoundedAvatarSize.EXTRA_SMALL}
                    />
                </div>
                <div className={s.streamerItemText}>{user.channel.name}</div>
            </div>
        );
    }

    return (
        <div className={textFieldClassNames} style={styles} onBlur={() => reset()}>
            <div className={s.textFieldInputContainer}>
                <input
                    className={cn(s.textFieldInput, {
                        [s.textFieldInputInvisible]: !!selectedStreamer,
                    })}
                    placeholder={placeholder}
                    ref={inputRef}
                    value={value}
                    onChange={onChange}
                />
                { selectedStreamer && 
                    <div className={s.textFieldSelectedStreamer}>
                        { getStreamerItem(selectedStreamer) }
                    </div>
                }
            </div>

            <div className={s.textFieldActionsContainer}>
                <div className={s.textFieldAction}>
                    <Loader className={
                        cn(
                            s.loader, 
                            {
                                [s.loaderHidden]: !isLoadInProgress,
                            }
                        )}
                    />
                </div>
                <div
                    className={cn(
                        s.textFieldAction, 
                        {
                            [s.textFieldActionHidden]: !selectedStreamer,
                        }
                    )}
                    onClick={reset}>
                    <IconCloseLine/>
                </div>
            </div>

            {(searchResults && searchResults?.length > 0) &&
                <div className={s.textFieldDropdown}>
                    { searchResults?.map((res) => getStreamerItem(res, onStreamerClick)) }
                </div>
            }
        </div>
    )
}