import { ButtonType, ComponentColor } from '@components';
import React, { useState } from 'react';
import cn from 'classnames';
import s from './IconButton.scss';
import { noop } from '@common';

interface IconButtonProps {
    caption?: string | JSX.Element;
    className?: string;
    color?: ComponentColor;
    fullWidth?: boolean;
    icon?: JSX.Element;
    iconClassName?: string;
    type?: ButtonType;
    disabled?: boolean;
    onClick?: () => void;
    onHover?: () => void;
}

export const IconButton = (props: IconButtonProps) => {
    const {
        caption,
        className,
        color = ComponentColor.DEFAULT,
        fullWidth,
        icon,
        iconClassName,
        disabled = false,
        type = 'button',
        onClick = noop,
        onHover = noop,
    } = props;

    const [pressed, setPressed] = useState(false);

    const iconButtonClassnames = cn(
        s.iconButton,
        s['color' + color],
        {
            [s.iconButtonFullWidth]: fullWidth,
            [s.iconButtonClickable]: !!onClick,
            [s.disabled]: disabled,
            [s['color' + color + 'Disabled']]: disabled,
            [s['color' + color + 'Pressed']]: pressed,
        },
        className,
    );

    const iconButtonIconClassnames = cn(
        s.iconButtonIcon,
        iconClassName,
    );

    return (
        <button
            type={type}
            disabled={disabled}
            className={iconButtonClassnames}
            onClick={onClick}
            onMouseEnter={onHover}
            onMouseDown={() => setPressed(true)}
            onMouseUp={() => setPressed(false)}
        >
            { !! icon &&
                <div className={iconButtonIconClassnames}>
                    {icon}
                </div>
            }
            { !!caption &&
                <div className={s.iconButtonCaption}>
                    {caption}
                </div>
            }
        </button>
    )
}