import { Button, Loader, Panel } from '@components';
import { useCohortStats } from '@hooks';
import React from 'react';
import s from './CohortStats.scss';
import { StatisticRecord } from './components';

export const CohortStats = () => {
    const {
        currentStats,
        isLoadingPage,
        isEndReached,
        loadNextPage,
    } = useCohortStats();

    const rows = currentStats?.map((r, idx) => (
        <StatisticRecord data={r} key={idx}/>
    ));

    return (
        <Panel className={s.statsContainer}>
            {
                isLoadingPage
                    ? <Loader />
                    : <>
                        <table>
                            <thead>
                                <tr>
                                    <td>
                                        { "Num" }
                                    </td>
                                    <td>
                                        { 'Week' }
                                    </td>
                                    <td>
                                        { 'Invites' }
                                    </td>
                                    <td>
                                        { 'Cohort' }
                                    </td>
                                    <td>
                                        { 'Activated' }
                                    </td>
                                    <td>
                                        { 'Activated%' }
                                    </td>
                                    <td>
                                        { 'Earned' }
                                    </td>
                                    <td>
                                        { 'Earned%' }
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                { rows }
                            </tbody>
                        </table>

                        {!isEndReached &&
                            <Button
                                caption={'Загрузить еще'}
                                className={s.loadMoreButton}
                                onClick={loadNextPage}
                            />
                        }
                    </>
            }
        </Panel>
    );
};