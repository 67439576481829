import { noop } from '@common';
import { ComponentColor } from '@components';
import cn from 'classnames';
import React, { useMemo, useRef } from 'react';
import s from './TextField.scss';
import { useTextField } from './hooks/TextField';

export interface TextFieldAction {
    icon: JSX.Element;
    name: string;
    order?: number;
    hidden?: boolean;
    disabled?: boolean;
    keyboardKeys?: string[];
}

export interface TextFieldMethods {
    reset: () => void;
    focus: () => void;
    set: (value: string) => void;
}

interface TextFieldProps {
    actions?: Array<TextFieldAction>;
    className?: string;
    color?: ComponentColor;
    debounceTime?: number;
    defaultValue?: string;
    methodsRef?: React.MutableRefObject<TextFieldMethods | null>;
    invalidSymbols?: RegExp;
    maxLength?: number;
    maxWidth?: number;
    minWidth?: number;
    placeholder?: string;
    readonly?: boolean;
    tag?: JSX.Element;
    type?: React.HTMLInputTypeAttribute;
    useInactiveShadow?: boolean;
    onAction?: (action: TextFieldAction) => boolean | void;
    onChanged?: (value: string) => void;
    onEnter?: (value: string) => void;
    onLeave?: (value: string) => void;
}

export const TextField = (props: TextFieldProps) => {
    const {
        actions = [],
        className,
        color = ComponentColor.DEFAULT,
        methodsRef,
        maxWidth,
        minWidth,
        placeholder,
        tag,
        type,
        useInactiveShadow,
        onAction = noop,
        onEnter = noop,
        onLeave = noop,
    } = props;

    const inputRef = useRef<HTMLInputElement>(null);
    const textField = useTextField({...props});

    const styles = {
        minWidth,
        maxWidth,
    }

    const focus = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }

    const onActionHandler = (action: TextFieldAction) => {
        const isReset = onAction(action);
        if (isReset) {
            textField.reset();
        }
    }

    const actionsElements = useMemo(() => actions
        .filter(action => !action.hidden)
        .sort((a, b) => {
            const aOrder = a.order ?? 0;
            const bOrder = b.order ?? 0;
            return aOrder > bOrder ? 1 : aOrder < bOrder ? -1 : 0;
        })
        .map((action, idx) => (
        <div
            key={idx}
            className={cn(
                s.textFieldAction,
                {
                    [s.textFieldActionDisabled]: action.disabled,
                },
            )}
            onClick={() => onActionHandler(action)}
        >
            {action.icon}
        </div>
    )), [actions]);

    const onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const code = event.code;
        const action = actions.find(a => a.keyboardKeys?.includes(code));
        if (action) {
            onActionHandler(action);
        }
    }

    const textFieldClassNames = cn(
        s.textField,
        className,
        s['color' + color],
        {
            [s.textFieldInactiveShadow]: useInactiveShadow,
        }
    );

    if (methodsRef) {
        methodsRef.current = {
            reset: textField.reset,
            focus,
            set: textField.set,
        }
    }

    return (
        <div className={textFieldClassNames} style={styles}>
            { !!tag &&
                <div className={s.textFieldTagContainer}>
                    {tag}
                </div>
            }

            <div className={s.textFieldInputContainer}>
                <input
                    type={type}
                    className={s.textFieldInput}
                    placeholder={placeholder}
                    ref={inputRef}
                    value={textField.value}
                    onKeyUp={onKeyUp}
                    onChange={textField.onChange}
                    onFocus={() => onEnter(textField.value)}
                    onBlur={() => onLeave(textField.value)}
                />
            </div>

            { !!actions.length &&
                <div className={s.textFieldActionsContainer}>
                    {actionsElements}
                </div>
            }
        </div>
    )
}