import { SendedAlertStatisticData } from '@interfaces';
import { api } from '@store';
import { useEffect, useState } from 'react';

export const useSentAlertStats = () => {
    const {
        stats: {
            useLazyLoadDailySentAlertsStatsPageQuery,
            useGetCurrentDaySentAlertsStatsQuery,
        }
    } = api;

    const [dailyPage, setDailyPage] = useState(1);
    const [isDailyEndReached, setIsDailyEndReached] = useState(false);
    const [dailyStatistic, setDailyStatistic] = useState<SendedAlertStatisticData[]>([]);

    const {
        data: currentDayStats,
    } = useGetCurrentDaySentAlertsStatsQuery();

    const [
        loadDailyPage,
        {
            data: loadedDailyPage,
            isLoading: isDailyStatisticLoading,
        },
    ] = useLazyLoadDailySentAlertsStatsPageQuery();

    const loadNextDailyPage = () => {
        setDailyPage(dailyPage + 1);
    }

    useEffect(() => {
        loadDailyPage(dailyPage);
    }, []);

    useEffect(() => {
        loadDailyPage(dailyPage);
    }, [dailyPage]);

    useEffect(() => {
        if (loadedDailyPage && !loadedDailyPage.length) {
            setIsDailyEndReached(true);
        }
        setDailyStatistic((prev) => [...prev].concat(loadedDailyPage ?? []));
    }, [loadedDailyPage]);

    return {
        currentDayStats,
        dailyStatistic,
        isDailyStatisticLoading,
        isDailyEndReached,
        loadNextDailyPage,
    };
};
