import { StickerDto } from '@interfaces';
import { api } from '@store';

export const useSimilarStickers = () => {
    const {
        memesIndex: {
            useLazySearchSimilarStickersQuery,
        }
    } = api;

    const [
        searchSimilarStickers,
        {
            data: stickers,
            isLoading: isLoad,
            isFetching: isFetching,
        },
    ] = useLazySearchSimilarStickersQuery();

    const search = (alert: StickerDto) => {
        searchSimilarStickers({stickerId: alert.id})
    }

    const isLoading = isLoad || isFetching;

    return {
        stickers,
        isLoading,
        search,
    };
};
