import React from 'react'

import cn from 'classnames';

import s from './Switch.scss';
import { useSwitch } from './hooks/Switch';

export enum SwitchLabelPosition {
    LEFT = 'left',
    RIGHT = 'right',
}

export enum SwitchColor {
    RED = 'red',
    ORANGE = 'orange',
    GREEN = 'green',
    BLUE = 'blue',
    PURPLE = 'purple',
    BLACK = 'black',
}

interface SwitchProps {
    className?: string;
    caption?: string;
    captionPosition?: SwitchLabelPosition;
    color?: SwitchColor;
    checked?: boolean;
    wrapCaption?: boolean;
    onChange?: (value: boolean) => void;
}

export const Switch = (props: SwitchProps) => {
    const {
        className,
        checked: initialChecked,
        color = SwitchColor.ORANGE,
        caption,
        captionPosition = SwitchLabelPosition.LEFT,
        wrapCaption,
        onChange,
    } = props;

    const captionStyle = `caption-${captionPosition}`;

    const { checked, onChange: onCheckChange } = useSwitch({ initialChecked, onChange });

    return (
        <div className={cn(
            s.switchWrapper,
            className,
            s[color],
            s[captionStyle]
        )} onClick={() => onCheckChange()}>
            <span className={cn(
                s.switchCaption,
                { [s.switchCaptionAllowWrap]: wrapCaption }
            )}>{caption}</span>
            <div className={cn(
                s.switch,
                s.orange,
                {
                    [s.switchActive]: checked,
                }
            )}>
                <div className={cn(
                    s.switchThumbBorder,
                    {
                        [s.switchThumbBorderActive]: checked,
                    }
                )}>
                    <div className={cn(
                        s.switchThumb,
                        {
                            [s.switchThumbActive]: checked,
                        }
                    )}/>
                </div>
            </div>
        </div>
    )
}