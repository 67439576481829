import { SendedAlertStatisticData } from '@interfaces';
import React from 'react';


interface StatisticRecordProps {
    data: SendedAlertStatisticData;
    showAsMonth?: boolean;
}

export const StatisticRecord = (props: StatisticRecordProps) => {
    const {
        data,
        showAsMonth,
    } = props;

    return (
        <tr>
            <td>
                { showAsMonth
                    ? new Date(data.day).toLocaleDateString('ru-RU', {
                        month: 'long',
                    })
                    : new Date(data.day).toLocaleDateString()
                }
            </td>
            <td>
                { data.stickerSent ?? 0 }
            </td>
            <td>
                { data.search ?? 0 }
            </td>
            <td>
                { ((data.search ?? 0) / (data.stickerSent || 1) * 100).toFixed(2) }
            </td>
            <td>
                { data.top }
            </td>
            <td>
                { ((data.top ?? 0) / (data.stickerSent || 1) * 100).toFixed(2) }
            </td>
            <td>
                { data.last ?? 0 }
            </td>
            <td>
                { ((data.last ?? 0) / (data.stickerSent || 1) * 100).toFixed(2) }
            </td>
            <td>
                { data.favorites }
            </td>
            <td>
                { ((data.favorites ?? 0) / (data.stickerSent || 1) * 100).toFixed(2) }
            </td>
            <td>
                { data.myCreated }
            </td>
            <td>
                { ((data.myCreated ?? 0) / (data.stickerSent || 1) * 100).toFixed(2) }
            </td>
            <td>
                { data.popular }
            </td>
            <td>
                { ((data.popular ?? 0) / (data.stickerSent || 1) * 100).toFixed(2) }
            </td>
            <td>
                { data.channel }
            </td>
            <td>
                { ((data.channel ?? 0) / (data.stickerSent || 1) * 100).toFixed(2) }
            </td>
        </tr>
    );
};
