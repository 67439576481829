import {Column, ComponentColor, IconButton, Label, Panel, Row, TextArea, TextField} from '@components';
import { useModals } from '@hooks';
import React, { useState } from 'react';

import { IconCloseLine } from '@common/images/svg';
import s from './DeleteOrRestoreUserPopup.scss';
import { noop } from '@common';

interface DeleteOrRestoreUserPopupProps {
    userId: string;
    buttonCaption: string;
    onAcceptClick?: (userId: string) =>void;
}

export const DeleteOrRestoreUserPopup = (props: DeleteOrRestoreUserPopupProps) => {
    const { closeModal } = useModals();
    const {
        userId,
        buttonCaption,
        onAcceptClick = noop
    } = props;

    const [enteredId, setEnteredId] = useState('');

    const onButtonClick = () => {
        if (enteredId === userId) {
            onAcceptClick(userId);
            closeModal();
        }
    }

    return (
        <Panel className={s.rejectStickerPopup}>
            <Column className={s.rejectStickerPopupContent}>
                <Label caption={'Введите идентификатор пользователя'} className={s.rejectStickerPopupTopicLabel} />
                <Row>
                    <TextField
                        className={s.userId}
                        onChanged={(val) => setEnteredId(val?.toString() ?? '')}
                    />
                </Row>
                <Row className={s.footerRow}>
                    <IconButton
                        color={ComponentColor.RED}
                        disabled={enteredId !== userId}
                        caption={buttonCaption}
                        onClick={onButtonClick}
                    />
                    <IconButton
                        color={ComponentColor.ORANGE}
                        caption={'Отмена'}
                        onClick={closeModal}
                    />
                </Row>
            </Column>

            <div className={s.closeButton} onClick={closeModal}>
                <IconCloseLine/>
            </div>
        </Panel>
    )
}