import { AlertPlayer, Button, Column, Loader, Panel } from '@components';
import { useCategories, usePlayingAlertsList, useStreamerModeration } from '@hooks';
import { StickerDto, AlertSize, CategoryDto, IUser } from '@interfaces';
import config from 'config';
import React, { useState } from 'react';
import { AlertOnModeration } from './components';
import s from './StreamerModeration.scss';

export interface StreamerModerationProps {
    streamer?: IUser;
}

export const StreamerModeration = (props: StreamerModerationProps) => {

    const {
        streamer,
    } = props;

    const {
        currentPage,
        isLoadingPage,
        isFetchingPage,
        isEndReached,
        approveAlert,
        declineAlert,
        updateAlert,
        silentUpdateAlert,
        updateLoadedAlert,
        loadNextPage,
    } = useStreamerModeration(streamer);

    const {
        addAlertToCategory,
        removeAlertFromCategory,
    } = useCategories();

    const [ soundEnabled, setSoundEnabled ] = useState(false);

    const onSoundClick = () => {
        setSoundEnabled(prev => !prev);
    }

    const onCategorySelect = async (alert: StickerDto, category: CategoryDto) => {
        if (alert.categories?.includes(category.id)) {
            const updatedAlert = await removeAlertFromCategory({ stickerId: alert.id, categoryId: category.id }).unwrap();
            updateLoadedAlert(updatedAlert);
        } else {
            const updatedAlert = await addAlertToCategory({ stickerId: alert.id, categoryId: category.id }).unwrap();
            updateLoadedAlert(updatedAlert);
        }
    }

    const {
        list,
        addToList,
        removeFromList,
    } = usePlayingAlertsList({
        stickerSize: config?.design?.stickerSize?.[AlertSize.MEDIUM],
        availableAreaMask: 1,
    });

    const alertsElements = currentPage.map((a, idx) => (
        <AlertOnModeration
            key={a.id}
            alert={a}
            soundMuted={!soundEnabled}
            onApprove={approveAlert}
            onDecline={declineAlert}
            onUpdate={updateAlert}
            onSilentUpdate={silentUpdateAlert}
            onSoundClick={onSoundClick}
            onClick={addToList}
            onCategorySelect={onCategorySelect}
        />
    ));

    return (
        <Panel className={s.moderationContainer}>
            <Panel>
                <Column fullWidth>
                    {alertsElements}
                </Column>
                {!isEndReached && currentPage.length > 0 && !isLoadingPage && !isFetchingPage &&
                    <Button
                        caption={'Загрузить еще'}
                        className={s.loadMoreButton}
                        onClick={loadNextPage}
                    />
                }
                {(isLoadingPage || isFetchingPage) &&
                    <div className={s.loader}><Loader/></div>
                }
            </Panel>
            { list.length > 0 &&
                <Panel className={s.alertsShadow}>
                    {
                        list.map(a => {
                            return (
                                <AlertPlayer
                                    key={a.uid}
                                    alert={a.alert}
                                    geometry={a.geometry}
                                    size={AlertSize.MEDIUM}
                                    onPlayEnd={() => removeFromList(a.uid)}
                                />
                            );
                        })
                    }
                </Panel>
            }
        </Panel>
    );
};
