import { noop } from '@common';
import { useLogger } from '@hooks';
import { useEffect, useRef, useState } from 'react';

interface SliderParams {
    minValue: number;
    maxValue: number;
    initialValue?: number;
    updatePositionOnMouseUp?: boolean;
    realtimeUpdate?: boolean;
    onChange?: (value: number) => void;
    onCommit?: (value: number) => void;
}

export const useSlider = (params: SliderParams) => {
    const {
        initialValue = 0,
        realtimeUpdate = false,
        maxValue,
        minValue,
        onChange = noop,
        onCommit = noop,
    } = params;

    const { trace, info, warning } = useLogger({ target: 'Slider'});

    const [position, setPosition] = useState(initialValue);
    const currentPositionRef = useRef<number>(0);

    const range = maxValue - minValue;

    let mouseStartX = 0;
    let maxLeftShift = 0;
    let maxRightShift = 0;
    let pos = currentPositionRef.current;
    let deltaX = 0;

    const trackRef = useRef<HTMLDivElement>(null);
    const thumbRef = useRef<HTMLDivElement>(null);
    const gizmoRef = useRef<HTMLDivElement>(null);

    const updatePosition = (absolutePosition: number, updateState: boolean = false, isCommit = false) => {
        if (trackRef.current && thumbRef.current && gizmoRef.current) {
            const trackWidth = trackRef.current.clientWidth - gizmoRef.current.clientWidth;
            let rangedPosition = minValue + (range * absolutePosition / trackWidth);
            rangedPosition = rangedPosition - Math.round(rangedPosition) >= 0.5
                ? Math.ceil(rangedPosition)
                : Math.floor(rangedPosition);

            onChange(rangedPosition);
            if (updateState || realtimeUpdate) {
                setPosition(rangedPosition);
            }
            if (isCommit) {
                onCommit(rangedPosition);
            }
        }
    }

    const onThumbMouseDown = (event: MouseEvent) => {
        event.stopPropagation();
        if (trackRef.current && thumbRef.current && gizmoRef.current) {
            document.addEventListener('mouseup', onThumbMouseUp);
            document.addEventListener('mousemove', onMouseMove);
            const trackWidth = trackRef.current.clientWidth - gizmoRef.current.clientWidth;
            mouseStartX = event.pageX;
            pos = currentPositionRef.current;
            maxLeftShift = -currentPositionRef.current;
            maxRightShift = trackWidth - currentPositionRef.current;
        }
    }

    const onThumbMouseUp = (event: MouseEvent) => {
        event.stopPropagation();
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onThumbMouseUp);
        mouseStartX = 0;
        maxLeftShift = 0;
        maxRightShift = 0;
        currentPositionRef.current = pos + deltaX;
        updatePosition(pos + deltaX, true, true);
    }

    const onMouseMove = (event: MouseEvent) => {
        if (trackRef.current && thumbRef.current && gizmoRef.current) {
            const trackWidth = trackRef.current.clientWidth - gizmoRef.current.clientWidth;
            const thumbBorder = (thumbRef.current.clientWidth - gizmoRef.current.clientWidth) / 2;
            deltaX = event.pageX - mouseStartX;
            if (deltaX < maxLeftShift) {
                deltaX = maxLeftShift;
            }
            if (deltaX > maxRightShift) {
                deltaX = maxRightShift;
            }

            if (trackWidth && thumbRef.current) {
                updatePosition(pos + deltaX);
                thumbRef.current.style.left = `${currentPositionRef.current - thumbBorder + deltaX}px`
            }
        }
    }

    const onTrackMouseDown = (event: MouseEvent) => {
        console.log('Test')
        let clickPos = event.offsetX;
        if (trackRef.current && thumbRef.current && gizmoRef.current) {
            const trackWidth = trackRef.current.clientWidth - gizmoRef.current.clientWidth;
            const thumbBorder = (thumbRef.current.clientWidth - gizmoRef.current.clientWidth) / 2;
            if (clickPos < gizmoRef.current.clientWidth / 2) {
                clickPos = gizmoRef.current.clientWidth / 2;
            }
            if (clickPos > trackWidth + gizmoRef.current.clientWidth / 2) {
                clickPos = trackWidth + gizmoRef.current.clientWidth / 2;
            }
            let realPos = clickPos - thumbRef.current.clientWidth / 2;
            currentPositionRef.current = realPos;
            updatePosition(realPos + thumbBorder, true, true);
            thumbRef.current.style.left = `${realPos}px`;
        }
    }

    useEffect(() => {
        if (trackRef.current && thumbRef.current && gizmoRef.current) {
            const trackWidth = trackRef.current.clientWidth - gizmoRef.current.clientWidth;
            const thumbBorder = (thumbRef.current.clientWidth - gizmoRef.current.clientWidth) / 2;
            const perc = (initialValue - minValue) / (maxValue - minValue);
            const initialPosition = trackWidth * perc;
            thumbRef.current.style.left = `${initialPosition - thumbBorder}px`;
            currentPositionRef.current = initialPosition;
            updatePosition(initialPosition, true);
            trace(`Recalc initial: Track width${trackWidth}, Perc: ${perc}, Min: ${minValue}, Max: ${maxValue}, Init pos: ${initialPosition}`);
        }
    }, [initialValue])

    useEffect(() => {
        thumbRef.current?.addEventListener('mousedown', onThumbMouseDown);
        trackRef.current?.addEventListener('mousedown', onTrackMouseDown);

        if (trackRef.current && thumbRef.current && gizmoRef.current) {
            const trackWidth = trackRef.current.clientWidth - gizmoRef.current.clientWidth;
            const thumbBorder = (thumbRef.current.clientWidth - gizmoRef.current.clientWidth) / 2;
            const perc = (initialValue - minValue) / (maxValue - minValue);
            const initialPosition = trackWidth * perc;
            thumbRef.current.style.left = `${initialPosition - thumbBorder}px`;
            currentPositionRef.current = initialPosition;
            updatePosition(initialPosition, true);
            trace(`Recalc initial: Track width${trackWidth}, Perc: ${perc}, Min: ${minValue}, Max: ${maxValue}, Init pos: ${initialPosition}`);
        }

        return () => {
            thumbRef.current?.removeEventListener('mousedown', onThumbMouseDown);
            trackRef.current?.removeEventListener('mousedown', onTrackMouseDown);
        }
    }, [])

    return {
        trackRef,
        thumbRef,
        gizmoRef,
        position,
    }
}