import { IUser, StreamerEventsStickersDataItem, StreamerEventsStickersData } from '@interfaces';
import { api } from '@store';
import { useEffect, useState } from 'react';

export const useStreamerEventsStickers = (streamer?: IUser) => {
    const {
        stats: {
            useLazyGetStreamerEventsStickersPageQuery,
        }
    } = api;

    const [page, setPage] = useState(1);
    const [currentStats, setCurrentStats] = useState<StreamerEventsStickersDataItem[]>([]);
    const [lastData, setLastData] = useState<StreamerEventsStickersData>();
    const [isEndReached, setIsEndReached] = useState(false);
    const [displayErrors, setDisplayErrors] = useState(false);

    const [
        loadPage,
        {
            data: loadedPage,
            isLoading: isLoadingPage,
            isSuccess,
            isFetching: isFetchingPage,
        },
    ] = useLazyGetStreamerEventsStickersPageQuery();

    const load = () => {
        if (!!streamer) {
            loadPage({ page: 1, streamerId: streamer?.id });
            setDisplayErrors(false);
        } else {
            setDisplayErrors(true);
            setCurrentStats([]);
        }
    }

    const loadNextPage = () => {
        setPage(page + 1);
    }

    useEffect(() => {
        setDisplayErrors(false);
        setIsEndReached(false);
        setLastData(undefined);
        setCurrentStats([]);
        setPage(1);
        load();
    }, [streamer]);

    useEffect(() => {
        if (streamer && page > 1) {
            loadPage({page, streamerId: streamer?.id});
        }
    }, [page]);

    useEffect(() => {
        if (loadedPage && !loadedPage.data.length) {
            setIsEndReached(true);
        } else {
            if (page === 1) {
                setCurrentStats(loadedPage?.data ?? []);
            } else {
                setCurrentStats((prev) => [...prev].concat(loadedPage?.data ?? []));
            }
            setLastData(loadedPage);
        }
    }, [loadedPage]);

    return {
        lastData,
        currentStats,
        isLoadingPage,
        isFetchingPage,
        isEndReached,
        isSuccess,
        displayErrors,

        loadNextPage,
        load,
    };
};
