import React from 'react'

import cn from 'classnames';

import config from 'config';

import s from './Panel.scss';

interface PanelProps {
    className?: string;
    useFixedWidth?: boolean;
}

export const Panel = (props: React.PropsWithChildren<PanelProps>) => {
    const { children, className, useFixedWidth } = props;

    let styles: Record<string, unknown> = {};

    if (useFixedWidth) {
        styles.width = config?.design?.fixedWidth ?? 'auto';
    }

    return (
        <div
            className={cn(s.panel, className)}
            style={styles}
        >
            {children}
        </div>
    )
}