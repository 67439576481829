import {
    Admin,
    AuthRedirect,
    Moderation,
    SearchQueriesStats,
    SearchStats,
    SendAlertsIntervalStats,
    SendedAlertStats,
    Stats,
    StreamerAlertsStats,
    StreamerEvents,
    StreamerStickersStats,
    ThematicSets,
    TopStreamers,
    ViewerEvents
} from "@views";
import { CohortStats } from "@views/pages/Admin/components/CohortStats";
import { Moderator } from "@views/pages/Moderator";
import React from "react";
import { Navigate, RouteObject } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import {Login} from "@views/pages/Login";

export const Routing: RouteObject[] = [
    {
        path: "/",
        element: (
            <ProtectedRoute>
                <Moderator />
            </ProtectedRoute>
        ),
    },
    {
        path: "/login",
        element: (
            <Login />
        ),
    },
    {
        path: "/admin",
        element: (
            <ProtectedRoute>
                <Admin />
            </ProtectedRoute>
        ),
        children: [
            {
                path: "moderation",
                element: <Moderation />,
            },
            {
                path: "viewer-events",
                element: <ViewerEvents />,
            },
            {
                path: "streamer-events",
                element: <StreamerEvents />,
            },
            {
                path: "stats",
                element: <Stats />,
            },
            {
                path: "send-alerts-interval-stats",
                element: <SendAlertsIntervalStats />,
            },
            {
                path: "sended-alert-stats",
                element: <SendedAlertStats />,
            },
            {
                path: "search-stats",
                element: <SearchStats />,
            },
            {
                path: "search-queries",
                element: <SearchQueriesStats />,
            },
            {
                path: "cohort-stats",
                element: <CohortStats />,
            },
            {
                path: "top-streamers",
                element: <TopStreamers />,
            },
            {
                path: "streamer-alerts",
                element: <StreamerAlertsStats />,
            },
            {
                path: "streamer-stickers",
                element: <StreamerStickersStats />,
            },
            {
                path: "thematic-sets",
                element: <ThematicSets />,
            },
            {
                path: "",
                element: <Navigate to="moderation" replace />,
            },
        ],
    },
    {
        path: "/moder",
        element: (
            <ProtectedRoute>
                <Moderator />
            </ProtectedRoute>
        ),
        children: [
            {
                path: "moderation",
                element: <Moderation />,
            },
            {
                path: "viewer-events",
                element: <ViewerEvents />,
            },
            {
                path: "streamer-events",
                element: <StreamerEvents />,
            },
            {
                path: "thematic-sets",
                element: <ThematicSets />,
            },
            {
                path: "",
                element: <Navigate to="moderation" replace />,
            },
        ],
    },
    {
        path: "/auth/redirect",
        element: <AuthRedirect />,
    },
];
