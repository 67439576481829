import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useSlider } from './hooks/Slider';
import s from './Slider.scss';

interface SliderProps {
    caption?: string;
    className?: string;
    showPosition?: boolean;
    minValue: number;
    maxValue: number;
    initialValue?: number;
    realtimeUpdate?: boolean;
    disabled?: boolean;
    onChange?: (value: number) => void;
    onCommit?: (value: number) => void;
}

export const Slider = (props: SliderProps) => {
    const {
        caption,
        className,
        disabled,
        showPosition = false,
    } = props;

    const {
        trackRef,
        thumbRef,
        gizmoRef,
        position,
    } = useSlider(props);

    const [ style, setStyle ] = useState({});

    const calcStyle = () => {
        if (thumbRef.current) {
            setStyle({
                left: `${thumbRef.current.offsetLeft + thumbRef.current.clientWidth / 2 ?? 0}px`,
            });
        }
    }

    useEffect(() => {
        calcStyle();
    }, [position]);

    return (
        <div className={cn(
            s.slider,
            className,
            { [s.sliderDisabled]: disabled }
        )}>
            {!!caption &&
                <div className={s.sliderCaption}>{`${caption}${showPosition ? ': ' + position + '%' : ''}`}</div>
            }
            <div className={s.sliderTrackContainer} ref={trackRef}>
                <div className={s.sliderTrack}>
                    <div className={s.sliderTrackProgress} style={style} />
                    <div className={s.sliderThumb} ref={thumbRef}>
                        <div className={s.sliderThumbGizmo} ref={gizmoRef}/>
                    </div>
                </div>
            </div>
        </div>
    )
}