import { StickerDto } from '@interfaces';

export const minIndex = (arr: Array<number>): number => {
    const minValue = Math.min(...arr);
    return arr.indexOf(minValue);
}

export const columnizeStickers = (stickers: Array<StickerDto>, columnsCount: number): Array<Array<StickerDto>> => {
    const columns: Array<Array<StickerDto>> = [];
    const columnsHeight: Array<number> = Array.from({ length: columnsCount }, () => 0);

    stickers.forEach((s, i) => {
        const minHeightIndex = minIndex(columnsHeight);

        if (!columns[minHeightIndex]) {
            columns[minHeightIndex] = [];
        }
        columns[minHeightIndex].push(s);
        columnsHeight[minHeightIndex] = columns[minHeightIndex].map(s => s.videoData?.height ?? 0).reduce((acc, curr) => acc + curr);
    });

    return columns;
}
