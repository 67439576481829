import { SendAlertsStatisticData } from '@interfaces';
import React from 'react';

// import s from './StatisticRecord.scss';
// import cn from 'classnames';

interface StatisticRecordProps {
    data: SendAlertsStatisticData;
}

export const StatisticRecord = (props: StatisticRecordProps) => {
    const {
        data,
    } = props;

    return (
        <tr>
            <td>
                { data.name }
            </td>
            <td>
                { data.sent }
            </td>
            <td>
                { data.sendersCount }
            </td>
            <td>
                { data.streamersCount }
            </td>
        </tr>
    );
};
