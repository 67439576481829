import { useCategories } from '@hooks';
import React from 'react';
import cn from 'classnames';
import s from './CategoriesPanel.scss';
import { CategoryDto } from '@interfaces';

interface CategoriesPanelProps {
    activeCategories?: string[];
    onCategoryClick?: (category: CategoryDto) => void;
}

export const CategoriesPanel = (props: CategoriesPanelProps) => {
    const {
        activeCategories = [],
        onCategoryClick,
    } = props;

    const {
        categories,
    } = useCategories();

    const buttons = categories?.map((c, idx) => (
        <div
            className={cn(
                s.category,
                { [s.categoryActive]: activeCategories.includes(c.id)}
            )}
            key={idx}
            onClick={() => onCategoryClick && onCategoryClick(c)}
        >
            {c.name}
        </div>
    ));

    return (
        <div className={s.categoriesPanel}>
            {buttons}
        </div>
    );
}
