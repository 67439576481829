import { Loader, Panel } from '@components'
import { useTopStreamers } from '@hooks';
import React from 'react'

import cn from 'classnames';

import s from './TopStreamers.scss';

export const TopStreamers = () => {
    const {
        stats,
        isTopStreamersLoading,
    } = useTopStreamers();

    const items = stats?.topStreamers.map((streamer, idx) => {
        const revenue = streamer.activity?.spentTotal ?? 0;
        const lifeTime = ((Date.now() - new Date(streamer.registeredAt).getTime()) / (24 * 60 * 60 * 1000)).toFixed();
        const lastActiveDays = streamer.lastActive ? parseInt(((Date.now() - new Date(streamer.lastActive.date).getTime()) / (24 * 60 * 60 * 1000)).toFixed()) : 0;
        const activeDays = streamer.activity?.days ?? 0;
        
        return (
            <tr key={idx}>
                <td className={cn({[s.topStreamersContainerGreenTd]: revenue > 0})}>{streamer.name}</td>
                <td>{streamer.donationAlertsUsername}</td>
                <td>{streamer.channelAlertsCount}</td>
                <td>{streamer.onlyChannelStickers ? '+' : ''}</td>
                <td>{streamer.twitchFriendly ? '+' : ''}</td>
                <td>{streamer.familyFriendly ? '+' : ''}</td>
                <td>{streamer.memePartyActive ? '+' : ''}</td>
                <td>{new Date(streamer.registeredAt).toLocaleString('ru-RU', { day: '2-digit', month: '2-digit', year: '2-digit'})}</td>
                <td>{streamer.lastVisit ? new Date(streamer.lastVisit).toLocaleString('ru-RU', { day: '2-digit', month: '2-digit', year: '2-digit'}).replace(',', '') : '-'}</td>
                <td className={cn({
                    [s.topStreamersContainerActive]: streamer.lastActive && (lastActiveDays === 0),
                    [s.topStreamersContainerLowActive]: (revenue > 0 && lastActiveDays > 3),
                    [s.topStreamersContainerNotActive]: (revenue > 0 && lastActiveDays > 7),
                })
                    }>
                    {streamer.lastActive ? new Date(streamer.lastActive.date).toLocaleString('ru-RU', { day: '2-digit', month: '2-digit', year: '2-digit'}) : '-'}
                </td>
                <td>{lifeTime}</td>
                <td>{activeDays}</td>
                <td>{streamer.alertPrice}</td>
                <td>{streamer.currencyName ?? 'Мемкоин'}</td>
                <td>{streamer.payers ?? 0}</td>
                <td>{revenue}</td>
            </tr>
        )
    });

    return (
        <Panel className={s.topStreamersContainer}>
            {
                isTopStreamersLoading
                    ? <Loader/>
                    : <>
                        <div className={s.topStreamersContainerTotal}>
                            <div>Streamers Total: {stats?.total.streamersTotal}</div>
                            <div>Streamers Activated: {stats?.total.streamersActivated}</div>
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <td>Name</td>
                                    <td>DA</td>
                                    <td>ChS</td>
                                    <td>CO</td>
                                    <td>TF</td>
                                    <td>FF</td>
                                    <td>MP</td>
                                    <td>RegisteredAt</td>
                                    <td>LastVisit</td>
                                    <td>LastActive</td>
                                    <td>LifeTime</td>
                                    <td>ActiveDays</td>
                                    <td>AlertPrice</td>
                                    <td>CurrencyName</td>
                                    <td>Payers</td>
                                    <td>Revenue</td>
                                </tr>
                            </thead>
                            <tbody>
                                {items}
                            </tbody>
                        </table>
                    </>
            }
        </Panel>
    )
}