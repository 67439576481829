import { configureStore } from '@reduxjs/toolkit';
import { memeAlertsAuthApi } from './meme-alerts/meme-alerts-auth.api';
import { memeAlertsBaseApi } from './meme-alerts/meme-alerts-base.api';
import { memeAlertsUserApi } from './meme-alerts/meme-alerts-user.api';
import { memeAlertsAlertApi } from './meme-alerts/meme-alerts-alert.api';
import { memeAlertsStatsApi } from './meme-alerts/meme-alerts.stats.api';
import { memeAlertsCategoriesApi } from './meme-alerts/meme-alerts-category.api';
import { stickersModerationPagesApi } from './meme-alerts/stickers-moderation-pages.api';
import { stickersExternalModerationApi } from './meme-alerts/stickers-external-moderation.api';
import { stickersMemesIndexApi } from './meme-alerts/stickers-memes-index.api';

export const api = {
    ...memeAlertsBaseApi,
    auth: {
        ...memeAlertsAuthApi,
    },
    user: {
        ...memeAlertsUserApi,
    },
    alert: {
        ...memeAlertsAlertApi,
    },
    stats: {
        ...memeAlertsStatsApi,
    },
    categories: {
        ...memeAlertsCategoriesApi,
    },
    moderation: {
        ...stickersModerationPagesApi,
    },
    externalModeration: {
        ...stickersExternalModerationApi,
    },
    memesIndex: {
        ...stickersMemesIndexApi,
    },
};

export const store = configureStore({
    reducer: {
        [memeAlertsBaseApi.reducerPath]: memeAlertsBaseApi.reducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false,
    }).concat(memeAlertsBaseApi.middleware)
});
