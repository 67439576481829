import { StreamerStickersStatsData, StreamerStickersStatsDataItem } from '@interfaces';
import { api } from '@store';
import { useEffect, useState } from 'react';

export const useStreamerStickersStats = () => {
    const {
        stats: {
            useLazyGetStreamerStickersStatsPageQuery,
        }
    } = api;

    const [page, setPage] = useState(1);
    const [streamerName, setStreamerName] = useState<string>();
    const [currentStats, setCurrentStats] = useState<StreamerStickersStatsDataItem[]>([]);
    const [lastData, setLastData] = useState<StreamerStickersStatsData>();
    const [isEndReached, setIsEndReached] = useState(false);
    const [coefA, setCoefA] = useState(1);
    const [coefB, setCoefB] = useState(1);
    const [displayErrors, setDisplayErrors] = useState(false);

    const [
        loadPage,
        {
            data: loadedPage,
            isLoading: isLoadingPage,
            isSuccess,
            isFetching: isFetchingPage,
        }
    ] = useLazyGetStreamerStickersStatsPageQuery();

    const startCalculation = () => {
        if (!!streamerName && !!coefA && !!coefB) {
            loadPage({ page, streamerName, commonPopularityWeight: coefA, streamerPopularityWeight: coefB });
            setDisplayErrors(false);
        } else {
            setDisplayErrors(true);
        }
    }

    const loadNextPage = () => {
        setPage(page + 1);
    }

    useEffect(() => {
        setDisplayErrors(false);
        setIsEndReached(false);
        setLastData(undefined);
        setCurrentStats([]);
        setPage(1);
    }, [streamerName]);

    useEffect(() => {
        setIsEndReached(false);
        setLastData(undefined);
        setCurrentStats([]);
        setPage(1);
    }, [coefA, coefB]);

    useEffect(() => {
        if (streamerName && page > 1) {
            loadPage({page, streamerName, commonPopularityWeight: coefA, streamerPopularityWeight: coefB });
        }
    }, [page]);

    useEffect(() => {
        if (loadedPage && !loadedPage.data.length) {
            if (loadedPage.streamer) {
                setIsEndReached(true);
            } else {
                setIsEndReached(false);
                setLastData(undefined);
                setCurrentStats([]);
                setDisplayErrors(true);
            }
        } else {
            setCurrentStats((prev) => [...prev].concat(loadedPage?.data ?? []));
            setLastData(loadedPage);
        }
    }, [loadedPage]);

    return {
        streamerName,
        coefA,
        coefB,
        lastData,
        currentStats,
        isLoadingPage,
        isFetchingPage,
        isEndReached,
        isSuccess,
        displayErrors,

        loadNextPage,
        setStreamerName,
        setCoefA,
        setCoefB,
        startCalculation,
    };
};
