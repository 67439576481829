import { Button, ComponentColor, IconButton, Input, InputColor, Loader, Panel, RoundedAvatar, RoundedAvatarSize, TextField } from '@components';
import { useStreamerStickersStats } from '@hooks';
import React from 'react';
import s from './StreamerStickersStats.scss';
import { StatisticRecord } from './components';

export const StreamerStickersStats = () => {
    const {
        streamerName,
        coefA,
        coefB,
        lastData,
        currentStats,
        isLoadingPage,
        isFetchingPage,
        isEndReached,
        isSuccess,
        displayErrors,
        loadNextPage,
        setStreamerName,
        setCoefA,
        setCoefB,
        startCalculation,
    } = useStreamerStickersStats();

    const rows = currentStats?.map((r, idx) => (
        <StatisticRecord data={lastData} item={r} key={idx}/>
    ));

    return (
        <Panel className={s.statsContainer}>
            <div className={s.streamerName}>
                <Input
                    placeholder='Имя стримера'
                    className={s.streamerNameInput}
                    color={InputColor.ORANGE}
                    errorMessage={!streamerName ? 'Введите имя пользователя' : 'Пользователь с таким именем не найден'}
                    hasError={
                        (!isSuccess && displayErrors && !streamerName) ||
                        (displayErrors && isSuccess && !isLoadingPage && !isFetchingPage && !!streamerName && !lastData?.streamer)}
                    onChange={(value) => setStreamerName(value?.toString())}
                />
                {<RoundedAvatar source={lastData?.streamer?.channel?.avatarUrl} size={RoundedAvatarSize.MEDIUM}/>}
                <TextField
                    placeholder={'Коэф. A'}
                    invalidSymbols={/[^0-9\.]/}
                    onChanged={(value) => setCoefA(parseFloat(value))}
                />
                <TextField
                    placeholder={'Коэф. B'}
                    invalidSymbols={/[^0-9\.]/}
                    onChanged={(value) => setCoefB(parseFloat(value))}
                />
                <IconButton
                    caption={'Запустить расчет'}
                    color={ComponentColor.ORANGE}
                    onClick={startCalculation}
                    // disabled={!streamerName || !coefA || !coefB}
                />
            </div>
            {currentStats.length > 0 &&
                <table>
                    <thead>
                        <tr>
                            <td>
                                { 'Name' }
                            </td>
                            <td>
                                { 'Sent' }
                            </td>
                            <td>
                                { 'Sent μ' }
                            </td>
                            <td>
                                { 'Sent σ' }
                            </td>
                            <td>
                                { 'PopTotal' }
                            </td>
                            <td>
                                { 'Streamer' }
                            </td>
                            <td>
                                { 'Streamer μ' }
                            </td>
                            <td>
                                { 'Streamer σ' }
                            </td>
                            <td>
                                { 'PopStreamer' }
                            </td>
                            <td>
                                { 'Rank' }
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        { rows }
                    </tbody>
                </table>
            }

            {!isEndReached && currentStats.length > 0 && !isLoadingPage && !isFetchingPage &&
                <Button
                    caption={'Загрузить еще'}
                    className={s.loadMoreButton}
                    onClick={loadNextPage}
                />
            }
            {(isLoadingPage || isFetchingPage) &&
                <div className={s.loader}><Loader/></div>
            }
        </Panel>
    );
};