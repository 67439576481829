import { noop } from '@common';
import { ComponentColor, Loader, RoundedAvatar, RoundedAvatarSize } from '@components';
import cn from 'classnames';
import React, { useRef } from 'react';
import s from './GameSelect.scss';
import { useGameSelect } from './hooks/GameSelect';
import { IconCloseLine } from '@common/images/svg';
import { IGame } from '@interfaces';

export interface GameSelectMethods {
    reset: () => void;
    focus: () => void;
    set: (value: string) => void;
}

export interface GameSelectDefaultValue {
    id?: string;
    coverThumbId?: string;
    name?: string;
}

interface GameSelectProps {
    className?: string;
    color?: ComponentColor;
    debounceTime?: number;
    defaultValue?: GameSelectDefaultValue;
    methodsRef?: React.MutableRefObject<GameSelectMethods | null>;
    invalidSymbols?: RegExp;
    maxLength?: number;
    maxWidth?: number;
    minWidth?: number;
    fullWidth?: boolean;
    placeholder?: string;
    readonly?: boolean;
    useInactiveShadow?: boolean;
    onSelect?: (game?: IGame) => void;
}

export const GameSelect = (props: GameSelectProps) => {
    const {
        className,
        color = ComponentColor.DEFAULT,
        methodsRef,
        maxWidth,
        minWidth,
        fullWidth,
        placeholder,
        useInactiveShadow,
        onSelect = noop,
    } = props;

    const inputRef = useRef<HTMLInputElement>(null);
    const {
        isLoadInProgress,
        searchResults,
        selectedGame,
        errorUrls,
        value,
        onChange,
        setSelectedGame,
        set,
        reset,
    } = useGameSelect({...props});

    const styles = {
        minWidth,
        maxWidth,
    }

    const focus = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }

    const onGameClick = (game: IGame) => {
        setTimeout(() => {
            setSelectedGame(game);
            onSelect(game);
        }, 0);
    }

    const textFieldClassNames = cn(
        s.textField,
        className,
        s['color' + color],
        {
            [s.textFieldInactiveShadow]: useInactiveShadow,
            [s.textFieldFullWidth]: fullWidth,
        }
    );

    if (methodsRef) {
        methodsRef.current = {
            reset,
            focus,
            set,
        }
    }

    const getGameItem = (game: IGame, onSelect?: (game: IGame) => void) => {
        return (
            <div 
                key={game._id}
                onMouseDown={() => onSelect ? onSelect(game) : noop}
                className={cn(s.gameItem, {[s.gameItemSelectable]: !!onSelect})}>
                <div className={s.gameItemIcon}>
                    <RoundedAvatar
                        className={s.gameItemIconAvatar}
                        source={`https://images.igdb.com/igdb/image/upload/t_thumb/${game.coverThumbId}.jpg`}
                        size={RoundedAvatarSize.EXTRA_SMALL}
                    />
                </div>
                <div className={s.gameItemText}>{game.name}</div>
            </div>
        );
    }

    return (
        <div className={textFieldClassNames} style={styles} onBlur={() => reset()}>
            <div className={s.textFieldInputContainer}>
                <input
                    className={cn(s.textFieldInput, {
                        [s.textFieldInputInvisible]: !!selectedGame,
                    })}
                    placeholder={placeholder}
                    ref={inputRef}
                    value={value}
                    onChange={onChange}
                />
                { selectedGame && 
                    <div className={s.textFieldSelectedGame}>
                        { getGameItem(selectedGame) }
                    </div>
                }
            </div>

            <div className={s.textFieldActionsContainer}>
                <div className={s.textFieldAction}>
                    <Loader className={
                        cn(
                            s.loader, 
                            {
                                [s.loaderHidden]: !isLoadInProgress,
                            }
                        )}
                    />
                </div>
                <div
                    className={cn(
                        s.textFieldAction, 
                        {
                            [s.textFieldActionHidden]: !selectedGame,
                        }
                    )}
                    onClick={reset}>
                    <IconCloseLine/>
                </div>
            </div>

            {(searchResults && searchResults?.length > 0) &&
                <div className={s.textFieldDropdown}>
                    { searchResults?.map((res) => getGameItem(res, onGameClick)) }
                </div>
            }
        </div>
    )
}