import React from 'react'
import cn from 'classnames';
import s from './HeadedPage.scss';

interface HeadedPageProps {
    className?: string;
    contentClassName?: string;
    backgroundUrl?: string;
    backgroundMode?: number;
    header?: React.ReactNode;
    footer?: React.ReactNode;
}

export const HeadedPage = (props: React.PropsWithChildren<HeadedPageProps>) => {
    const { header, footer, children, className, contentClassName, backgroundUrl, backgroundMode } = props;

    const pageStyles = backgroundUrl ? {
        backgroundImage: `url(${backgroundUrl})`,
        backgroundPosition: backgroundMode ? '0 0' : 'center',
        backgroundSize: backgroundMode ? '' : 'cover',
    } : {};

    return (
        <div className={cn(s.headedPage, className)} style={pageStyles}>
            { !!header &&
                <div className={s.headedPageHeader}>
                    { header }
                </div>
            }
            <div className={cn(s.headedPageContent, contentClassName)}>
                { children }
            </div>
            { !!footer &&
                <div className={s.headedPageFooter}>
                    { footer }
                </div>
            }
        </div>
    )
}