import React from 'react';
import cn from 'classnames';

import s from './CircleButton.scss';

export enum CircleButtonColor {
    RED = 'red',
    GREEN = 'green',
    WHITE = 'white',
}

interface CircleButtonProps {
    className?: string;
    color?: CircleButtonColor;
    icon?: JSX.Element;
    isActive?: boolean;
    onClick: () => void;
}

export const CircleButton = (props: CircleButtonProps) => {
    const {
        className,
        color = CircleButtonColor.WHITE,
        icon,
        isActive,
        onClick,
    } = props;

    const onClickHandler = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        onClick && onClick();
    }

    return (
        <button
            type='button'
            className={cn(
                s.circleButton,
                className,
                s[color],
                {
                    [s.clickable]: !!onClick,
                    [s[`${color}Active`]]: isActive,
                }
            )}
            onClick={(e) => onClickHandler(e)}
        >
            <div className={s.circleButtonIcon}>
                {icon}
            </div>
        </button>
    )
}