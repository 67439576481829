import { api } from '@store';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export type ModeratorHookReturnType = ReturnType<typeof useModerator>;

export const useModerator = () => {
    const {
        user: {
            useCurrentUserQuery,
            useGetCurrentModerationShiftQuery,
            useOpenModerationShiftMutation,
            useCloseModerationShiftMutation,
        },
    } = api;

    const navigate = useNavigate();
    const {
        data: currentUser,
        isFetching: isCurrentUserFetching
    } = useCurrentUserQuery();

    const {
        data: currentModerationShift,
        isFetching: isCurrentModerationShiftFetching
    } = useGetCurrentModerationShiftQuery();

    const [openModerationShift] = useOpenModerationShiftMutation();

    const [closeModerationShift] = useCloseModerationShiftMutation();

    const openShift = async () => {
        if (currentModerationShift?.isOpen) return;

        return await openModerationShift().unwrap()
    }

    const closeShift = async () => {
        if (!currentModerationShift?.isOpen) return;

        return await closeModerationShift().unwrap()
    }

    const toggleShift = async () => {
        return currentModerationShift?.isOpen ? await closeShift() : await openShift();
    }

    useEffect(() => {
        if (currentUser && (!currentUser?.flags?.isAdmin && !currentUser?.flags?.isModer && !currentUser?.flags?.isMasterModer)) {
            navigate(-1);
        }
    }, [currentUser]);

    return {
        currentUser,
        currentModerationShift,
        isCurrentUserFetching,
        isCurrentModerationShiftFetching,

        openShift,
        closeShift,
        toggleShift,
    };
};
