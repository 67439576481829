import { ITag } from './ITag';

export interface ThematicSet {
    id: string;
    name: string;
    tags: ITag[];
    pictureUrl: string;
    beginDate: number;
    endDate: number;
    description: string;
}