import React from 'react';
import { StickerDto, AlertUpdateDto, CategoryDto, EEtmaRating, EModerationState, ITag, IUser } from '@interfaces';
import { Alert, AlertSizeCalculationBase, ColoredButton, ColoredButtonColor, Column, Input, InputValueType, Label, LabelSize, Row, StreamerSelect, Switch, SwitchColor, TagSelect, TextArea } from '@components';
import { CategoriesPanel } from '@views/fragments';
import cn from 'classnames';
import s from './AlertOnModeration.scss';
import { IconEtmaA, IconEtmaE, IconEtmaM, IconEtmaT, IconThumbDownLine, IconThumbUpLine } from '@common/images/svg';
import { noop } from '@common';

interface AlertOnModerationProps {
    alert: StickerDto;
    soundMuted?: boolean;
    onApprove?: (stickerId: string) => void;
    onDecline?: (stickerId: string) => void;
    onUpdate?: (alertData: AlertUpdateDto) => void;
    onSilentUpdate?: (alertData: AlertUpdateDto) => void;
    onSoundClick?: () => void;
    onClick?: (alert: StickerDto) => void;
    onCategorySelect?: (alert: StickerDto, category: CategoryDto) => void;
}

export const AlertOnModeration = (props: AlertOnModerationProps) => {
    const {
        alert,
        soundMuted,
        onApprove,
        onDecline,
        onUpdate,
        onSilentUpdate = noop,
        onSoundClick,
        onClick,
        onCategorySelect,
    } = props;

    const formatCreationTimestamp = (timestamp?: number) => {
        if (!timestamp) {
            return '';
        }
        const date = new Date(timestamp);
        return date.toLocaleDateString('ru-RU', {
            day: '2-digit',
            month: 'short',
            hour: '2-digit',
            minute: '2-digit',
        }).replace(/\./g, '');
    }

    const formatAuthor = (alert: StickerDto) => {
        return (
            <div>Автор: <span className={s.authorName}>{alert.creatorName ?? 'Неизвестен'}</span> <span className={s.approved}>{alert.creatorTotalApproved}</span> <span className={s.declined}>{alert.creatorTotalDeclined}</span></div>
        )
    }

    const formatModerator = (moderator: string = '') => {
        return (
            <div>Модератор: <span className={s.authorName}>{moderator ?? 'Неизвестен'}</span></div>
        )
    }

    const onCategoryClick = (category: CategoryDto) => {
        onCategorySelect && onCategorySelect(alert, category);
    }

    const onNameUpdate = (value: InputValueType) => {
        const newName = value?.toString() ?? "";
        if (newName !== alert.name) {
            onSilentUpdate({ id: alert.id, name: value?.toString() ?? '' });
        }
    }

    const onDescriptionUpdate = (value: InputValueType) => {
        const newDesc = value?.toString() ?? "";
        if (newDesc !== alert.description) {
            onSilentUpdate({ id: alert.id, description: value?.toString() ?? '' });
        }
    }

    const onStreamerUpdate = (streamer?: IUser) => {
        const newStreamerId = streamer?._id || null;
        if (newStreamerId !== alert.streamerId) {
            onSilentUpdate({ id: alert.id, streamerId: newStreamerId });
        }
    }

    const onTagsUpdate = (tags?: ITag[]) => {
        const newTags = tags || [];
        onSilentUpdate({ id: alert.id, tags: newTags });
    }

    const onNsfwUpdate = (value: boolean) => {
        if (alert.isAdult !== value) {
            onUpdate && onUpdate({ id: alert.id, isAdult: value });
        }
    }

    const onisNotTSUpdate = (value: boolean) => {
        if (alert.isNotTwitchSafe !== value) {
            onUpdate && onUpdate({ id: alert.id, isNotTwitchSafe: value });
        }
    }

    const onHasCopyrightUpdate = (value: boolean) => {
        if (alert.hasCopyright !== value) {
            onUpdate && onUpdate({ id: alert.id, hasCopyright: value });
        }
    }

    const onisOnTopUpdate = (value: boolean) => {
        if (alert.isOnTop !== value) {
            onUpdate && onUpdate({ id: alert.id, isOnTop: value });
        }
    }

    const onEtmaRatingUpdate = (value: EEtmaRating) => {
        if (value >= 0 && alert.etmaRating !== value) {
            onUpdate && onUpdate({ id: alert.id, etmaRating: value });
        }
    }

    const onApproveAlert = () => {
        if (alert.etmaRating !== undefined && alert.etmaRating !== null) {
            onApprove && onApprove(alert.id);
        }
    }

    const defaultStreamerValue = alert.streamerId ? {id: alert.streamerId, avatarUrl: alert.streamerAvatar, name: alert.streamerName} : undefined;

    return (
        <Row className={s.alertOnModeration}>
            <Column className={s.alertContainer}>
                <Alert
                    alert={alert}
                    hideName
                    muteSound={soundMuted}
                    playable={!!alert.stickerUrl}
                    unmuteOnHover={true}
                    sizeCalculationBase={AlertSizeCalculationBase.WIDTH}
                    hideModerationStatus={true}
                    onSoundClick={onSoundClick}
                    onClick={onClick}
                />
            </Column>
            <Column className={s.alertForm}>
                <Row className={s.authorAndDate}>
                    {formatAuthor(alert)}
                    {formatModerator(alert.moderatorName)}
                    <Label className={s.date} caption={formatCreationTimestamp(alert.createdAt)} size={LabelSize.SMALL} />
                </Row>
                <Row>
                    <Input debounceTime={500} defaultValue={alert.name} fullWidth onChange={(value) => onNameUpdate(value)} />
                </Row>
                <Row>
                    <TextArea debounceTime={500} maxRows={3} placeholder={'Описание, ключевые слова'} defaultValue={alert.description} onChange={(value) => onDescriptionUpdate(value)} />
                </Row>
                <Row>
                    <StreamerSelect 
                        debounceTime={500}
                        fullWidth
                        placeholder={'Никнейм стримера (если стикер о нём)'}
                        defaultValue={defaultStreamerValue}
                        onSelect={(streamer) => onStreamerUpdate(streamer)} />
                </Row>
                <Row>
                    <TagSelect
                        fullWidth
                        placeholder={'Добавить тег'}
                        debounceTime={500}
                        maxLength={20}
                        defaultValue={alert.tags}
                        onTagsChanged={onTagsUpdate}
                    />
                </Row>
                <Row>
                    <CategoriesPanel activeCategories={alert.categories} onCategoryClick={onCategoryClick} />
                </Row>
                <Row>
                    <div className={s.etma}>
                        <div className={cn(s.etmaButton, {[s.selectedE]: alert.etmaRating === EEtmaRating.E})} onClick={() => onEtmaRatingUpdate(EEtmaRating.E)}>
                            <IconEtmaE/>
                        </div>
                        <div className={cn(s.etmaButton, {[s.selectedT]: alert.etmaRating === EEtmaRating.T})} onClick={() => onEtmaRatingUpdate(EEtmaRating.T)}>
                            <IconEtmaT/>
                        </div>
                        <div className={cn(s.etmaButton, {[s.selectedM]: alert.etmaRating === EEtmaRating.M})} onClick={() => onEtmaRatingUpdate(EEtmaRating.M)}>
                            <IconEtmaM/>
                        </div>
                        <div className={cn(s.etmaButton, {[s.selectedA]: alert.etmaRating === EEtmaRating.A})} onClick={() => onEtmaRatingUpdate(EEtmaRating.A)}>
                            <IconEtmaA/>
                        </div>
                    </div>
                </Row>
                <Row className={s.buttons}>
                    <ColoredButton
                        caption={<IconThumbDownLine/>}
                        className={s.button}
                        color={ColoredButtonColor.RED}
                        disabled={alert.moderationState === EModerationState.DECLINED}
                        onClick={() => onDecline && onDecline(alert.id)}
                    />
                    <div className={s.expander}/>
                    <Switch
                        className={s.switch}
                        checked={alert.isOnTop}
                        color={SwitchColor.GREEN}
                        caption={'Top'}
                        onChange={(value) => onisOnTopUpdate(value)}
                    />
                    <Switch
                        className={s.switch}
                        checked={alert.isAdult}
                        color={SwitchColor.RED}
                        caption={'NSFW'}
                        onChange={(value) => onNsfwUpdate(value)}
                    />
                    <Switch
                        className={s.switch}
                        checked={alert.isNotTwitchSafe}
                        color={SwitchColor.PURPLE}
                        caption={'Twitch Unsafe'}
                        onChange={(value) => onisNotTSUpdate(value)}
                    />
                    <Switch
                        className={s.switch}
                        checked={alert.hasCopyright}
                        color={SwitchColor.PURPLE}
                        caption={'Copyright'}
                        onChange={(value) => onHasCopyrightUpdate(value)}
                    />
                    <div className={s.expander}/>
                    <ColoredButton
                        caption={<IconThumbUpLine/>}
                        className={s.button}
                        color={ColoredButtonColor.GREEN}
                        disabled={(alert.moderationState === EModerationState.APPROVED || (alert.etmaRating === undefined || alert.etmaRating === null))}
                        onClick={() => onApproveAlert()}
                    />
                </Row>
            </Column>
        </Row>
    );
};
