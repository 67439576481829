export type MemePartySessionDto = {
    streamerId: string;
    enabled?: boolean;
    goal?: number;
    currentValue?: number;
    active?: boolean;
    startedAt?: number;
    willEndAt?: number;
}

export type MemePartySessionFetchParams = {
    streamerId?: string;
}
