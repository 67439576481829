import React from 'react'

import cn from 'classnames';

import s from './Loader.scss';

interface LoaderProps {
    className?: string;
    containerClassName?: string;
}

export const Loader = (props: LoaderProps) => {
    return (
        <div className={cn(s.loaderContainer, props.containerClassName)}>
            <div className={cn(s.loader, props.className)}>

            </div>
        </div>
    )
}