import { Logo } from '@components';
import { AuthButtons } from '@views/fragments';
import React from 'react'

import s from './Login.scss';

interface LoginProps {
    className?: string;
    returnUrl?: string;
}

export const Login = (props: LoginProps) => {
    const {
        className,
        returnUrl,
    } = props;

    return (
        <div className={s.login}>
            <div className={s.logo}>
                <Logo />
            </div>
            <div className={s.text}>
                {'Войдите или\nзарегистрируйтесь'}
            </div>
            <AuthButtons className={className} returnUrl={returnUrl} />
        </div>
    )
}
