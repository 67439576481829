import React, { createContext, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface AuthContextType {
    token: string;
    refreshToken: string;
    obsToken: string;
    authMode: EAuthMode;
    removeToken: () => void;
    updateToken: (accessToken?: string, refreshToken?: string) => void;
}

export enum EAuthMode {
    Default = 'Default',
    OBS = 'OBS',
}

export const AuthContext = createContext<AuthContextType>({ token: '', refreshToken: '', obsToken: '', authMode: EAuthMode.Default, removeToken: () => {}, updateToken: () => {} });

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [searchParams] = useSearchParams();
    const tokenRef = useRef('');
    const refreshTokenRef = useRef('');
    const obsTokenRef = useRef('');
    const authModeRef = useRef<EAuthMode>(EAuthMode.Default);
    const navigate = useNavigate();

    const qToken = searchParams.get('token');
    if (qToken) {
        localStorage.setItem('accessToken', qToken);
    }

    const refreshToken = searchParams.get('refreshToken');
    if (refreshToken) {
        localStorage.setItem('refreshToken', refreshToken);
        refreshTokenRef.current = refreshToken;
    }

    const obsToken = searchParams.get('obsToken');
    if (obsToken) {
        localStorage.setItem('obsToken', obsToken);
        obsTokenRef.current = obsToken;
    }

    if (!qToken && obsToken) {
        authModeRef.current = EAuthMode.OBS;
    }

    const lsToken = localStorage.getItem('accessToken') ?? '';

    if (lsToken) tokenRef.current = lsToken;

    const updateToken = (accessToken?: string, refreshToken?: string) => {
        if (accessToken) {
            localStorage.setItem('accessToken', accessToken);
            tokenRef.current = accessToken;
        }

        if (refreshToken) {
            localStorage.setItem('refreshToken', refreshToken);
            refreshTokenRef.current = refreshToken;
        }
    }

    const removeToken = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('obsToken');
        navigate(0);
    }

    const value = {
        token: tokenRef.current,
        refreshToken: refreshTokenRef.current,
        obsToken: obsTokenRef.current,
        authMode: authModeRef.current,
        removeToken,
        updateToken,
    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
