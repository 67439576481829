import React, { useState } from 'react';
import cn from 'classnames';

import s from './ColoredButton.scss';

export enum ColoredButtonColor {
    WHITE = 'white',
    ORANGE = 'orange',
    GREEN = 'green',
    RED = 'red',
}

interface ColoredButtonProps {
    className?: string;
    captionClassName?: string;
    caption: string | JSX.Element;
    color?: ColoredButtonColor;
    disabled?: boolean;
    onClick?: () => void;
    onHover?: () => void;
}

export const ColoredButton = (props: ColoredButtonProps) => {
    const {
        caption,
        color = ColoredButtonColor.ORANGE,
        className,
        captionClassName,
        disabled,
        onClick,
        onHover,
    } = props;

    const [pressed, setPressed] = useState(false);

    return(
        <button
            type='button'
            disabled={disabled}
            className={cn(
                s.button,
                s[color],
                className,
                {
                    [s.disabled]: disabled,
                    [s[color + 'Pressed']]: pressed,
                },
            )}
            onClick={onClick}
            onMouseEnter={onHover}
            onMouseDown={() => setPressed(true)}
            onMouseUp={() => setPressed(false)}
        >
            { typeof caption === 'string'
                ? caption
                : (<div className={captionClassName ? captionClassName : s.caption}>
                    {caption}
                </div>)
            }
        </button>
    );
};
