import { EModerationState } from '@interfaces';
import { EventType } from './enums';

export interface Event {
    _id: string;
    timestamp: number;
    kind?: EventType;
}

export interface AlertSentEvent extends Event {
    userId?: string;
    streamerName: string;
    streamerId: string;
    stickerId: string;
    stickerName: string;
    paidPrice: number;
    userAlias?: string;
    fullscreen?: boolean;
    isStickerDisabled?: boolean;
    message?: string;
}

export interface BuyCurrencyEvent extends Event {
    userId?: string;
    streamerName: string;
    streamerId: string;
    packValue: number;
    packPrice: number;
    packDescription: string;
    userName?: string;
    preventStreamerNotification?: boolean;
    firstPayment?: boolean;
}

export interface ModerationStatusEvent extends Event {
    creatorId?: string;
    moderatorId?: string;
    stickerId?: string;
    stickerName: string;
    status: EModerationState;
    reason?: string;
}
