import { Column, ComponentColor, IconButton, Label, Panel, Row, TextArea } from '@components';
import { useModals } from '@hooks';
import React, { useState } from 'react';

import { IconCloseLine } from '@common/images/svg';
import s from './RejectStickerPopup.scss';
import { noop } from '@common';

interface RejectStickerPopupProps {
    onDeclineClick?: (reason: string) =>void;
}

export const RejectStickerPopup = (props: RejectStickerPopupProps) => {
    const { closeModal } = useModals();
    const {
        onDeclineClick = noop
    } = props;

    const [rejectReason, setRejectReason] = useState('');

    const onSaveClick = () => {
        onDeclineClick(rejectReason);
        closeModal();
    }

    return (
        <Panel className={s.rejectStickerPopup}>
            <Column className={s.rejectStickerPopupContent}>
                <Label caption={'Причина отклонения'} className={s.rejectStickerPopupTopicLabel} />
                <Row>
                    <TextArea
                        maxRows={5}
                        onChange={(val) => setRejectReason(val?.toString() ?? '')}
                    />
                </Row>
                <Row className={s.footerRow}>
                    <IconButton
                        color={ComponentColor.RED}
                        caption={'Отклонить'}
                        onClick={onSaveClick}
                    />
                    <IconButton
                        color={ComponentColor.ORANGE}
                        caption={'Отмена'}
                        onClick={closeModal}
                    />
                </Row>
            </Column>

            <div className={s.closeButton} onClick={closeModal}>
                <IconCloseLine/>
            </div>
        </Panel>
    )
}