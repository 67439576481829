import { StickerDto } from '@interfaces';
import { useEffect, useState } from 'react';
import { useApi } from '@hooks';
import { ModerationMode } from '@typings';
import { PAGE_SIZE } from '@const';

interface StickersPagesParams {
    searchQuery: string;
    stickersType?: ModerationMode;
}

export const useStickersPages = (params: StickersPagesParams) => {
    const {
        searchQuery,
        stickersType = ModerationMode.UNMODERATED,
    } = params;

    const {
        alert: {
            useGetModerationStatsQuery,
        },
        moderation: {
            useLazyLoadPageModerationAreaQuery,
        }
    } = useApi();

    const {
        data: {
            declined: declinedCount,
            onModeration: onModerationCount,
            top: topCount,
            total: catalogCount,
        } = { declined: 0, onModeration: 0, total: 0, top: 0 },
        isSuccess: isModStatsLoaded,
        isLoading: isModStatsLoading,
    } = useGetModerationStatsQuery(searchQuery);

    const [
        loadPage,
        {
            data: loadedAlertsPage = [],
            isFetching: isStickersFetching,
        }
    ] = useLazyLoadPageModerationAreaQuery();

    const [ page, setPage ] = useState(1);
    const [ totalStickersCount, setTotalStickersCount ] = useState(0);
    const [ totalPagesCount, setTotalPagesCount ] = useState(1);
    const [ alerts, setAlerts ] = useState<Array<StickerDto>>([]);

    const changePage = (page: number) => {
        setPage(page);
        loadPage({ skip: PAGE_SIZE * (page - 1), pageSize: PAGE_SIZE, searchQuery, moderationMode: stickersType });
    }

    const reloadCurrentPage = () => {
        //setAlerts([]);
        loadPage({ skip: PAGE_SIZE * (page - 1), pageSize: PAGE_SIZE, searchQuery, moderationMode: stickersType });
    }

    const updateAlert = (alert: StickerDto) => {
        setAlerts([...alerts].map(a => a.id === alert.id ? {...a, ...alert } : a ));
    }

    useEffect(() => {
        setPage(1);
    }, [searchQuery, stickersType]);

    useEffect(() => {
        setPage(1);
        setTotalPagesCount(0);
    }, [stickersType]);

    useEffect(() => {
        loadPage({ skip: PAGE_SIZE * (page - 1), pageSize: PAGE_SIZE, searchQuery, moderationMode: stickersType });
    }, [searchQuery, stickersType]);


    useEffect(() => {
        if(!isStickersFetching) setAlerts(loadedAlertsPage);
    }, [loadedAlertsPage]);

    useEffect(() => {
        if (totalPagesCount < page) {
            setPage(totalPagesCount || 1);
        }
    }, [totalPagesCount]);

    useEffect(() => {
        if (isModStatsLoaded) {
            if (stickersType === ModerationMode.UNMODERATED) {
                setTotalStickersCount(onModerationCount);
                setTotalPagesCount(Math.ceil(onModerationCount / PAGE_SIZE));
            } else if (stickersType === ModerationMode.DECLINED) {
                setTotalStickersCount(declinedCount);
                setTotalPagesCount(Math.ceil(declinedCount / PAGE_SIZE));
            } else if (stickersType === ModerationMode.TOP) {
                setTotalStickersCount(topCount);
                setTotalPagesCount(Math.ceil(topCount / PAGE_SIZE));
            } else {
                setTotalStickersCount(catalogCount);
                setTotalPagesCount(Math.ceil(catalogCount / PAGE_SIZE));
            }
        }
    }, [declinedCount, onModerationCount, catalogCount, topCount, stickersType, searchQuery]);

    return {
        alerts,
        currentPage: page,
        totalPagesCount,
        totalStickersCount,
        isStickersFetching,
        changePage,
        updateAlert,
        reloadCurrentPage,
    }
}