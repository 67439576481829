import { SendAlertsStatisticData } from '@interfaces';
import { api } from '@store';
import { useEffect, useState } from 'react';

export const useSendAlertsIntervalStats = () => {
    const {
        stats: {
            useLazyGetCurrentSendAlertsStatsQuery,
        }
    } = api;

    const [page, setPage] = useState(1);
    const [days, setDays] = useState(30);
    const [currentStats, setCurrentStats] = useState<SendAlertsStatisticData[]>([]);
    const [isEndReached, setIsEndReached] = useState(false);

    const [
        loadPage,
        {
            data: loadedPage,
            isLoading: isLoadingPage,
        }
    ] = useLazyGetCurrentSendAlertsStatsQuery();

    const loadNextPage = () => {
        setPage(page + 1);
    }

    useEffect(() => {
        loadPage({page, days});
    }, []);

    useEffect(() => {
        loadPage({page, days});
    }, [page]);

    useEffect(() => {
        setCurrentStats([]);
        setIsEndReached(false);
        if (page != 1) {
            setPage(1);
        } else {
            loadPage({page, days});
        }
    }, [days]);

    useEffect(() => {
        if (loadedPage && !loadedPage.length) {
            setIsEndReached(true);
        }
        setCurrentStats((prev) => [...prev].concat(loadedPage ?? []));
    }, [loadedPage]);

    return {
        currentStats,
        days,
        isLoadingPage,
        isEndReached,

        loadNextPage,
        setDays,
    };
};
