import { Column, ComponentColor, GameSelect, IconButton, Label, Loader, Panel, Row, StreamerSelect, Switch, SwitchColor, TagSelect } from '@components';
import { useModals } from '@hooks';
import { IGame, ITag, IUser, MassStickersUpdateDto } from '@interfaces';
import React, { createRef, useState } from 'react';

import { IconCloseLine } from '@common/images/svg';
import s from './MassTagPopup.scss';
import { noop } from '@common';

interface MassTagPopupProps {
    filter: string;
    stickersCount: number;
    massUpdateStickers?: (data: MassStickersUpdateDto) => Promise<number>;
}

export const MassTagPopup = (props: MassTagPopupProps) => {
    const { closeModal } = useModals();
    const {
        filter,
        stickersCount,
        massUpdateStickers = noop
    } = props;

    const approveLayerRef = createRef<HTMLDivElement>();

    const [ streamer, setStreamer ] = useState<IUser>();
    const [ game, setGame ] = useState<IGame>();
    const [ addedTags, setAddedTags] = useState<ITag[]>();
    const [ removedTags, setRemovedTags] = useState<ITag[]>();
    const [ isNotTwitchSafe, setIsNotTwitchSafe ] = useState<boolean>(false);

    const [isVisibleApproveLayer, setIsVisibleApproveLayer] = useState(false);
    const [isEditInProcess, setIsEditInProcess] = useState(false);

    const isValidValues = 
        (filter.length > 0 
            && (game 
                || streamer 
                || (addedTags && addedTags.length > 0) 
                || (removedTags && removedTags.length > 0) 
                || isNotTwitchSafe));

    const onApproveShadowLayerClick = (event: React.MouseEvent<HTMLElement>) => {
        if (event.target !== approveLayerRef.current) return;
        event.stopPropagation();

        setIsVisibleApproveLayer(false)
    }

    const onApproveCancelClick = async () => {
        setIsVisibleApproveLayer(false)
    }

    const onApproveClick = async () => {
        if (isValidValues) {
            try {
                setIsEditInProcess(true);
                    await massUpdateStickers({
                        query: filter,
                        streamerId: streamer?._id,
                        gameId: game?._id,
                        addedTags,
                        removedTags,
                        isNotTwitchSafe,
                    });
                closeModal();
            } catch {
                setIsEditInProcess(false);
            }
        }
    }

    return (
        <Panel className={s.massTagPopup}>
            { isVisibleApproveLayer &&
                <div className={s.approveLayer} ref={approveLayerRef} onClick={onApproveShadowLayerClick}>
                    <div className={s.content}>
                        <div className={s.question}>Подтвердите массовое редактирование</div>
                        <div className={s.textHint}>
                            {`Применить изменения к ${stickersCount} стикерам?`}
                        </div>
                        {isEditInProcess ?
                            <div className={s.loader}>
                                <Loader className={s.spinner}/>
                            </div>
                        :
                            <div className={s.buttons}>
                                <IconButton
                                        caption={'Да'}
                                        fullWidth
                                        onClick={onApproveClick}
                                        color={ComponentColor.RED}
                                    />
                                <IconButton
                                        caption={'Нет'}
                                        fullWidth
                                        onClick={onApproveCancelClick}
                                        color={ComponentColor.ORANGE}
                                    />
                            </div>
                        }
                    </div>
                </div>
            }
            <Column className={s.massTagPopupContent}>
                <Label caption={'Массовое редактирование'} className={s.massTagPopupTopicLabel} />
                <Row>
                    <StreamerSelect 
                        debounceTime={500}
                        fullWidth
                        placeholder={'Никнейм стримера (выбрать другого стримера)'}
                        onSelect={(streamer) => setStreamer(streamer)} 
                    />
                </Row>
                <Row>
                    <GameSelect
                        fullWidth
                        placeholder={'Игра (выбрать другую игру)'}
                        debounceTime={500}
                        maxLength={100}
                        onSelect={(game) => setGame(game)}
                    />
                </Row>
                <Row>
                    <TagSelect
                        fullWidth
                        placeholder={'Добавить тег'}
                        debounceTime={500}
                        maxLength={20}
                        onTagsChanged={setAddedTags}
                    />
                </Row>
                <Row>
                    <TagSelect
                        fullWidth
                        placeholder={'Удалить тег'}
                        debounceTime={500}
                        maxLength={20}
                        onTagsChanged={setRemovedTags}
                    />
                </Row>
                <Row>
                    <Switch
                        className={s.switch}
                        color={SwitchColor.PURPLE}
                        caption={'Twitch Unsafe'}
                        onChange={(value) => setIsNotTwitchSafe(value)}
                    />
                </Row>
                <Row className={s.footerRow}>
                    <IconButton
                        color={ComponentColor.ORANGE}
                        caption={'Сохранить'}
                        onClick={() => isValidValues && setIsVisibleApproveLayer(true)}
                        disabled={!isValidValues}
                    />
                </Row>
            </Column>

            <div className={s.closeButton} onClick={closeModal}>
                <IconCloseLine/>
            </div>
        </Panel>
    )
}