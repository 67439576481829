import { memeAlertsBaseApi } from './meme-alerts-base.api';

export const memeAlertsAuthApi = memeAlertsBaseApi.injectEndpoints({
    endpoints: (build) => ({
        auth: build.query<Record<string, string>, { username: string, password: string }>({
            query: (credentials: { username: string, password: string }) => ({
                url: 'auth/login',
                method: 'POST',
                body: credentials
            }),
        }),
        logout: build.mutation<void, void>({
            query: () => ({
                url: 'auth/logout',
                method: 'POST',
            }),
            invalidatesTags: ['CurrentUser']
        }),
        reissueObsToken: build.query<{ token: string }, void>({
            query: () => ({
                url: 'auth/obs',
                method: 'POST',
            }),
        }),
    }),
});

