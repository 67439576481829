// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StickersGrid__stickersGrid--zB9Bi {\n  display: flex;\n  flex-direction: row;\n  column-gap: 10px;\n  margin-top: 5px;\n}\n@media screen and (max-width: 960px) {\n  .StickersGrid__stickersGrid--zB9Bi {\n    column-gap: 4px;\n  }\n}\n.StickersGrid__stickersGridColumn--I2bWh {\n  min-width: 24%;\n  max-width: 24%;\n}\n@media screen and (max-width: 960px) {\n  .StickersGrid__stickersGridColumn--I2bWh {\n    min-width: 49.5%;\n    max-width: 49.5%;\n    row-gap: 3px;\n  }\n}\n.StickersGrid__stickersGrid--zB9Bi .StickersGrid__sticker--RCAcT video {\n  filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.15));\n}\n.StickersGrid__stickersGrid--zB9Bi .StickersGrid__stickerForAppleDevice--ZIi78 video {\n  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));\n}", "",{"version":3,"sources":["webpack://./src/views/fragments/StickersGrid/StickersGrid.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;AACJ;AACI;EANJ;IAOQ,eAAA;EAEN;AACF;AAAI;EACI,cAAA;EACA,cAAA;AAER;AAKQ;EATJ;IAUQ,gBAAA;IACA,gBAAA;IACA,YAAA;EAFV;AACF;AAMQ;EACI,qDAAA;AAJZ;AAQY;EACI,mDAAA;AANhB","sourcesContent":[".stickersGrid {\n    display: flex;\n    flex-direction: row;\n    column-gap: 10px;\n    margin-top: 5px;\n\n    @media screen and (max-width: 960px) {\n        column-gap: 4px;\n    }\n\n    &Column {\n        min-width: 24%;\n        max-width: 24%;\n\n        // @media screen and (max-width: 1280px) {\n        //     min-width: 32%;\n        //     max-width: 32%;\n        // }\n\n        @media screen and (max-width: 960px) {\n            min-width: 49.5%;\n            max-width: 49.5%;\n            row-gap: 3px;\n        }\n    }\n\n    .sticker {\n        video {\n            filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.15));\n        }\n\n        &ForAppleDevice {\n            video {\n                filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stickersGrid": "StickersGrid__stickersGrid--zB9Bi",
	"stickersGridColumn": "StickersGrid__stickersGridColumn--I2bWh",
	"sticker": "StickersGrid__sticker--RCAcT",
	"stickerForAppleDevice": "StickersGrid__stickerForAppleDevice--ZIi78"
};
export default ___CSS_LOADER_EXPORT___;
