import { Button, Loader } from '@components';
import { EventType, useStreamerEventsMoney } from '@hooks';
import React from 'react';
import s from './MoneyTable.scss';
import cn from 'classnames';
import { IUser } from '@interfaces';

export interface MoneyTableProps {
    streamer?: IUser;
}

export const MoneyTable = (props: MoneyTableProps) => {
    const {
        streamer,
    } = props;

    const {
        currentStats,
        isLoadingPage,
        isFetchingPage,
        isEndReached,
        loadNextPage,
    } = useStreamerEventsMoney(streamer);

    const formatTimestamp = (timestamp?: number) => {
        if (!timestamp) {
            return '';
        }
        const date = new Date(timestamp);
        return date.toLocaleDateString('ru-RU', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        });
    }

    const formatTimestampWOTime = (timestamp?: number) => {
        if (!timestamp) {
            return '';
        }
        const date = new Date(timestamp);
        return date.toLocaleDateString('ru-RU', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
        });
    }

    const eventRows = currentStats?.map((r, idx) => {
        const rows = [];
        rows.push(
            <tr key={idx} className={s.dayRecord}>
                <td>
                    { formatTimestampWOTime(r.timestamp) }
                </td>
                <td>
                </td>
                <td>
                </td>
                <td>
                </td>
                <td>
                </td>
                <td >
                    { r.money }
                </td>
            </tr>
        );
        r.events.map((e, eidx) => {
            rows.push(
                <tr key={idx + '-' + eidx} className={cn({
                    [s.bonusRow]: e.kind === EventType.WELCOME_BONUS_EARNED || e.kind === EventType.BONUS_EARNED,
                    [s.buyRow]: e.kind === EventType.BUY_CURRENCY,
                })}>
                    <td>
                        { formatTimestamp(e.timestamp) }
                    </td>
                    <td>
                        { e.kind === EventType.BUY_CURRENCY ? 'Purchase' : 'Bonus' }
                    </td>
                    <td >
                        { e.userName || e.userAlias }
                    </td>
                    <td >
                        { e.userId }
                    </td>
                    <td >
                        { e.packValue || e.bonusValue }
                    </td>
                    <td >
                        { e.packPrice }
                    </td>
                </tr>
            );
        });

        return rows;
    });

    return (
        <>
            {currentStats.length > 0 &&
                <table>
                    <thead>
                        <tr>
                            <td>
                                { 'Date' }
                            </td>
                            <td>
                                { 'Type' }
                            </td>
                            <td>
                                { 'Viewer' }
                            </td>
                            <td>
                                { 'VID' }
                            </td>
                            <td>
                                { 'Coins' }
                            </td>
                            <td>
                                { 'Money' }
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        { eventRows }
                    </tbody>
                </table>
            }
            {!isEndReached && currentStats.length > 0 && !isLoadingPage && !isFetchingPage &&
                <Button
                    caption={'Загрузить еще'}
                    className={s.loadMoreButton}
                    onClick={loadNextPage}
                />
            }
            {(isLoadingPage || isFetchingPage) &&
                <div className={s.loader}><Loader/></div>
            }
        </>
    );
};