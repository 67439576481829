import React, { useEffect, useRef, useState } from 'react';
import { StickerDto, AlertSize, defaultGeometry, Geometry } from '@interfaces';
import config from 'config';
import cn from 'classnames';
import s from './AlertPlayer.scss';
import { useLogger } from '@hooks';

interface AlertPlayerProps {
    uid?: string;
    alert: StickerDto;
    geometry?: Geometry;
    size?: string;
    volume?: number;
    hidden?: boolean;
    fullscreen?: boolean;
    useAnimation?: boolean;
    pageBackgroundUrl?: string,
    pageBackgroundMode?: number,
    isCopyrightFreeOnly?: boolean;
    onPlayEnd?: () => void;
}

export const AlertPlayer = (props: AlertPlayerProps) => {
    const {
        alert: {
            stickerUrl,
            customSettings,
            videoData,
        },
        geometry: {
            x,
            y,
            rotation,
        } = defaultGeometry,
        size = AlertSize.MEDIUM,
        volume = 100,
        hidden = false,
        useAnimation = false,
        fullscreen = false,
        pageBackgroundUrl,
        pageBackgroundMode,
        isCopyrightFreeOnly,
        onPlayEnd,
    } = props;

    const { trace } = useLogger({ target: 'AlertPlayer' });

    const fullscreenStyles = pageBackgroundUrl ? {
        backgroundImage: `url(${pageBackgroundUrl})`,
        backgroundPosition: pageBackgroundMode ? '0 0' : 'center',
        backgroundSize: pageBackgroundMode ? '' : 'cover',
    } : {};

    const styles = {
        top: y,
        left: x,
        transform: `rotate(${rotation}deg)`,
        ...fullscreenStyles,
    }

    const container = useRef<HTMLVideoElement>(null);

    const [videoStyles, setVideoStyles] = useState({ })
    const [fadeOut, setFadeOut] = useState(false);

    const calcVideoStyles = () => { // TODO: Try to calc it outside of AlertPlayer
        const sizeName = size ?? AlertSize.MEDIUM;
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        const videoWidth = container.current!.offsetWidth;
        const videoHeight = container.current!.offsetHeight;
        const videoStyles: Record<string, unknown> = { opacity: 1 };

        const viewportDiagonal = Math.sqrt(Math.pow(viewportWidth, 2) + Math.pow(viewportHeight, 2));

        if (fullscreen && videoData) {
            videoStyles.width = '100%';
            videoStyles.height = '100%';
        } else {
            if (videoWidth >= videoHeight) {
                const videoWidth = config.design.stickerSize?.[sizeName]?.width * viewportDiagonal / 100 + 'px';
                const videoHeight = 'auto';
                trace(`Calculated video dimensions: Width = ${videoWidth}, Height = ${videoHeight}`);
                videoStyles.width = videoWidth;
                videoStyles.height = videoHeight;
                videoStyles.borderRadius = config.design.stickerSize?.[sizeName]?.width * viewportDiagonal / 100 * 0.05;
            } else {
                trace(`Video width < Video height, calculating video size using ${config.design.stickerSize?.[sizeName]?.height}% of the height`);
                const videoWidth = 'auto';
                const videoHeight = config.design.stickerSize?.[sizeName]?.height * viewportDiagonal / 100 + 'px';
                trace(`Calculated video dimensions: Width = ${videoWidth}, Height = ${videoHeight}`);
                videoStyles.width = videoWidth;
                videoStyles.height = videoHeight;
                videoStyles.borderRadius = config.design.stickerSize?.[sizeName]?.height * viewportDiagonal / 100 * 0.05;
            }
        }

        return videoStyles;
    }

    const minAlertPlayingTimeSec: number = config.obs?.minAlertPlayingTimeSec ?? 3;

    const onCopyrightFreeTimeEnd = () => {
        if (container.current) {
            container.current.volume = 0;
        }
    }

    const onLoaded = (e: React.SyntheticEvent<HTMLVideoElement>) => {
        const videoDuration = e.currentTarget.duration || minAlertPlayingTimeSec;
        if (videoDuration && onPlayEnd) {
            const duration = videoDuration < minAlertPlayingTimeSec ? minAlertPlayingTimeSec : videoDuration
            setTimeout(() => onPlayEnd(), duration * 1000);
            setTimeout(() => setFadeOut(true), duration * 1000 - 500);
            // if (isCopyrightFreeOnly) {
            //     setTimeout(() => onCopyrightFreeTimeEnd(), 7000);
            // }
        }

        if (container.current) {
            setVideoStyles(calcVideoStyles());
        }
    }

    useEffect(() => {
        if (container.current) {
            const vol = customSettings?.customVolumeEnabled && customSettings?.customVolume !== undefined
                ? customSettings?.customVolume
                : volume;
            container.current.volume = vol / 100;
        }
    }, [volume]);

    return (
        <div
            className={cn(
                s.alertPlayer,
                {
                    [s.alertPlayerHidden]: hidden,
                    [s.alertPlayerFullscreen]: fullscreen,
                    [s.alertPlayerAnimated]: useAnimation,
                    [s.alertPlayerAnimatedFadeOut]: useAnimation && fadeOut,
                }
            )}
            style={styles}
        >
            <video
                ref={container}
                className={s.video}
                hidden={hidden}
                autoPlay={true}
                controls={false}
                src={stickerUrl}
                style={{...videoStyles}}
                onPlay={(e) => onLoaded(e)}
            />
        </div>
    );
};
