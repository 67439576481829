import { Logo, Panel, Row, SideMenu } from '@components';
import { useAdmin } from '@hooks';
import { HeadedPage } from '@layouts';
import React from 'react'

import { menu } from './MenuItems';

import s from './Admin.scss';
import { Outlet, useNavigate } from 'react-router-dom';

export const Admin = () => {
    const admin = useAdmin();
    const navigate = useNavigate();

    const header = (
        <div className={s.header}>
            <div className={s.headerLogoContainer} onClick={() => navigate('/')}>
                <Logo />
            </div>
            <div className={s.headerTitle}>
                {'Панель управления и настроек'}
            </div>
            <div className={s.headerExpander}/>
        </div>
    );

    return (
        <HeadedPage
            className={s.pageOverrides}
            header={header}
        >
            <Row fullHeight noGap className={s.contentRow}>
                <SideMenu elements={menu}/>
                <Panel>
                    <Outlet context={admin} />
                </Panel>
            </Row>
        </HeadedPage>
    );
};
