export enum EventType {
    GENERIC = 'generic',
    REGISTRATION = 'registration',
    LOGIN = 'login',
    STREAMER_PAGE_OPEN = 'page-open',
    SEND_ALERT_POPUP_OPEN = 'send-sticker-popup-open',
    PAYMENT_DIALOG_OPEN = 'payment-dialog-open',
    ALERT_SENT = 'sticker-sent',
    BUY_CURRENCY = 'buy-currency',
    MODERATION_STATUS = 'moderation-status',
    WELCOME_BONUS_EARNED = 'welcome-bonus-earned',
    BONUS_EARNED = 'bonus-earned',
    BONUS_ISSUED = 'bonus-issued',
    MEME_CANNON_STICKER_SENT = 'meme-cannon-sticker-sent',
}
