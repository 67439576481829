import { NotificationType } from '@contexts';
import { useApi, useNotifications } from '@hooks';
import { StickerDto, AlertUpdateDto, EModerationState, IUser, StreamerModerationAreaPageData } from '@interfaces';
import { useEffect, useState } from 'react';
import { PAGE_SIZE } from '@const';

export const useStreamerModeration = (streamer?: IUser) => {
    const [ page, setPage ] = useState(1);
    const [ currentPage, setCurrentPage ] = useState<StickerDto[]>([]);
    const [ lastData, setLastData ] = useState<StreamerModerationAreaPageData>();
    const [ isEndReached, setIsEndReached ] = useState(false);
    const [ displayErrors, setDisplayErrors ] = useState(false);

    const {
        alert: {
            useUpdateStickerMutation,
            useLazySilentUpdateStickerQuery,
        },
        moderation: {
            useLazyLoadPageStreamerModerationAreaQuery,
        }
    } = useApi();

    const [
        loadPage,
        {
            data: loadedStickersPage,
            isLoading: isLoadingPage,
            isSuccess,
            isFetching: isFetchingPage,
        }
    ] = useLazyLoadPageStreamerModerationAreaQuery();

    const [
        update,
    ] = useUpdateStickerMutation();

    const [
        silentUpdate,
    ] = useLazySilentUpdateStickerQuery();

    const {
        notify,
    } = useNotifications();

    const loadNextPage = () => {
        setPage(page + 1);
    }

    const reloadCurrentPage = () => {
        loadPage({ skip: PAGE_SIZE * (page - 1), pageSize: PAGE_SIZE, streamerId: streamer?.id });
    }

    const load = () => {
        setIsEndReached(false);
        setLastData(undefined);
        setCurrentPage([]);
        setPage(1);
        if (!!streamer) {
            setDisplayErrors(false);
            loadPage({ skip: 0, pageSize: PAGE_SIZE, streamerId: streamer.id });
        } else {
            setDisplayErrors(true);
        }
    }

    const approveAlert = async (stickerId: string) => {
        const updatedAlert = await update({ id: stickerId, moderationState: EModerationState.APPROVED }).unwrap();
        if (updatedAlert) {
            updateLoadedAlert({ id: stickerId, moderationState: EModerationState.APPROVED } as StickerDto);
            notify('Алерт успешно одобрен', NotificationType.SUCCESS);
        } else {
            notify('Не удалось одобрить алерт, обратитесь к разработчику', NotificationType.ERROR);
        }
    }

    const declineAlert = async (stickerId: string) => {
        const updatedAlert = await update({ id: stickerId, moderationState: EModerationState.DECLINED }).unwrap();
        if (updatedAlert) {
            updateLoadedAlert({ id: stickerId, moderationState: EModerationState.DECLINED } as StickerDto);
            notify('Алерт успешно отклонен', NotificationType.SUCCESS);
        } else {
            notify('Не удалось отклонить алерт, обратитесь к разработчику', NotificationType.ERROR);
        }
    }

    const updateAlert = async (alertData: AlertUpdateDto) => {
        const updatedAlert = await update({ ...alertData }).unwrap();
        if (updatedAlert) {
            updateLoadedAlert(alertData as StickerDto);
        } else {
            notify('Не удалось обновить алерт, обратитесь к разработчику', NotificationType.ERROR);
        }
    }

    const updateLoadedAlert = (alert: StickerDto) => {
        setCurrentPage([...currentPage].map(a => a.id === alert.id ? {...a, ...alert } : a ));
    }

    const silentUpdateAlert = async (alertData: AlertUpdateDto) => {
        await silentUpdate({ ...alertData }).unwrap();
    }

    useEffect(() => {
        if (streamer && page > 1) {
            reloadCurrentPage();
        }
    }, [page]);

    useEffect(() => {
        if (!isLoadingPage && !isFetchingPage) {
            if (loadedStickersPage && !loadedStickersPage.data.length) {
                if (loadedStickersPage.streamer) {
                    setIsEndReached(true);
                } else {
                    setIsEndReached(false);
                    setLastData(undefined);
                    setCurrentPage([]);
                    setDisplayErrors(true);
                }
            } else {
                setCurrentPage((prev) => [...prev].concat(loadedStickersPage?.data ?? []));
                setLastData(loadedStickersPage);
                if (loadedStickersPage?.data && loadedStickersPage?.data.length < PAGE_SIZE) {
                    setIsEndReached(true);
                }
            }
        }
    }, [loadedStickersPage, isLoadingPage, isFetchingPage]);

    useEffect(() => {
        setDisplayErrors(false);
        setIsEndReached(false);
        setLastData(undefined);
        setCurrentPage([]);
        setPage(1);
        load();
    }, [streamer]);

    return {
        currentPage,
        displayErrors,
        isEndReached,
        lastData,
        streamer,
        isLoadingPage,
        isFetchingPage,
        isSuccess,
        load,
        loadNextPage,
        approveAlert,
        declineAlert,
        updateAlert,
        silentUpdateAlert,
        updateLoadedAlert,
    };
};
