import { Button, Loader } from '@components';
import { EventType, useStreamerEventsStickers } from '@hooks';
import React from 'react';
import s from './StickersTable.scss';
import { IUser, StreamerEventsStickersDataItem } from '@interfaces';

export interface StickersTableProps {
    streamer?: IUser;
}

export const StickersTable = (props: StickersTableProps) => {
    const {
        streamer,
    } = props;

    const {
        currentStats,
        isLoadingPage,
        isFetchingPage,
        isEndReached,
        loadNextPage,
    } = useStreamerEventsStickers(streamer);

    const formatTimestamp = (timestamp?: number) => {
        if (!timestamp) {
            return '';
        }
        const date = new Date(timestamp);
        return date.toLocaleDateString('ru-RU', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        });
    }

    const resolveRomanEventType = (item: StreamerEventsStickersDataItem) => {
        const kind = item.kind;
        const fullscreen = item.fullscreen;
        if (kind === EventType.ALERT_SENT) {
            return fullscreen ? 'Фуллскрин' : 'Стрикер';
        }
        if (kind === EventType.MEME_CANNON_STICKER_SENT) {
            return `Мемпушка (${item.count})`;
        }
    }

    const eventRows = currentStats?.map((r, idx) => (
        <tr key={idx}>
            <td>
                { formatTimestamp(r.timestamp) }
            </td>
            <td>
                { resolveRomanEventType(r) }
            </td>
            <td >
                { r.userName || r.userAlias }
            </td>
            <td >
                { r.userId }
            </td>
            <td >
                { r.stickerName }
            </td>
            <td >
                { r.paidPrice }
            </td>
        </tr>
    ));

    return (
        <>
            {currentStats.length > 0 &&
                <table>
                    <thead>
                        <tr>
                            <td>
                                { 'Date' }
                            </td>
                            <td>
                                { 'Type' }
                            </td>
                            <td>
                                { 'Viewer' }
                            </td>
                            <td>
                                { 'VID' }
                            </td>
                            <td>
                                { 'Coins' }
                            </td>
                            <td>
                                { 'Money' }
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        { eventRows }
                    </tbody>
                </table>
            }
            {!isEndReached && currentStats.length > 0 && !isLoadingPage && !isFetchingPage &&
                <Button
                    caption={'Загрузить еще'}
                    className={s.loadMoreButton}
                    onClick={loadNextPage}
                />
            }
            {(isLoadingPage || isFetchingPage) &&
                <div className={s.loader}><Loader/></div>
            }
        </>
    );
};