import { NotificationType } from '@contexts';
import { useNotifications } from '@hooks/context';
import { useApi } from '@hooks/webapi';
import { ITag, ThematicSet } from '@typings';
import { useEffect, useState } from 'react';

export const useThematicSets = () => {
    const {
        alert: {
            useLoadThematicSetsQuery,
            useCreateThematicSetMutation,
            useUpdateThematicSetMutation,
            useRemoveThematicSetMutation,
        }
    } = useApi();

    const {
        notify,
    } = useNotifications();

    const { data: loadedThematicSets = [] } = useLoadThematicSetsQuery();
    const [ createThematicSet ] = useCreateThematicSetMutation();
    const [ updateThematicSet ] = useUpdateThematicSetMutation();
    const [ deleteThematicSet ] = useRemoveThematicSetMutation();

    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [isSaveAllowed, setIsSaveAllowed] = useState<boolean>(false);

    // const [thematicSetId, setThematicSetId] = useState<string>();
    const [pictureFile, setPictureFile] = useState<File>();
    const [pictureData, setPictureData] = useState<string>();
    const [thematicSetName, setThematicSetName] = useState<string>();
    const [thematicTags, setThematicTags] = useState<ITag[]>();
    const [beginDate, setBeginDate] = useState<string>();
    const [endDate, setEndDate] = useState<string>();
    const [thematicSetText, setThematicSetText] = useState<string>();
    const [thematicSets, setThematicSets] = useState<ThematicSet[]>([]);
    const [selectedThematicSet, setSelectedThematicSet] = useState<ThematicSet>();

    const updatePictureFile = (file: File) => setPictureFile(file);
    const updateSetName = (name: string) => setThematicSetName(name);
    const updateThematicTags = (tags?: ITag[]) => setThematicTags(tags);
    const updateBeginDate = (date: string) => setBeginDate(date);
    const updateEndDate = (date: string) => setEndDate(date);
    const updateThematicText = (text: string) => setThematicSetText(text);
    const switchEditMode = (isEnabled: boolean) => setIsEditMode(isEnabled);

    const saveThematicSet = async () => {
        if (isSaveAllowed) {
            const data = new FormData();
            if (pictureFile && pictureData) {
                data.append('picture', pictureFile!);
            }
            data.append('name', thematicSetName!);
            data.append('tags', JSON.stringify(thematicTags));
            data.append('beginDate', Date.parse(beginDate!).toString());
            data.append('endDate', Date.parse(endDate!).toString());
            data.append('description',thematicSetText ?? '');
            const result = selectedThematicSet
                ? await updateThematicSet({ data, id: selectedThematicSet.id }).unwrap()
                : await createThematicSet(data).unwrap();
            if (result) {
                setIsEditMode(false);
            } else {
                notify('Что-то пошло не так!!!', NotificationType.ERROR);
            }
        }
    }

    const selectThematicSet = (id: string) => {
        setSelectedThematicSet(thematicSets.find(ts => ts.id === id));
        setIsEditMode(true);
    }

    const removeThematicSet = (id: string) => {
        deleteThematicSet(id);
    }

    const cancelThematicSetCreation = () => {
        setSelectedThematicSet(undefined);
        setIsEditMode(false);
    }

    useEffect(() => {
        const sortedThematicSets = [...loadedThematicSets].sort((s1, s2) => s1.beginDate > s2.beginDate ? 1 : s1.beginDate === s2.beginDate ? 0 : -1);
        setThematicSets(sortedThematicSets);
    }, [loadedThematicSets]);

    useEffect(() => {
        if (selectedThematicSet) {
            const beginDate = selectedThematicSet?.beginDate ? new Date(selectedThematicSet?.beginDate) : null;
            const beginDateString = beginDate ? `${beginDate?.getFullYear()}-${(beginDate?.getMonth() + 1).toString().padStart(2, '0')}-${beginDate?.getDate().toString().padStart(2, '0')}` : '';

            const endDate = selectedThematicSet?.endDate ? new Date(selectedThematicSet?.endDate) : null;
            const endDateString = endDate ? `${endDate?.getFullYear()}-${(endDate?.getMonth() + 1).toString().padStart(2, '0')}-${endDate?.getDate().toString().padStart(2, '0')}` : '';

            setThematicSetName(selectedThematicSet.name);
            setBeginDate(beginDateString);
            setEndDate(endDateString);
            setThematicTags(selectedThematicSet.tags);
            setThematicSetText(selectedThematicSet.description);
        } else {
            setThematicSetName(undefined);
            setBeginDate(undefined);
            setEndDate(undefined);
            setThematicTags(undefined);
            setThematicSetText(undefined);
            setPictureData(undefined);
            setPictureFile(undefined);
        }
    }, [selectedThematicSet]);

    useEffect(() => {
        if (pictureFile) {
            const reader = new FileReader();

            reader.onloadend = () => {
                if (reader.result) {
                    setPictureData(reader.result.toString());
                }
            }

            reader.readAsDataURL(pictureFile);
        }
    }, [pictureFile]);

    useEffect(() => {
        if ((pictureData && thematicSetName && thematicTags?.length && beginDate && endDate) || selectedThematicSet) {
            setIsSaveAllowed(true);
        } else {
            setIsSaveAllowed(false);
        }
    }, [pictureFile, thematicSetName, thematicTags, beginDate, endDate, selectedThematicSet]);

    return {
        isEditMode,
        isSaveAllowed,
        pictureFile,
        pictureData,
        thematicSets,
        selectedThematicSet,

        updatePictureFile,
        updateSetName,
        updateThematicTags,
        updateBeginDate,
        updateEndDate,
        updateThematicText,

        saveThematicSet,
        cancelThematicSetCreation,
        removeThematicSet,
        selectThematicSet,
        switchEditMode,
    };
};
