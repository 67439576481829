import { AlertPlayer, Loader, Menu, MenuItem, Panel } from '@components';
import { useModals, usePlayingAlertsList, useSimilarStickers } from '@hooks';
import { StickerDto, AlertSize } from '@interfaces';
import React, { useEffect, useState } from 'react';

import { IconCloseLine, IconPlayCircleLine, IconReplayLine, IconSoundEnableLine } from '@common/images/svg';
import s from './SimilarStickersPopup.scss';
import { StickersGrid } from '@views';
import config from 'config';

interface SimilarStickersPopupProps {
    alert: StickerDto,
}

export const SimilarStickersPopup = (props: SimilarStickersPopupProps) => {
    const { closeModal } = useModals();
    const {
        alert,
    } = props;

    const {
        stickers,
        isLoading,
        search,
    } = useSimilarStickers()

    const {
        list,
        addToList,
        removeFromList,
    } = usePlayingAlertsList({
        stickerSize: config?.design?.stickerSize?.[AlertSize.MEDIUM],
        availableAreaMask: 1,
    });

    const [activeTab, setActiveTab] = useState('TOTAL');
    const [totalStickers, setTotalStickers] = useState<StickerDto[]>([]);
    const [videoStickers, setVideoStickers] = useState<StickerDto[]>([]);
    const [audioStickers, setAudioStickers] = useState<StickerDto[]>([]);
    const [currentStickers, setCurrentStickers] = useState(totalStickers);

    const setTab = (tabName: string) => {
        switch (tabName) {
            case 'TOTAL':
                setActiveTab(tabName);
                setCurrentStickers(totalStickers);
                break;
            case 'VIDEO':
                setActiveTab(tabName);
                setCurrentStickers(videoStickers);
                break;
            case 'AUDIO':
                setActiveTab(tabName);
                setCurrentStickers(audioStickers);
                break;
        }
    }
    
    useEffect(() => {
        if (alert) {
            search(alert);
        }
    }, [alert])

    useEffect(() => {
        if (!isLoading && stickers) {
            setTotalStickers(stickers.total.map(([alert, _score]) => (alert)));
            setVideoStickers(stickers.v.map(([alert, _score]) => (alert)));
            setAudioStickers(stickers.a.map(([alert, _score]) => (alert)));
            
            switch (activeTab) {
                case 'TOTAL':
                    setCurrentStickers(stickers.total.map(([alert, _score]) => (alert)));
                    break;
                case 'VIDEO':
                    setCurrentStickers(stickers.v.map(([alert, _score]) => (alert)));
                    break;
                case 'AUDIO':
                    setCurrentStickers(stickers.a.map(([alert, _score]) => (alert)));
                    break;
            }
        }
    }, [stickers, isLoading])

    return (
        <Panel className={s.similarStickerPopup}>
            <Menu className={s.tabs}>
                <MenuItem
                    icon={<IconReplayLine />}
                    caption={'Видео+Аудио'}
                    isActive={activeTab === 'TOTAL'}
                    onClick={() => setTab('TOTAL')}
                />
                <MenuItem
                    icon={<IconPlayCircleLine />}
                    caption={'Видео'}
                    isActive={activeTab === 'VIDEO'}
                    onClick={() => setTab('VIDEO')}
                />
                <MenuItem
                    icon={<IconSoundEnableLine />}
                    caption={'Аудио'}
                    isActive={activeTab === 'AUDIO'}
                    onClick={() => setTab('AUDIO')}
                />
            </Menu>
            {currentStickers && currentStickers.length > 0
                ? <StickersGrid
                    className={s.stickersGrid}
                    isMobileLayoutRecommended={false}
                    stickers={currentStickers}
                    stickersSoundEnabled={true}
                    onStickerClick={addToList}
                />
                : isLoading ? <Loader className={s.loader}/> : <>Ничего не найдено</>
            }

            <div className={s.closeButton} onClick={closeModal}>
                <IconCloseLine/>
            </div>

            { list.length > 0 &&
                <Panel className={s.alertsShadow}>
                    {
                        list.map(a => {
                            return (
                                <AlertPlayer
                                    key={a.uid}
                                    alert={a.alert}
                                    geometry={a.geometry}
                                    size={AlertSize.LARGE}
                                    onPlayEnd={() => removeFromList(a.uid)}
                                />
                            );
                        })
                    }
                </Panel>
            }
        </Panel>
    )
}