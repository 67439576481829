import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const AuthRedirect = () => {
    const [searchParams] = useSearchParams();

    const processToken = () => {
        const accessToken = searchParams.get('accessToken');
        const refreshToken = searchParams.get('refreshToken');
        const obsToken = searchParams.get('obsToken');

        console.log(accessToken);
        if (!accessToken) {
            console.log('Auth error!');
        } else {
            localStorage.setItem('token', accessToken);
            if (refreshToken) localStorage.setItem('refreshToken', refreshToken);
            if (obsToken) localStorage.setItem('obsToken', obsToken);
        }
    };

	useEffect(() => {
        processToken();
        window.opener.postMessage({ action: 'close' });
    },[]);

    return (<></>)
};
