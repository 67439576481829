import { StickerDto } from '@interfaces';
import { memeAlertsBaseApi } from './meme-alerts-base.api';

export const stickersExternalModerationApi = memeAlertsBaseApi.injectEndpoints({
    endpoints: (build) => ({
        getNextModeratedSticker: build.query<StickerDto, { skipCurrent?: boolean }>({
            query: (data) => ({
                url: 'sticker/external-moderation/next-sticker',
                method: 'POST',
                body: data,
            }),
        }),
    }),
});
