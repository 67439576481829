import { Button, ColoredButton, ColoredButtonColor, Column, ComponentColor, IconButton, Input, InputColor, Label, LabelSize, Panel, Row, Select, Slider, StreamerSelect, Switch, SwitchColor, TagSelect, TextArea, TextField, TextFieldAction } from '@components';
import React from 'react';

import { IconArrowRightLine, IconCloseLine, IconCogLine, IconMagnifierLine } from '@common/images/svg';
import { MemeParty } from '@views/widgets';
import s from './ThemePreview.scss';
import { noop } from '@common';

const onTextFieldAction = (action: TextFieldAction) => {
    switch (action.name) {
        case 'reset':
            return true;
        case 'enter':
            console.log('Делаем что-то');
            break;
    }
}

const onTextFieldChanged = (value: string) => {
    console.log(value);
}

export const ThemePreview = () => {
    return (
        <Panel className={s.themePreviewContent}>
            <Row className={s.themePreviewRow}>
                <div className={s.list}>
                    <Column>
                        <Label caption='Поле ввода' size={LabelSize.SMALL} />
                        <Input placeholder='Placeholder...' />
                    </Column>
                    <Column>
                        <Label caption='Поле ввода (цветной фокус)' size={LabelSize.SMALL} />
                        <Input placeholder='Placeholder...' color={InputColor.ORANGE} />
                    </Column>
                    <Column>
                        <Label caption='Текстовое поле' size={LabelSize.SMALL} />
                        <TextField
                            tag={<IconMagnifierLine />}
                            placeholder={'Поисковый запрос...'}
                            defaultValue='Значение по умолчанию'
                            invalidSymbols={/[^0-9]/}
                            maxLength={10}
                            actions={[
                                { icon: <IconCloseLine/>, name: 'reset' },
                                { icon: <IconArrowRightLine/>, name: 'enter' },
                            ]}
                            onAction={onTextFieldAction}
                            onChanged={onTextFieldChanged}
                        />
                    </Column>
                    <Column>
                        <Label caption='Поиск стримера' size={LabelSize.SMALL} />
                        <StreamerSelect
                            placeholder={'Стример...'}
                            debounceTime={500}
                            maxLength={100}
                        />
                    </Column>
                    <Column>
                        <Label caption='Выбор тегов' size={LabelSize.SMALL} />
                        <TagSelect
                            placeholder={'Добавить тег'}
                            debounceTime={500}
                            maxLength={20}
                        />
                    </Column>
                </div>
            </Row>
            <Row className={s.themePreviewRow}>
                <div className={s.list}>
                    <Column>
                        <Button caption={'Simple Button'} onClick={noop} />
                    </Column>
                    <Column>
                        <ColoredButton caption={'Colored Button'} color={ColoredButtonColor.ORANGE} />
                        <ColoredButton caption={'Colored Button'} color={ColoredButtonColor.GREEN} />
                        <ColoredButton caption={'Colored Button'} color={ColoredButtonColor.RED} />
                        <ColoredButton caption={'Colored Button'} color={ColoredButtonColor.WHITE} />
                    </Column>
                    <Column>
                        <IconButton caption={'Icon Button'} icon={<IconCogLine/>} color={ComponentColor.RED}/>
                        <IconButton caption={'Icon Button'} icon={<IconCogLine/>} color={ComponentColor.ORANGE}/>
                        <IconButton caption={'Icon Button'} icon={<IconCogLine/>} color={ComponentColor.GREEN}/>
                        <IconButton caption={'Icon Button'} icon={<IconCogLine/>} color={ComponentColor.BLUE}/>
                        <IconButton caption={'Icon Button'} icon={<IconCogLine/>} color={ComponentColor.DEFAULT}/>
                    </Column>
                    <Column>
                        <Select
                            options={[
                                { value: 5, caption: 'Пять', id: 4 },
                                { value: 7, caption: 'Семь', id: 3 },
                                { value: 10, caption: 'Десять', id: 2, selected: true },
                                { value: 13, caption: 'Тринадцать', id: 1 },
                            ]}
                            onSelectionChanged={(s) => console.log(JSON.stringify(s))}
                            onValueChanged={(s) => console.log(s)}
                        />
                    </Column>
                </div>
            </Row>
            <Row className={s.themePreviewRow}>
                <div className={s.list}>
                    <Column>
                        <Label caption='Текстовая область' size={LabelSize.SMALL} />
                        <TextArea
                            maxRows={4}
                            placeholder={'Placeholder...'}
                        />
                    </Column>
                    <Column fullWidth>
                        <Label caption='Слайдер' size={LabelSize.SMALL} />
                        <Slider
                            caption={'Громкость'}
                            showPosition
                            minValue={0}
                            maxValue={100}
                            initialValue={0}
                            realtimeUpdate
                        />
                    </Column>
                </div>
            </Row>
            <Row className={s.themePreviewRow}>
                <div className={s.list}>
                    <Column fullWidth>
                        <Switch
                            caption={'Красный'}
                            color={SwitchColor.RED}
                            className={s.test}
                        />
                        <Switch
                            caption={'Оранжевый'}
                            color={SwitchColor.ORANGE}
                            className={s.test}
                        />
                        <Switch
                            caption={'Зеленый'}
                            color={SwitchColor.GREEN}
                            className={s.test}
                        />
                        <Switch
                            caption={'Синий'}
                            color={SwitchColor.BLUE}
                            className={s.test}
                        />
                        <Switch
                            caption={'Фиолетовый'}
                            color={SwitchColor.PURPLE}
                            className={s.test}
                        />
                    </Column>
                </div>
                <Column fullWidth>
                    <MemeParty goalValue={100} currentValue={50}/>
                </Column>
            </Row>
            <Row className={s.themePreviewRow}>
                <div className={s.list}>
                    <Column fullWidth>
                    </Column>
                </div>
            </Row>
        </Panel>
    );
};
