import { Input, InputValueType } from '@components';
import { Panel, Column } from '@components';
import { CenteredContent } from '@layouts';
import React, { useState } from 'react'
import { IconEnterLine, IconKeyLine, IconUserLine } from '@images/svg';

import s from './Login.scss';
import { useLogin } from '@hooks';

export const Login = () => {
    const [username, setUsername] = useState<InputValueType>();
    const [password, setPassword] = useState<InputValueType>();

    const { login } = useLogin();

    const onLoginAction = () => {
        if (username && password) {
            login(username.toString(), password.toString());
        }
    }

    return (
        <Panel>
            <CenteredContent>
                <Column>
                    <Input
                        caption={'User name'}
                        width={"245px"}
                        tagIcon={<IconUserLine/>}
                        onChange={(v) => setUsername(v)}
                    />
                    <br/>
                    <Input
                        actionIcon={<IconEnterLine/>}
                        caption={'Password'}
                        width={"200px"}
                        tagIcon={<IconKeyLine/>}
                        type='password'
                        onAction={onLoginAction}
                        onChange={(v) => setPassword(v)}
                    />
                </Column>
            </CenteredContent>
        </Panel>
    )
}
