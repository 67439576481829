import { IUser } from "./IUser";

export interface StatisticData {
    day: number;
    newUsers: number;
    totalUsers: number;
    newStreamers: number;
    totalStreamers: number;
    activeStreamers: number;
    streamerPageHits: number;
    sendStickerPopups: number;
    uniquePayersCount: number;
    uniqueSendersCount: number;
    purchasedCurrencyValue: number;
    spentCurrencyValue: number;
    totalCurrencyInWallets: number;
    sales: number;
    fullscreen: number;
    revenue: number;
    profit: number;
    memePartyCount: number;
    newPayersCount: number;
    totalPayersCount: number;
}

export interface SendedAlertStatisticData {
    day: number;
    stickerSent: number;
    search: number;
    top: number;
    popular: number;
    last: number;
    channel: number;
    favorites: number;
    myCreated: number;
}

export interface SearchStatisticData {
    day: number;
    search: number;
    searchNull: number;
    searchSent: number;
}

export interface SearchQueryStatisticData {
    _id: string;
    count: number;
    results: number;
}

export interface SendAlertsStatisticData {
    _id: string;
    name: number;
    sent: number;
    sendersCount: number;
    streamersCount: number;
}

export interface CohortStatisticData {
    _id: string;
    num: number;
    weekDescription: string;
    invitesCount: number;
    cohortSize: number;
    activated: number;
    earned: number;
}

export interface StreamerAlertsStatsData {
    streamer: IUser,
    data: StreamerAlertsStatsDataItem[];
    minMax: {
        maxUsages: number;
        maxStreamerUsages: number;
        minCreationDate: number;
    }
}

export interface StreamerAlertsStatsDataItem {
    _id: string;
    name: string;
    stickerUrl: string;
    stickerPreview: string;
    stickerAnimatedPreview: string;
    isOnTop: boolean;
    createdAt: number;
    moderatedAt: number;
    streamerSends: number;
    totalSends: number;
    daysAfterCreation: number;
}

export interface StreamerStickersStatsLoadParams {
    page: number;
    streamerName: string;
    commonPopularityWeight?: number;
    streamerPopularityWeight?: number;
}

export interface StreamerStickersStatsData {
    streamer: IUser,
    data: StreamerStickersStatsDataItem[];
}

export interface StreamerStickersStatsDataItem {
    _id: string;
    name: string;
    lifetime: number;
    totalSendings: number;
    totalAverage: number;
    totalRms: number;
    totalZScore: number;
    streamerSendings: number;
    streamerAverage: number;
    streamerRms: number;
    streamerZScore: number;
    rank: number;
}

export interface ViewerEventsLoadParams {
    page: number;
    userId: string;
}

export interface ViewerEventsData {
    viewer: IUser,
    wallets: ViewerWallets[];
    data: ViewerEventsDataItem[];
}

export interface ViewerWallets {
    userId: string;
    balance: number;
    streamerId: string;
    streamerName: string;
}

export interface ViewerEventsDataItem {
    _id: string;
    kind: string;
    fullscreen?: boolean;
    timestamp: number;
    streamerName: string;
    type: string;
    event: string;
    stickerName?: string;
    paidPrice?: number;
    packValue?: number;
    packPrice?: number;
    bonusValue?: number;
    money: number;
    balance: number;
}

export interface StreamerEventsMoneyLoadParams {
    page: number;
    streamerId: string;
}

export interface StreamerEventsMoneyData {
    data: StreamerEventsMoneyDataItem[];
}

export interface StreamerEventsMoneyDataItem {
    year: number;
    month: number;
    day: number;
    events: StreamerEventsMoneyDataEventItem[];
    timestamp: number;
    money: number;
}

export interface StreamerEventsMoneyDataEventItem {
    _id: string;
    kind: string;
    timestamp: number;
    streamerName: string;
    userName: string;
    userAlias: string;
    userId: string;
    paidPrice?: number;
    packValue?: number;
    packPrice?: number;
    bonusValue?: number;
    money: number;
}

export interface StreamerEventsStickersLoadParams {
    page: number;
    streamerId: string;
}

export interface StreamerEventsStickersData {
    data: StreamerEventsStickersDataItem[];
}

export interface StreamerEventsStickersDataItem {
    _id: string;
    kind: string;
    fullscreen?: boolean;
    timestamp: number;
    streamerName: string;
    type: string;
    event: string;
    userName: string;
    userAlias: string;
    userId: string;
    stickerName?: string;
    paidPrice?: number;
    packValue?: number;
    packPrice?: number;
    bonusValue?: number;
    count: number;
    balance: number;
}