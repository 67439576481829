import { CohortStatisticData } from '@interfaces';
import React from 'react';

interface StatisticRecordProps {
    data: CohortStatisticData;
}

export const StatisticRecord = (props: StatisticRecordProps) => {
    const {
        data,
    } = props;

    const acp = data.activated === 0 || data.cohortSize === 0 ? 0 : ((data.activated / data.cohortSize) * 100).toFixed(2);
    const ecp = data.earned === 0 || data.cohortSize === 0 ? 0 : ((data.earned / data.cohortSize) * 100).toFixed(2);

    return (
        <tr>
            <td>
                { data.num }
            </td>
            <td>
                { data.weekDescription }
            </td>
            <td>
                { data.invitesCount }
            </td>
            <td>
                { data.cohortSize }
            </td>
            <td>
                { data.activated }
            </td>
            <td>
                { acp }
            </td>
            <td>
                { data.earned }
            </td>
            <td>
                { ecp }
            </td>
        </tr>
    );
};
