import React from 'react';

import cn from 'classnames';

import { InputValueType, useInput } from './hooks/Input';

import s from './Input.scss';
import { Label, LabelSize } from '@components';

export enum InputColor {
    ORANGE = 'orange',
    RED = 'red',
    GREEN = 'green',
}

interface InputProps {
    className?: string;
    caption?: string | JSX.Element;
    placeholder?: string;
    prefix?: string;
    tagIcon?: JSX.Element;
    actionClassName?: string;
    actionIcon?: JSX.Element;
    actionText?: string;
    actions?: JSX.Element[];
    fullWidth?: boolean;
    width?: string;
    readonly?: boolean;
    disabled?: boolean;
    defaultValue?: InputValueType;
    color?: InputColor;
    shadowActive?: boolean;
    debounceTime?: number;
    resetTrigger?: unknown;
    invalidSymbols?: RegExp;
    maxLength?: number;
    maxNumberValue?: number;
    hasError?: boolean;
    errorMessage?: string;
    type?: React.HTMLInputTypeAttribute;
    onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onChange?: (value: InputValueType) => void;
    onAction?: () => void;
    onTag?: () => void;
}

export const Input = (props: InputProps) => {
    const {
        className,
        caption,
        placeholder,
        prefix,
        tagIcon,
        actionClassName,
        actionIcon,
        actionText,
        actions = [],
        fullWidth,
        width,
        readonly,
        disabled,
        color = 'none',
        shadowActive,
        hasError,
        errorMessage,
        type = 'text',
        onKeyUp,
        onAction,
        onTag,
    } = props;

    let styles: Record<string, unknown> = {};

    if (width) {
        styles.width = width;
    }

    return (
        <div className={cn(
            s.inputWrapper,
            {
                [s.inputWrapperFullWidth]: fullWidth,
                [s.inputWrapperDisabled]: disabled,
            },
            className,
        )}>
            <div className={s.inputLabelContainer}>
            {
                caption && ((typeof caption === "string") ? <Label caption={caption} size={LabelSize.SMALL}/> : caption)
            }
            </div>
            <div
                className={cn(
                    s.inputContainer,
                    s[color],
                    {
                        [s.shadowed]: shadowActive,
                        [s.hasError]: hasError,
                    }
                )}
            >
                {
                    tagIcon &&
                    <div
                        className={cn(
                            s.inputContainerTag,
                            {
                                [s.clickable]: !!onTag
                            }
                        )}
                        onClick={onTag}
                    >
                        <div className={s.inputContainerTagIcon}>{tagIcon}</div>
                    </div>
                }
                {
                    prefix &&
                    <div className={s.inputContainerPrefix}>
                        {prefix}
                    </div>
                }
                <input
                    type={type}
                    className={cn(
                        s.inputContainerInput,
                        {
                            [s.inputContainerInputNoLeftPadding]: !!prefix || !!tagIcon
                        }
                    )}
                    onKeyUp={onKeyUp}
                    readOnly={readonly}
                    placeholder={placeholder}
                    {...useInput({ ...props })}
                    style={styles}
                />
                {
                    actionIcon &&
                    <div
                        className={cn(
                            actionClassName,
                            s.inputContainerAction,
                            {
                                [s.clickable]: !!onAction
                            }
                        )}
                        onClick={onAction}
                    >
                        <div className={s.inputContainerActionIcon}>{actionIcon}</div>
                    </div>
                }
                {actions.map((e, i) => e)}
                {
                    actionText &&
                    <div
                        className={cn(
                            s.inputContainerText,
                            {
                                [s.clickable]: !!onAction
                            }
                        )}
                        onClick={onAction}
                    >
                        <div className={s.inputContainerTextValue}>{actionText}</div>
                    </div>
                }
            </div>
            { hasError &&
                <div className={s.errorMessage}>{errorMessage}</div>
            }
        </div>
    )
}