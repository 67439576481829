import { CohortStatisticData } from '@interfaces';
import { api } from '@store';
import { useEffect, useState } from 'react';

export const useCohortStats = () => {
    const {
        stats: {
            useLazyGetCohortStatsPageQuery,
        }
    } = api;

    const [page, setPage] = useState(1);
    const [currentStats, setCurrentStats] = useState<CohortStatisticData[]>([]);
    const [isEndReached, setIsEndReached] = useState(false);

    const [
        loadPage,
        {
            data: loadedPage,
            isLoading: isLoadingPage,
        }
    ] = useLazyGetCohortStatsPageQuery();

    const loadNextPage = () => {
        setPage(page + 1);
    }

    useEffect(() => {
        loadPage(page);
    }, []);

    useEffect(() => {
        loadPage(page);
    }, [page]);

    useEffect(() => {
        if (loadedPage && !loadedPage.length) {
            setIsEndReached(true);
        }
        setCurrentStats((prev) => [...prev].concat(loadedPage ?? []));
    }, [loadedPage]);

    return {
        currentStats,
        isLoadingPage,
        isEndReached,

        loadNextPage,
    };
};
