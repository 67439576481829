import React, { useEffect, useState } from 'react'

export type TextAreaValueType = string | ReadonlyArray<string> | number | undefined;

interface TextAreaParams {
    defaultValue?: TextAreaValueType;
    readonly?: boolean;
    debounceTime?: number;
    resetTrigger?: unknown;
    onChange?: (value: TextAreaValueType) => void;
}

let timer: NodeJS.Timeout;

export const useTextArea = (params: TextAreaParams = {}) => {
    const {
        defaultValue = '',
        readonly,
        debounceTime = 0,
        resetTrigger,
        onChange,
    } = params;

    const [value, setValue] = useState<TextAreaValueType>(defaultValue);
    const [length, setLength] = useState<TextAreaValueType>(defaultValue);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(event.target.value);
        setLength(event.target.value.length);
        if (debounceTime > 0) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                onChange && onChange(event.target.value);
            }, debounceTime);
        } else {
            onChange && onChange(event.target.value);
        }
    }

    useEffect(() => {
        if (defaultValue || defaultValue === '') {
            setValue(defaultValue);
            onChange && onChange(defaultValue);
        }
    }, [defaultValue]);

    useEffect(() => {
        setValue(defaultValue ?? '');
        onChange && onChange(defaultValue);
    }, [resetTrigger])

    return {
        value,
        length,
        onChange: readonly ? () => {} : handleChange,
    }
}