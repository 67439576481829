import { Button, Loader, Panel, Row } from '@components';
import { useSentAlertStats } from '@hooks';
import cn from 'classnames';
import React from 'react';
import s from './SendedAlertStats.scss';
import { StatisticRecord } from './components';

export const SendedAlertStats = () => {
    const {
        currentDayStats,
        dailyStatistic,
        isDailyStatisticLoading,
        isDailyEndReached,
        loadNextDailyPage,
    } = useSentAlertStats();

    const isLoading = isDailyStatisticLoading;

    const rows = dailyStatistic?.map((r, idx) => (
        <StatisticRecord data={r} key={idx}/>
    ));

    return (
        <Panel className={s.statsContainer}>
            <Row noGap>
                <div className={cn(
                    s.statsTab,
                    s.statsTabActive,
                )}>
                    {`Дневная статистика`}
                </div>
            </Row>
            {
                isLoading
                    ? <Loader />
                    : <>
                        <table>
                            <thead>
                                <tr>
                                    <td>
                                        { "Date" }
                                    </td>
                                    <td>
                                        { 'AlertSent' }
                                    </td>
                                    <td>
                                        { 'Search' }
                                    </td>
                                    <td>
                                        { 'Search %' }
                                    </td>
                                    <td>
                                        { 'Top' }
                                    </td>
                                    <td>
                                        { 'Top %' }
                                    </td>
                                    <td>
                                        { 'MySent' }
                                    </td>
                                    <td>
                                        { 'MySent %' }
                                    </td>
                                    <td>
                                        { 'MyFavorites' }
                                    </td>
                                    <td>
                                        { 'MyFavorites %' }
                                    </td>
                                    <td>
                                        { 'MyCreated' }
                                    </td>
                                    <td>
                                        { 'MyCreated%' }
                                    </td>
                                    <td>
                                        { 'ChanPopular' }
                                    </td>
                                    <td>
                                        { 'ChanPopular %' }
                                    </td>
                                    <td>
                                        { 'ChanStickers' }
                                    </td>
                                    <td>
                                        { 'ChanStickers %' }
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                { currentDayStats &&
                                    <StatisticRecord data={currentDayStats} key={-1}/>
                                }

                                {rows}
                            </tbody>
                        </table>


                        {!isDailyEndReached &&
                            <Button
                                caption={'Загрузить еще'}
                                className={s.loadMoreButton}
                                onClick={loadNextDailyPage}
                            />
                        }
                    </>
            }
        </Panel>
    );
};