import React from 'react'

import s from './StickerOverlay.scss';
import { StickerDto } from '@interfaces';
import { IconSoundActiveLine, IconSoundMuteLine } from '@common/images/svg';
import { noop } from '@common';

interface StickerOverlayProps {
    sticker: StickerDto;
    soundEnabled?: boolean;
    onSoundButtonClick?: () => void;
}

export const StickerOverlay = (props: StickerOverlayProps) => {
    const {
        sticker,
        soundEnabled,
        onSoundButtonClick = noop,
    } = props;

    const onSoundClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        onSoundButtonClick();
    }

    return (
        <div className={s.stickerOverlay}>
            <div className={s.stickerOverlayContent}>
                {/* <div className={s.stickerOverlayContentCreator}>
                    <div className={s.stickerOverlayContentCreatorAvatar}>
                        <RoundedAvatar size={RoundedAvatarSize.EXTRA_SMALL}/>
                    </div>
                    <div className={s.stickerOverlayContentCreatorName}>
                        {sticker.creatorName}
                    </div>
                </div> */}
                <div className={s.stickerOverlayContentSoundIcon} onClick={onSoundClick}>
                    { soundEnabled ? <IconSoundActiveLine /> : <IconSoundMuteLine /> }
                </div>
                <div className={s.stickerOverlayContentStickerName}>
                    {sticker.name}
                </div>
            </div>
        </div>
    )
}
