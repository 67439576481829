import React from 'react'
import { Link } from 'react-router-dom';
import cn from 'classnames';
import s from './SideMenuItem.scss';

export interface SideMenuItemProps {
    icon?: JSX.Element;
    caption: string;
    to?: string;
    enabled?: boolean;
    selected?: boolean;
    collapsed?: boolean;
    onClick?: () => void;
}

export const SideMenuItem = (props: SideMenuItemProps) => {
    const {
        icon,
        caption,
        to,
        selected,
        collapsed,
        onClick,
    } = props;

    return to
        ? (<Link
            to={to}
            className={cn(
            s.sideMenuItem,
                {
                    [s.sideMenuItemActiveSelected]: selected,
                    [s.sideMenuItemActive]: to || onClick,
                    [s.sideMenuItemCollapsed]: collapsed,
                }
            )}
            onClick={onClick}
        >
            {!!icon &&
                <div className={s.sideMenuItemIcon}>
                    {icon}
                </div>
            }
            <div className={s.sideMenuItemCaption} title={caption}>
                {caption}
            </div>
        </Link>)
        : (<div
            className={cn(
                s.sideMenuItem,
                {
                    [s.sideMenuItemActiveSelected]: selected,
                    [s.sideMenuItemActive]: to || onClick,
                }
            )}
            onClick={onClick}
        >
            {!!icon &&
                <div className={s.sideMenuItemIcon}>
                    {icon}
                </div>
            }
            <div className={s.sideMenuItemCaption}>
                {caption}
            </div>
        </div>)
}
