import { NotificationType } from '@contexts';
import { useApi, useNotifications, useStickersPages } from '@hooks';
import {AlertUpdateDto, EModerationState, StickerDto} from '@interfaces';
import { MassStickersUpdateDto, ModerationMode } from '@typings';
import { useState } from 'react';

export const useModeration = () => {
    const [ filter, setFilter ] = useState('');
    const [ moderationMode, setModerationMode ] = useState(ModerationMode.UNMODERATED);
    const [ lastModeratedAlertId, setLastModeratedAlertId ] = useState<string>();

    const {
        alerts,
        currentPage,
        totalPagesCount,
        totalStickersCount,
        isStickersFetching,
        changePage,
        reloadCurrentPage,
        updateAlert: updateLoadedAlert,
    } = useStickersPages({
        searchQuery: filter,
        stickersType: moderationMode,
    })

    const {
        alert: {
            useUpdateStickerMutation,
            useLazySilentUpdateStickerQuery,
            useGetModerationStatsQuery,
            useMassUpdateStickersMutation,
        }
    } = useApi();

    const {
        data: moderationStats,
    } = useGetModerationStatsQuery(filter);

    const [
        update,
    ] = useUpdateStickerMutation();

    const [
        massUpdate,
    ] = useMassUpdateStickersMutation();

    const [
        silentUpdate,
    ] = useLazySilentUpdateStickerQuery();

    const {
        notify,
    } = useNotifications();

    const approveAlert = async (stickerId: string) => {
        const updatedAlert = await update({ id: stickerId, moderationState: EModerationState.APPROVED }).unwrap();
        if (updatedAlert) {
            reloadCurrentPage();
            notify('Алерт успешно одобрен', NotificationType.SUCCESS);
            setLastModeratedAlertId(stickerId);
        } else {
            notify('Не удалось одобрить алерт, обратитесь к разработчику', NotificationType.ERROR);
        }
    }

    const declineAlert = async (stickerId: string, rejectReason?: string) => {
        const updatedAlert = await update({ id: stickerId, moderationState: EModerationState.DECLINED, rejectReason }).unwrap();
        if (updatedAlert) {
            reloadCurrentPage();
            notify('Алерт успешно отклонен', NotificationType.SUCCESS);
            setLastModeratedAlertId(stickerId);
        } else {
            notify('Не удалось отклонить алерт, обратитесь к разработчику', NotificationType.ERROR);
        }
    }

    const returnLastAlertForModeration = async () => {
        if (lastModeratedAlertId) {
            const updatedAlert = await update({ id: lastModeratedAlertId, moderationState: EModerationState.MODERATION }).unwrap();
            if (updatedAlert) {
                reloadCurrentPage();
                notify('Алерт успешно возвращен на модерацию', NotificationType.SUCCESS);
                setLastModeratedAlertId(undefined);
            } else {
                notify('Не удалось вернуть алерт, обратитесь к разработчику', NotificationType.ERROR);
            }
        }
    }

    const updateAlert = async (alertData: AlertUpdateDto) => {
        const updatedAlert = await update({ ...alertData }).unwrap();
        if (updatedAlert) {
            reloadCurrentPage();
            //notify('Алерт успешно обновлен', NotificationType.SUCCESS);
        } else {
            notify('Не удалось обновить алерт, обратитесь к разработчику', NotificationType.ERROR);
        }
    }

    const massUpdateStickers = async (updateData: MassStickersUpdateDto): Promise<number> => {
        const updatedCount = await massUpdate({ ...updateData }).unwrap();
        if (updatedCount > 0) {
            reloadCurrentPage();
            notify(`Обновлено ${updatedCount} стикеров`, NotificationType.SUCCESS);
        } else {
            notify('Не удалось обновить стикеры, обратитесь к разработчику', NotificationType.ERROR);
        }

        return updatedCount;
    }

    const silentUpdateAlert = async (alertData: AlertUpdateDto) => {
        await silentUpdate({ ...alertData }).unwrap();
    }

    const changeFilter = (value: string) => {
        setFilter(value);
    }

    const showTab = (value: ModerationMode) => {
        setModerationMode(value);
    }

    return {
        alerts,
        currentPage,
        totalPagesCount,
        totalStickersCount,
        activeTab: moderationMode,
        moderationStats,
        lastModeratedAlertId,
        filter,
        isStickersFetching,
        changePage,
        approveAlert,
        declineAlert,
        returnLastAlertForModeration,
        updateAlert,
        massUpdateStickers,
        silentUpdateAlert,
        changeFilter,
        showTab,
        updateLoadedAlert,
    };
};
