import { NotificationType } from '@contexts';
import { useApi, useNotifications } from '@hooks';
import { AlertUpdateDto, EModerationState } from '@interfaces';
import { StickerDto } from '@typings';
import { useEffect, useState } from 'react';

export const useExternalModeration = () => {
    const [ currentSticker, setCurrentSticker ] = useState<StickerDto>();
    const [ lastModeratedAlertId, setLastModeratedAlertId ] = useState<string>();

    const {
        alert: {
            useUpdateStickerMutation,
            useLazySilentUpdateStickerQuery,
            useGetModerationStatsQuery,
        },
        externalModeration: {
            useLazyGetNextModeratedStickerQuery,
        }
    } = useApi();

    const {
        data: moderationStats,
    } = useGetModerationStatsQuery('');

    const [
        update,
    ] = useUpdateStickerMutation();

    const [
        silentUpdate,
    ] = useLazySilentUpdateStickerQuery();

    const [
        getNextModeratedSticker,
        {
            data: currentModeratedSticker,
            isLoading: isLoading,
            isFetching: isFetching,
        }
     ] = useLazyGetNextModeratedStickerQuery();

    const {
        notify,
    } = useNotifications();

    const reloadCurrentAlert = () => {
        getNextModeratedSticker({ skipCurrent: false });
    }

    const skipCurrentAlert = () => {
        getNextModeratedSticker({ skipCurrent: true });
    }

    const approveAlert = async (stickerId: string) => {
        const updatedAlert = await update({ id: stickerId, moderationState: EModerationState.APPROVED }).unwrap();
        if (updatedAlert) {
            reloadCurrentAlert();
            notify('Алерт успешно одобрен', NotificationType.SUCCESS);
            setLastModeratedAlertId(stickerId);
        } else {
            notify('Не удалось одобрить алерт, обратитесь к разработчику', NotificationType.ERROR);
        }
    }

    const declineAlert = async (stickerId: string, rejectReason?: string) => {
        const updatedAlert = await update({ id: stickerId, moderationState: EModerationState.DECLINED, rejectReason }).unwrap();
        if (updatedAlert) {
            reloadCurrentAlert();
            notify('Алерт успешно отклонен', NotificationType.SUCCESS);
            setLastModeratedAlertId(stickerId);
        } else {
            notify('Не удалось отклонить алерт, обратитесь к разработчику', NotificationType.ERROR);
        }
    }

    const returnLastAlertForModeration = async () => {
        if (lastModeratedAlertId) {
            const updatedAlert = await update({ id: lastModeratedAlertId, moderationState: EModerationState.MODERATION }).unwrap();
            if (updatedAlert) {
                reloadCurrentAlert();
                notify('Алерт успешно возвращен на модерацию', NotificationType.SUCCESS);
                setLastModeratedAlertId(undefined);
            } else {
                notify('Не удалось вернуть алерт, обратитесь к разработчику', NotificationType.ERROR);
            }
        }
    }

    const updateAlert = async (alertData: AlertUpdateDto) => {
        const updatedAlert = await update({ ...alertData }).unwrap();
        if (updatedAlert) {
            reloadCurrentAlert();
        } else {
            notify('Не удалось обновить алерт, обратитесь к разработчику', NotificationType.ERROR);
        }
    }

    const silentUpdateAlert = async (alertData: AlertUpdateDto) => {
        await silentUpdate({ ...alertData }).unwrap();
    }

    const updateLoadedAlert = (alert: StickerDto) => {
        setCurrentSticker({ ...currentSticker, ...alert });
    }

    useEffect(() => {
        reloadCurrentAlert();
    }, []);

    useEffect(() => {
        setCurrentSticker(currentModeratedSticker);
    }, [currentModeratedSticker]);

    return {
        currentSticker,
        moderationStats,
        lastModeratedAlertId,
        approveAlert,
        declineAlert,
        returnLastAlertForModeration,
        updateAlert,
        silentUpdateAlert,
        updateLoadedAlert,
        skipCurrentAlert,
    };
};
