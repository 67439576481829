import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from '@contexts';

export const ProtectedRoute = (props: React.PropsWithChildren) => {
    const { children } = props;
    const location = useLocation();
    const ctx = useContext(AuthContext);

    if (!ctx.token) {
        return <Navigate to="/login" state={{ from: location }} replace />
    }

    return (
        <>
            {children}
        </>
    );
}
