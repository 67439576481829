// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Logo__logo--YCvMs {\n  filter: drop-shadow(0 2px 0 rgba(0, 0, 0, 0.25));\n}", "",{"version":3,"sources":["webpack://./src/components/decorations/Logo/Logo.scss"],"names":[],"mappings":"AAEA;EACI,gDAAA;AADJ","sourcesContent":["@import '/src/common/styles/colors.scss';\n\n.logo {\n    filter: drop-shadow(0 2px 0 $color-black-a25);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "Logo__logo--YCvMs"
};
export default ___CSS_LOADER_EXPORT___;
