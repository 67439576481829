import { IconHuskySolid, IconImagesLine, IconStarLine, IconThumbUpLine } from '@common/images/svg';
import { SideMenuItemProps } from '@components';
import React from 'react';

export const menu: Array<SideMenuItemProps> = [
    {
        "icon": <IconThumbUpLine />,
        "caption": "Модерация",
        "to": "moderation",
        "enabled": true,
    },
    {
        "icon": <IconHuskySolid />,
        "caption": "Зрители",
        "to": "viewer-events",
        "enabled": true,
    },
    {
        "icon": <IconStarLine />,
        "caption": "Стримеры",
        "to": "streamer-events",
        "enabled": true,
    },
    {
        "icon": <IconImagesLine />,
        "caption": "Тематические подборки",
        "to": "thematic-sets",
        "enabled": true,
    },
];
