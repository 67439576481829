import { ChannelDto } from './ChannelDto';

export interface Flags {
    isAdmin: boolean;
    isModer: boolean;
    isMasterModer: boolean;
}

export interface IUser {
    _id: string;
    id: string;
    name: string;
    username: string;
    avatar: string;
    channel: ChannelDto;
    flags: Flags;
    // TODO: Check and remove
    // balance?: number;
    createdAt: number;
    deletedAt: number;
    deletedBy: string;
    deletedData: IUser;
}
