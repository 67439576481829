import { ViewerEventsDataItem, ViewerEventsData } from '@interfaces';
import { api } from '@store';
import { useEffect, useState } from 'react';
import {useCurrentUser} from "@hooks/user";

export enum EViewerEventsTableType {
    EVENTS,
    WALLETS,
}

export const useViewerEvents = () => {
    const {
        user: {
            useLazyGiveBonusQuery,
            useLazyDeleteUserByIdQuery,
            useLazyRestoreUserByIdQuery,
        },
        stats: {
            useLazyGetViewerEventsPageQuery,
        }
    } = api;

    const { currentUser } = useCurrentUser();
    const [page, setPage] = useState(1);
    const [userId, setUserId] = useState<string>();
    const [currentStats, setCurrentStats] = useState<ViewerEventsDataItem[]>([]);
    const [lastData, setLastData] = useState<ViewerEventsData>();
    const [isEndReached, setIsEndReached] = useState(false);
    const [displayErrors, setDisplayErrors] = useState(false);

    const [
        giveBonusRequest,
    ] = useLazyGiveBonusQuery();

    const [
        deleteUserRequest,
    ] = useLazyDeleteUserByIdQuery();

    const [
        restoreUserRequest,
    ] = useLazyRestoreUserByIdQuery();

    const [
        loadPage,
        {
            data: loadedPage,
            isLoading: isLoadingPage,
            isSuccess,
            isFetching: isFetchingPage,
        },
    ] = useLazyGetViewerEventsPageQuery();

    const giveBonus = async (streamerId: string, value?: number) => {
        if (lastData?.viewer && value && value > 0) {
            await giveBonusRequest({userId: lastData?.viewer.id, streamerId, value}).unwrap();
            setDisplayErrors(false);
            setIsEndReached(false);
            setLastData(undefined);
            setCurrentStats([]);
            setPage(1);
            find();
        }
    }

    const deleteUser = async (userId: string) => {
        if (userId) {
            await deleteUserRequest(userId).unwrap();
            setDisplayErrors(false);
            setIsEndReached(false);
            setLastData(undefined);
            setCurrentStats([]);
            setPage(1);
            find();
        }
    }

    const restoreUser = async (userId: string) => {
        if (userId) {
            await restoreUserRequest(userId).unwrap();
            setDisplayErrors(false);
            setIsEndReached(false);
            setLastData(undefined);
            setCurrentStats([]);
            setPage(1);
            find();
        }
    }

    const find = () => {
        if (!!userId) {
            loadPage({ page: 1, userId });
            setDisplayErrors(false);
        } else {
            setDisplayErrors(true);
        }
    }

    const loadNextPage = () => {
        setPage(page + 1);
    }

    useEffect(() => {
        setDisplayErrors(false);
        setIsEndReached(false);
        setLastData(undefined);
        setCurrentStats([]);
        setPage(1);
    }, [userId]);

    useEffect(() => {
        if (userId && page > 1) {
            loadPage({page, userId});
        }
    }, [page]);

    useEffect(() => {
        if (loadedPage && !loadedPage.data.length) {
            if (loadedPage.viewer) {
                setIsEndReached(true);
                setLastData(loadedPage);
            } else {
                setIsEndReached(false);
                setLastData(undefined);
                setCurrentStats([]);
                setDisplayErrors(true);
            }
        } else {
            if (page === 1) {
                setCurrentStats(loadedPage?.data ?? []);
            } else {
                setCurrentStats((prev) => [...prev].concat(loadedPage?.data ?? []));
            }
            setLastData(loadedPage);
        }
    }, [loadedPage]);

    return {
        currentUser,
        userId,
        lastData,
        currentStats,
        isLoadingPage,
        isFetchingPage,
        isEndReached,
        isSuccess,
        displayErrors,

        loadNextPage,
        setUserId,
        find,
        giveBonus,
        deleteUser,
        restoreUser,
    };
};
