import { Button, Panel } from '@components';
import { useApi, useModals } from '@hooks';
import { IconCloseLine, IconGoogleG, IconTwitchAltSolid, IconVkAltSolid } from '@images/svg';
import cn from 'classnames';
import config from 'config';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import s from './AuthButtons.scss';

interface AuthButtonsProps {
    className?: string;
    returnUrl?: string;
    hideCloseButton?: boolean;
    callback?(): void;
}

let authWindow: Window | null;

export interface IMessage {
	action: string;
}

export const getAuthUrl = (service: string) => {
	return `${config.apiHost}/auth/${service}?return_url=${encodeURIComponent(`${location.protocol}//${location.host}'/auth/redirect`)}`;
};

export const AuthButtons = (props: AuthButtonsProps) => {
    const dispatch = useDispatch();
    const {
        className,
        returnUrl,
        hideCloseButton,
        callback,
    } = props;

    const {
        util,
    } = useApi();

    const { clearModals, closeModal } = useModals();
    const navigate = useNavigate();

    const onAuthClick = async (service: string) => {
		authWindow = window.open(getAuthUrl(service));
	};

    const onMessage = (event: MessageEvent) => {
		try {
			const message = event.data as IMessage;
			if (message.action === 'close') {
				authWindow?.close();
                if (!callback) {
                    clearModals();
                }
                navigate(returnUrl ?? `/`);
                dispatch(util.resetApiState());
                if (callback) {
                    callback();
                }
                window.removeEventListener('message', onMessage);
			}
		} catch (ignore) {}
	};

	useEffect(
		() => {
			window.addEventListener('message', onMessage);

			return () => {
				window.removeEventListener('message', onMessage);
			};
		},
		[],
	);

    return (
        <div className={className || s.authButtonsModal} onClick={(e) => e.stopPropagation()}>
            {!hideCloseButton &&
                <div className={s.closeButton} onClick={closeModal}>
                    <IconCloseLine />
                </div>
            }
            <Panel className={s.registrationButtonsBlock}>
                <Button
                    className={cn(s.registrationButton, s.registrationButtonGoogle)}
                    caption={
                        <div className={s.registrationButtonCaption}>
                            <div className={s.registrationButtonIcon}><IconGoogleG/></div>
                            <div className={s.registrationButtonCaptionText}>Google</div>
                        </div>
                    }
                    onClick={() => onAuthClick('google')}
                />
                <Button
                    className={cn(s.registrationButton, s.registrationButtonTwitch)}
                    caption={
                        <div className={s.registrationButtonCaption}>
                            <div className={s.registrationButtonIcon}><IconTwitchAltSolid/></div>
                            <div className={s.registrationButtonCaptionText}>Twitch</div>
                        </div>
                    }
                    onClick={() => onAuthClick('twitch')}
                />
                <Button
                    className={cn(s.registrationButton, s.registrationButtonVk)}
                    caption={
                        <div className={s.registrationButtonCaption}>
                            <div className={s.registrationButtonIcon}><IconVkAltSolid/></div>
                            <div className={s.registrationButtonCaptionText}>VK</div>
                        </div>
                    }
                    onClick={() => onAuthClick('vk')}
                />
            </Panel>
        </div>
    );
};
