import React from 'react'

import cn from 'classnames';

import s from './Menu.scss';

interface MenuProps {
    className?: string;
}

export const Menu = (props: React.PropsWithChildren<MenuProps>) => {
    const { className, children } = props;
    return (
        <div className={cn(s.menu, className)}>
            { children }
        </div>
    )
}