import React from 'react'

import cn from 'classnames';

import s from './Row.scss';

interface RowProps {
    className?: string;
    fullHeight?: boolean;
    noGap?: boolean;
}

export const Row = (props: React.PropsWithChildren<RowProps>) => {
    const { children, className, fullHeight, noGap } = props;

    return (
        <div className={cn(
            s.row,
            className,
            {
                [s.rowFullHeight]: fullHeight,
                [s.noGap]: noGap,
            },
        )}>
            {children}
        </div>
    );
};
