import config from 'config';

const CONFIG_LOGGING = config?.logging ?? 0;

/** The color of errors. Is used only in browsers logs. */
const COLOR_ERROR = 'color: #b00';

/** The color of warnings. Is used only in browsers logs. */
const COLOR_WARNING = 'color: #a70';

/** The color of info records. Is used only in browsers logs. */
const COLOR_INFO = 'color: #7a0';

/** The color of debug records. Is used only in browsers logs. */
const COLOR_DEBUG = 'color: #999';

/** The color of trace records. Is used only in browsers logs. */
const COLOR_TRACE = 'color: #666';

export enum LogLevel {
    /** This logging level prevents writing logs at all. */
    OFF = 0,

    /** Allows write only error messages into the log. */
    ERROR = 1,

    /** Allows write error and warning messages into the log. */
    WARNING = 2,

    /** Allows write error, warning and information messages into the log. */
    INFO = 3,

    /** Turns on writing of all types of messages but trace. */
    DEBUG = 4,

    /** All types of messages will be included into the log. */
    TRACE = 5
}

export interface LoggerParams {
    target?: string;
    logLevel?: LogLevel;
}

export class Logger {
    private readonly logLevel: LogLevel = CONFIG_LOGGING;
    private readonly loggerTarget: string = '';

    constructor(params: LoggerParams) {
        this.logLevel = params.logLevel ?? CONFIG_LOGGING;
        this.loggerTarget = params.target ?? '';
    }

    public log(logLevel: LogLevel, logMessage: unknown, customColor?: string) {
        const logTemplate = `%c${'[' + LogLevel[logLevel] + '] '}${this.loggerTarget !== '' ? '[' + this.loggerTarget + ']': ''} ${logMessage}`;
        const logObject = typeof logMessage === 'object' ? logMessage : '';

        if (logLevel <= this.logLevel) {
            let color;
            switch (logLevel) {
                case LogLevel.TRACE:
                    color = COLOR_TRACE;
                    break;
                case LogLevel.DEBUG:
                    color = COLOR_DEBUG;
                    break;
                case LogLevel.INFO:
                    color = COLOR_INFO;
                    break;
                case LogLevel.WARNING:
                    color = COLOR_WARNING;
                    break;
                case LogLevel.ERROR:
                    color = COLOR_ERROR;
                    break;
                default:
                    color = COLOR_DEBUG;
                    break;
            }
            if (logObject) {
                console.log(logTemplate, customColor ? `color: ${customColor}` : color, logObject);
            } else {
                console.log(logTemplate, customColor ? `color: ${customColor}` : color);
            }
        }
    }

    public trace(message: any, customColor?: string) {
        this.log(LogLevel.TRACE, message, customColor);
    }

    public debug(message: any, customColor?: string) {
        this.log(LogLevel.DEBUG, message, customColor);
    }

    public info(message: any, customColor?: string) {
        this.log(LogLevel.INFO, message, customColor);
    }

    public warning(message: any, customColor?: string) {
        this.log(LogLevel.WARNING, message, customColor);
    }

    public error(message: any, customColor?: string) {
        this.log(LogLevel.ERROR, message, customColor);
    }
}
