import { StickerDto } from '@typings';

export interface MemesIndexSearchParams {
    stickerId: string;
}

export interface MemesIndexSearchResult {
    total: [[StickerDto, number]],
    v: [[StickerDto, number]],
    a: [[StickerDto, number]],
}
