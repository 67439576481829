import React from 'react'
import cn from 'classnames';
import s from './Paginator.scss';

interface PaginatorProps {
    caption?: string;
    activePage?: number;
    maxPages: number;
    visiblePages?: number;
    onPageSelect?: (pageNumber: number) => void;
}

export const Paginator = (props: PaginatorProps) => {
    const {
        caption,
        maxPages,
        visiblePages = 7,
        onPageSelect,
    } = props;

    let {
        activePage,
    } = props;

    activePage = activePage ? activePage : 1;

    const firstPage = 1;
    const lastPage = maxPages;

    const floatingPagesCount = visiblePages - 2;
    const startOfFloatingPages = firstPage + 1;
    const endOfFloatingPages = lastPage - 1;

    const hasLastPage = lastPage > 1;
    
    const isStartOfPages = activePage < floatingPagesCount;
    const isEndOfPages = activePage >= maxPages - 3;

    const freeFloatingPages = visiblePages - 5;
    const leftFloatingPages = Math.floor(freeFloatingPages/2);
    const rightFloatingPages = leftFloatingPages + freeFloatingPages%2;

    let visiblePagesFrom = startOfFloatingPages;
    let visiblePagesTo = endOfFloatingPages;

    if (maxPages > visiblePages) {
        visiblePagesFrom = isStartOfPages ? startOfFloatingPages : isEndOfPages ? lastPage - (floatingPagesCount - 1) : activePage - leftFloatingPages;
        visiblePagesTo = isEndOfPages ? endOfFloatingPages : isStartOfPages ? floatingPagesCount : activePage + rightFloatingPages;
    }

    const prevPage = activePage - 1;
    const hasPrevPage = prevPage > 0;
    const nextPage = activePage + 1;
    const hasNextPage = nextPage <= lastPage;

    let controls = [];

    controls.push(<div key={'prev'} className={cn(s.page, { [s.pageDisabled]: !hasPrevPage })} onClick={() => hasPrevPage && onPageSelect && onPageSelect(prevPage)}>&#9664;</div>);
    controls.push(<div key={firstPage} className={cn(s.page, { [s.pageActive]: firstPage === activePage })} onClick={() => onPageSelect && onPageSelect(firstPage)}>{firstPage}</div>);

    if (!isStartOfPages && maxPages > visiblePages) controls.push(<div key={'leftSeparator'} className={cn(s.separator)}>...</div>);
    
    for(let i = visiblePagesFrom; i <= visiblePagesTo; i++) {
        controls.push(
            <div
                key={i}
                className={cn(
                    s.page,
                    { [s.pageActive]: i === activePage },
                )}
                onClick={() => onPageSelect && onPageSelect(i)}
            >
                {i}
            </div>
        );
    }

    if (!isEndOfPages && maxPages > visiblePages) controls.push(<div key={'rightSeparator'} className={cn(s.separator)}>...</div>);

    if (hasLastPage) controls.push(<div key={lastPage} className={cn(s.page, { [s.pageActive]: lastPage === activePage })} onClick={() => onPageSelect && onPageSelect(lastPage)}>{lastPage}</div>);

    controls.push(<div key={'next'} className={cn(s.page, { [s.pageDisabled]: !hasNextPage })} onClick={() => hasNextPage && onPageSelect && onPageSelect(nextPage)}>&#9654;</div>);

    return (
        <div className={s.paginator}>
            { caption &&
                <div className={s.paginatorHeader}>
                    {caption}
                </div>
            }
            
            <div className={s.paginatorPages}>
                {controls}
            </div>
        </div>
    )
}