import { ComponentColor, IconButton, Panel, RoundedAvatar, RoundedAvatarSize, Row, TagSelect, TextField } from '@components';
import React from 'react';

import { IconCheckLine, IconCloseLine, IconPlusLine } from '@common/images/svg';
import { useLogger } from '@hooks';
import { useThematicSets } from '@hooks/admin/ThematicSets';
import s from './ThematicSets.scss';

export const ThematicSets = () => {
    const {
        isEditMode,
        isSaveAllowed,
        pictureData,
        thematicSets,
        selectedThematicSet,
        updatePictureFile,
        updateSetName,
        updateThematicTags,
        updateBeginDate,
        updateEndDate,
        updateThematicText,
        switchEditMode,
        saveThematicSet,
        cancelThematicSetCreation,
        removeThematicSet,
        selectThematicSet,
    } = useThematicSets();

    const logger = useLogger({ target: ThematicSets.name });

    const items = thematicSets.map((ts, idx) => (
        <tr key={idx} className={s.thematicSetsListRow} onClick={() => !isEditMode && selectThematicSet(ts.id)}>
            <td>
                <RoundedAvatar source={ts.pictureUrl} size={RoundedAvatarSize.MEDIUM} />
            </td>
            <td className={s.thematicSetsListRowName}>
                <div className={s.thematicSetsListRowNameName}>{ts.name}</div>
                {!!ts.description &&
                    <div className={s.thematicSetsListRowNameDescription}>{ts.description}</div>
                }
            </td>
            <td className={s.thematicSetsListRowTags}>
                {ts.tags?.map(t => t.name).join(', ')}
            </td>
            <td className={s.thematicSetsListRowBeginDate}>
                {new Date(ts.beginDate).toLocaleDateString()}
            </td>
            <td className={s.thematicSetsListRowEndDate}>
                {new Date(ts.endDate).toLocaleDateString()}
            </td>
            <td>
                <div className={s.thematicSetsListRowDelButton} onClick={() => removeThematicSet(ts.id)}><IconCloseLine /></div>
            </td>
        </tr>
    ))

    const beginDate = selectedThematicSet?.beginDate ? new Date(selectedThematicSet?.beginDate) : null;
    const beginDateString = beginDate ? `${beginDate?.getFullYear()}-${(beginDate?.getMonth() + 1).toString().padStart(2, '0')}-${beginDate?.getDate().toString().padStart(2, '0')}` : '';

    const endDate = selectedThematicSet?.endDate ? new Date(selectedThematicSet?.endDate) : null;
    const endDateString = endDate ? `${endDate?.getFullYear()}-${(endDate?.getMonth() + 1).toString().padStart(2, '0')}-${endDate?.getDate().toString().padStart(2, '0')}` : '';

    return (
        <Panel className={s.thematicSets}>
            {isEditMode &&
                <>
                <Row className={s.thematicSetsControls}>
                    <RoundedAvatar
                        changeAllowed
                        showUploadWhenEmpty
                        source={pictureData || selectedThematicSet?.pictureUrl}
                        onChanged={updatePictureFile}
                    />

                    <TextField
                        placeholder={'Название подборки'}
                        defaultValue={selectedThematicSet?.name}
                        onChanged={updateSetName}
                    />

                    <TagSelect
                        placeholder={'Укажите тег'}
                        color={ComponentColor.ORANGE}
                        defaultValue={selectedThematicSet?.tags}
                        onTagsChanged={updateThematicTags}
                    />

                    <TextField
                        className={s.dateSelector}
                        type={'date'}
                        defaultValue={beginDateString}
                        onChanged={updateBeginDate}
                    />

                    <TextField
                        className={s.dateSelector}
                        type={'date'}
                        defaultValue={endDateString}
                        onChanged={updateEndDate}
                    />

                    <IconButton className={s.button} color={ComponentColor.ORANGE} icon={selectedThematicSet?.id ? <IconCheckLine/> : <IconPlusLine/>} onClick={saveThematicSet} disabled={!isSaveAllowed}/>
                    <IconButton className={s.button} color={ComponentColor.DEFAULT} icon={<IconCloseLine/>} onClick={cancelThematicSetCreation}/>
                </Row>
                <Row className={s.thematicSetsControls}>
                    <TextField
                        className={s.description}
                        placeholder={'Введите текст описания'}
                        defaultValue={selectedThematicSet?.description}
                        onChanged={updateThematicText}
                    />
                </Row>
                </>
            }

            {items.length > 0
                ? <table className={s.thematicSetsList}>
                    <thead>
                    <tr className={s.thematicSetsListTitles}>
                        <td></td>
                        <td>Название</td>
                        <td>Теги</td>
                        <td>Начало</td>
                        <td>Окончание</td>
                        <td></td>
                    </tr>
                    </thead>
                    <tbody>
                        {items}
                    </tbody>
                </table>
                : !isEditMode
                    ? <div>Тематические подборки отсутствуют</div>
                    : null
            }

            {!isEditMode &&
                <IconButton
                    className={s.thematicSetsAddButton}
                    iconClassName={s.thematicSetsAddButtonIcon}
                    color={ComponentColor.ORANGE}
                    icon={<IconPlusLine/>}
                    onClick={() => switchEditMode(true)}
                />
            }
        </Panel>
    )
}